import React from 'react'
import { useFormikContext, FieldArray } from 'formik'
import { Checkbox, Input, InputNumber, Select } from 'formik-antd'
import { Button, Icon } from 'antd'

const raidbotsFightStyles = {
  Patchwerk: 'Patchwerk',
  'Dungeon Slice': 'DungeonSlice',
  'Target Dummy': 'TargetDummy',
  'Execute Patchwerk': 'ExecutePatchwerk',
  'Hectic Add Cleave': 'HecticAddCleave',
  'Light Movement': 'LightMovement',
  'Heavy Movement': 'HeavyMovement',
  'Casting Patchwerk': 'CastingPatchwerk',
  'Cleave Add': 'CleaveAdd',
}

const operatorOptions = {
  'is less than': 'smaller',
  'is at most': 'smaller_or_equal',
  is: 'equal',
  'is at least': 'greater_or_equal',
  'is more than': 'greater',
}

const newConfiguration = {
  name: 'New configuration',
  fight_style: 'Patchwerk',
  sockets: false,
  pi: false,
  number_of_bosses_operator: 'equal',
  number_of_bosses: 1,
  fight_duration_operator: 'greater_or_equal',
  fight_duration: 5,
  weight: 1,
}

const WishlistConfigurations = (props) => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <div className='wishlist-configurations'>
      <h3>Allowed Droptimizer configurations</h3>
      <p>
        You can set up multiple Droptimizer configurations here. If you have
        more than one, the weight of each will be used for the upgrade
        percentages shown in the overview.
      </p>
      <p>
        For example, you might have one configuration for single target, and one
        configuration for AoE (both with a weight of 1). Members can upload
        their reports for each configuration separately, so if an item is a 1%
        upgrade on single target and a 3% upgrade on AoE then the overview will
        show 2%. If the user only fills in the single target configuration with
        a 1% upgrade, it will show 0.5%.
      </p>
      <FieldArray
        name={`wishlist_configurations`}
        render={(arrayHelpers) => (
          <div>
            {values.wishlist_configurations.map((configuration, index) => {
              if (configuration._destroy) {
                return null
              } else {
                return (
                  <div key={index} className='single-configuration'>
                    <div>
                      <div className='left'>
                        <div className='field'>
                          <label>Display name</label>
                          <Input
                            name={`wishlist_configurations.${index}.name`}
                          />
                        </div>

                        <div className='field'>
                          <label>Fight style</label>
                          <Select
                            className='fight-style'
                            placeholder='Fight style'
                            name={`wishlist_configurations.${index}.fight_style`}
                            optionFilterProp='children'
                          >
                            {Object.keys(raidbotsFightStyles).map(
                              (option, index) => (
                                <Select.Option
                                  key={index}
                                  value={raidbotsFightStyles[option]}
                                >
                                  {option}
                                </Select.Option>
                              ),
                            )}
                          </Select>
                        </div>

                        <div className='field weight'>
                          <label>Weight</label>
                          <Input
                            name={`wishlist_configurations.${index}.weight`}
                          />
                        </div>
                      </div>

                      <a
                        onClick={() => {
                          if (configuration.id) {
                            setFieldValue(
                              `wishlist_configurations.${index}._destroy`,
                              true,
                            )
                          } else {
                            arrayHelpers.remove(index)
                          }
                        }}
                      >
                        <Icon type='delete' />
                      </a>
                    </div>

                    <div>
                      <div className='with-operator'>
                        <span>Number of bosses </span>
                        <Select
                          name={`wishlist_configurations.${index}.number_of_bosses_operator`}
                          optionFilterProp='children'
                        >
                          {Object.keys(operatorOptions).map((option, index) => (
                            <Select.Option
                              key={index}
                              value={operatorOptions[option]}
                              className='lower'
                            >
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                        <InputNumber
                          name={`wishlist_configurations.${index}.number_of_bosses`}
                        />
                      </div>

                      <div className='with-operator'>
                        <span>Fight length </span>
                        <Select
                          name={`wishlist_configurations.${index}.fight_duration_operator`}
                          optionFilterProp='children'
                        >
                          {Object.keys(operatorOptions).map((option, index) => (
                            <Select.Option
                              key={index}
                              value={operatorOptions[option]}
                              className='lower'
                            >
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                        <InputNumber
                          name={`wishlist_configurations.${index}.fight_duration`}
                        />
                        <span>min</span>
                      </div>
                    </div>

                    <div className='checkbox-options'>
                      <Checkbox
                        name={`wishlist_configurations.${index}.sockets`}
                      >
                        Require gems
                      </Checkbox>

                      <Checkbox name={`wishlist_configurations.${index}.pi`}>
                        Require PI
                      </Checkbox>

                      <Checkbox
                        name={`wishlist_configurations.${index}.expert_mode`}
                      >
                        Allow expert mode
                      </Checkbox>
                    </div>

                    <div className='upgrade-levels'>
                      {['', 'heroic', 'normal', 'raid_finder'].map(
                        (difficulty, i) => {
                          return (
                            <div key={difficulty} className='with-operator'>
                              <span>
                                {i === 0 ? 'Item upgrade level must be' : 'and'}
                              </span>
                              <Select
                                className='upgrade-level'
                                defaultValue={
                                  props.droptimizer_upgrade_levels
                                    ? props.droptimizer_upgrade_levels[
                                        difficulty.length
                                          ? difficulty
                                          : 'mythic'
                                      ][0].value
                                    : null
                                }
                                name={`wishlist_configurations.${index}.upgrade_level${
                                  difficulty.length ? `_${difficulty}` : ''
                                }`}
                                optionFilterProp='children'
                              >
                                {(props.droptimizer_upgrade_levels
                                  ? props.droptimizer_upgrade_levels[
                                      difficulty.length ? difficulty : 'mythic'
                                    ]
                                  : []
                                ).map((option, index) => (
                                  <Select.Option
                                    key={index}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </Select.Option>
                                ))}
                              </Select>
                              <span>
                                for{' '}
                                {
                                  {
                                    '': 'Mythic',
                                    heroic: 'Heroic',
                                    normal: 'Normal',
                                    raid_finder: 'LFR',
                                  }[difficulty]
                                }{' '}
                                difficulty
                              </span>
                            </div>
                          )
                        },
                      )}
                      <div className='with-operator'>
                        <Checkbox
                          name={`wishlist_configurations.${index}.match_equipped_gear`}
                        >
                          Require &apos;Upgrade All Equipped Gear to the Same
                          Level&apos;
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                )
              }
            })}

            <Button onClick={() => arrayHelpers.push(newConfiguration)}>
              Add new configuration <Icon type='plus' />
            </Button>
          </div>
        )}
      />
    </div>
  )
}

export default WishlistConfigurations
