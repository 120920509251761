import React, { useMemo } from 'react'
import { sortBy } from 'lodash'
import { Tooltip } from 'antd'

const LootStatisticsBar = (props) => {
  const sortedGroups = useMemo(() => {
    return sortBy(Object.keys(props.groupedAwards), (group) =>
      props.categoryOrder.indexOf(parseInt(group)),
    )
  }, [props.groupedAwards])

  return (
    <div className='loot-statistics-bar'>
      {sortedGroups.map((group) => {
        const percentage =
          (props.groupedAwards[group].length / props.maxSize) * 100
        const responseType =
          props.groupedAwards[group][0].propagated_reponse_type

        if (responseType.excluded) {
          return null
        } else {
          return (
            <Tooltip
              title={responseType.name}
              key={group}
              placement='top'
              overlayClassName='loot-statistics-bar-tooltip'
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0}
            >
              <div
                className='loot-statistics-bar-section'
                style={{
                  background: responseType.rgba,
                  width: `${percentage}%`,
                }}
              />
            </Tooltip>
          )
        }
      })}
    </div>
  )
}

export default LootStatisticsBar
