import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import swaggerSpec from '../../../../../../../public/api-spec/v1/swagger.json'

import TeamService from '../../../services/TeamService'

import { Icon, Switch, Tooltip, message } from 'antd'

class ApiPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
    }
  }

  authorize = () => {
    if (this.props.guild.selectedTeam.private_key) {
      this.state.client.preauthorizeApiKey(
        'Bearer',
        this.props.guild.selectedTeam.private_key,
      )
    }
  }

  toggleApiAccess = () => {
    this.setState({ savingChanges: true })
    TeamService.toggleApi(this.props.guild.id, this.props.guild.selectedTeam.id)
      .then((result) => {
        message.success(
          `API access ${
            !this.props.guild.selectedTeam.private_key ? 'enabled' : 'disabled'
          }.`,
          1,
        )
        this.props.refreshGuild(result.data)
        this.authorize()
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          'Something went wrong saving your changes. Please try again.',
          3,
        )
      })
      .then((_) => {
        this.setState({ savingChanges: false })
      })
  }

  copyKey = () => {
    navigator.clipboard.writeText(this.props.guild.selectedTeam.private_key)
    this.setState({ keyCopied: true })
    window.getSelection().removeAllRanges()
  }

  render() {
    return (
      <div
        className={`api-page ${
          !this.props.guild.selectedTeam.private_key && 'inactive'
        }`}
      >
        <h1>
          <div className='title-caption'>General</div>
          <Icon type='api' />
          API
        </h1>

        <p>
          You can interact with your team&apos;s data through API endpoints. By
          default, access to your team&apos;s data is disabled entirely. A
          private key will be generated when you enable access; with this key
          your entire environment is approachable, so keep it safe! To
          authenticate simply include the key as an Authorization header
          (prefixed with <em>Bearer </em>) or as an `api_key` query parameter.
        </p>

        <h2>Credentials</h2>

        <div className='api-key'>
          {!!this.props.guild.selectedTeam.private_key && (
            <Tooltip title={this.state.keyCopied ? 'Copied' : 'Click to copy'}>
              <span onClick={this.copyKey}>
                {this.props.guild.selectedTeam.private_key}
              </span>
            </Tooltip>
          )}
          {!this.props.guild.selectedTeam.private_key && (
            <span className='inactive'>
              Click the toggle to generate credentials and enable the API
            </span>
          )}
          <Switch
            checkedChildren={<Icon type='check' />}
            unCheckedChildren={<Icon type='close' />}
            checked={!!this.props.guild.selectedTeam.private_key}
            loading={this.state.savingChanges}
            onChange={this.toggleApiAccess}
          />
        </div>

        <SwaggerUI
          key={this.props.guild.selectedTeam.id}
          spec={swaggerSpec}
          onComplete={(client) =>
            this.setState({ client: client }, () => {
              this.authorize()
            })
          }
        />
      </div>
    )
  }
}

export default ApiPage
