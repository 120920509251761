import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { Formik, Form, FieldArray } from 'formik'
const cookies = new Cookies()

import { Input, Select, Checkbox, SubmitButton } from 'formik-antd'
import {
  Icon,
  Tooltip,
  Button,
  Popconfirm,
  message,
  Alert,
  Select as NonFormikSelect,
} from 'antd'

import RaidSettings from '../../../components/RaidSettings'
import VariableSettingsTable from '../../../components/VariableSettingsTable'
import WishlistConfigurations from '../../../components/WishlistConfigurations'

import InstanceService from '../../../services/InstanceService'
import TeamService from '../../../services/TeamService'

const newRank = {
  name: 'New rank',
  default: false,
  wishlist_visibility: 1,
  raid_visibility: 1,
  applications_visibility: 1,
  spreadsheet_roster_visibility: 1,
  spreadsheet_summary_visibility: 1,
  spreadsheet_overview_visibility: 1,
  spreadsheet_vault_visibility: 1,
  spreadsheet_profession_visibility: 1,
  spreadsheet_raids_visibility: 1,
  default_signup_status: null,
}

const SettingsPage = (props) => {
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [loadingResetSignups, setLoadingResetSignups] = useState(false)
  const [removingWishlists, setRemovingWishlists] = useState(false)
  const [removingLootHistory, setRemovingLootHistory] = useState(false)
  const [removingKey, setRemovingKey] = useState(false)
  const [keyCopied, setKeyCopied] = useState(false)
  const [instances, setInstances] = useState([])

  useEffect(() => {
    InstanceService.fetchByKind(props.guild.subkind, true).then((instances) => {
      setInstances(instances)
    })
  }, [])

  const saveChanges = (values) => {
    setLoadingSave(true)

    // Set non-standard values in the way Rails expects
    values.raid_days_attributes = values.raidDays
    values.key = removingKey ? 'new-key' : false

    if (values.api_key?.client_id || values.api_key?.client_secret) {
      values.api_key_attributes = values.api_key
    }

    values.team_ranks_attributes = values.character_ranks.map((rank) => {
      return {
        ...rank,
        default_signup_status:
          rank.default_signup_status === undefined
            ? null
            : rank.default_signup_status,
        default_selection_status:
          rank.default_selection_status === undefined
            ? null
            : rank.default_selection_status,
      }
    })

    values.settings_attributes = values.settings
    values.droptimizer_configurations_attributes =
      values.wishlist_configurations

    TeamService.update(props.guild.id, props.guild.selectedTeam.id, values)
      .then((result) => {
        if (values.name != props.guild.selectedTeam.name) {
          const url = props.path
          var path = [
            url.region,
            url.realm,
            url.guild,
            result.data.guild.selectedTeam.slug,
            url.category,
            url.page,
          ]
          history.pushState(null, null, `/${path.filter(Boolean).join('/')}`)
        }
        props.refreshGuild(result.data)
      })
      .then(() => {
        message.success(`Updated settings.`, 2)
      })
      .catch((result) => {
        if (result.response.status == 401) window.location.reload()
        message.error(
          `Couldn't save settings. Error: ${result.response.data.error}.`,
          3,
        )
      })
      .then(() => {
        setLoadingSave(false)
        setRemovingKey(false)
      })
  }

  const deleteTeam = () => {
    setLoadingRemove(true)
    TeamService.delete(props.guild.id, props.guild.selectedTeam.id)
      .then((result) => {
        window.location = result.data.guild.path
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          `Something went wrong trying to delete this team. Please try again.`,
          3,
        )
        setLoadingRemove(false)
      })
  }

  const removeLootHistory = () => {
    setRemovingLootHistory(true)
    TeamService.clearLootHistory(props.guild.id, props.guild.selectedTeam.id)
      .then(() => {
        message.success(`All loot history has been deleted.`, 5)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          `Something went wrong trying to delete the loot history. Please try again.`,
          3,
        )
      })
      .then(() => setRemovingLootHistory(false))
  }

  const removeWishlists = () => {
    setRemovingWishlists(true)
    TeamService.clearWishlists(props.guild.id, props.guild.selectedTeam.id)
      .then(() => {
        message.success(`All wishlists have been deleted.`, 5)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          `Something went wrong trying to delete wishlists. Please try again.`,
          3,
        )
      })
      .then(() => setRemovingWishlists(false))
  }

  const resetSignups = (values) => {
    setLoadingResetSignups(true)
    TeamService.resetSignups(props.guild.id, props.guild.selectedTeam.id, {
      default_signup_status: values.default_signup_status,
      default_selection_status: values.default_selection_status,
    })
      .then(() => {
        message.success('All future signups and selections have been reset.', 2)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          `Something went wrong trying to reset. Please try again.`,
          3,
        )
      })
      .then((_) => setLoadingResetSignups(false))
  }

  const isActive = (values, raidDay) => {
    return values.raidDays
      .map((day, _) => (!day.inactive ? day.day : null))
      .includes(raidDay.day)
  }

  const copyKey = () => {
    navigator.clipboard.writeText(props.guild.selectedTeam.key)
    setKeyCopied(true)
    window.getSelection().removeAllRanges()
  }

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  return (
    <div className='settings-page'>
      <Formik
        initialValues={props.guild.selectedTeam}
        enableReinitialize={true}
        validateOnChange={false} // https://github.com/jaredpalmer/formik/issues/2542
        onSubmit={async (values) => {
          saveChanges(values)
          // mmm
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <h1>
              <div className='title-caption'>
                General - Team {props.guild.selectedTeam.name}
              </div>
              <Icon type='setting' />
              Settings
            </h1>

            {!props.guild.selectedTeam.active && (
              <Alert
                message={
                  <React.Fragment>
                    This team exceeds your guild&apos;s team limit of{' '}
                    {props.guild.teamLimit}. You can go to the
                    <Link
                      onClick={() => props.changeTeam(props.guild.teams[0].id)}
                      to={`${props.guild.teams[0].path}/premium`}
                    >
                      {' '}
                      Patreon page
                    </Link>{' '}
                    to increase it again.
                  </React.Fragment>
                }
                type='error'
                showIcon
              />
            )}

            <div className='team-settings'>
              <div className='field-group'>
                <div className='field grow'>
                  <label>Team name</label>
                  <Input name='name' type='text' maxLength={20} />
                </div>

                <div className='field'>
                  <label>Theme</label>
                  <NonFormikSelect
                    placeholder='Theme'
                    defaultValue={cookies.get('theme') || 'Dark'}
                    optionFilterProp='children'
                    onChange={(value) => props.switchToTheme(value)}
                  >
                    {['Dark', 'Light'].map((mode, _) => (
                      <NonFormikSelect.Option
                        key={`theme-${mode}`}
                        value={mode}
                      >
                        {mode}
                      </NonFormikSelect.Option>
                    ))}
                  </NonFormikSelect>
                </div>

                <div className='field'>
                  <label>Spreadsheet key</label>
                  <div className='api-key'>
                    <Tooltip title={keyCopied ? 'Copied' : 'Click to copy'}>
                      <span
                        className={`key ${removingKey ? 'deleting' : ''}`}
                        onClick={copyKey}
                      >
                        {props.guild.selectedTeam.key}
                      </span>
                    </Tooltip>
                    <span className='action'>
                      {removingKey && (
                        <Tooltip title='Undo'>
                          <Icon
                            type='reload'
                            onClick={() => setRemovingKey(false)}
                          />
                        </Tooltip>
                      )}
                      {!removingKey && (
                        <Tooltip title='Click to reset your key'>
                          <Icon
                            type='close-circle'
                            onClick={() => setRemovingKey(true)}
                          />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <label>
                Spreadsheet URL&nbsp;
                <Tooltip title='Paste your Google Docs URL here to generate a shortlink that points to it.'>
                  <Icon type='question-circle-o' />
                </Tooltip>
                :&nbsp;&nbsp;
                <a
                  className='shortlink'
                  target='_blank'
                  rel='noreferrer'
                  href={`https://wowaudit.com/sheet${props.guild.selectedTeam.path}`}
                >
                  {`https://wowaudit.com/sheet${props.guild.selectedTeam.path}`}
                </a>
              </label>
              <Input
                type='text'
                name='spreadsheet_url'
                className='regular'
                maxLength={150}
              />
            </div>

            <h2>Raids</h2>
            <p>
              Select the days and times your team raids here, as well as some
              default settings. Raids will automatically be generated in the
              calendar. The date and times of a raid is always based on the
              server time of your guild&apos;s realm. Everyone in your roster
              will be able to change their sign-up status of each raid by
              visiting the page. Changing the default signup or selection status
              will not affect raids that already exist. You can optionally reset
              all future signups to force the new default for all future raids.
            </p>

            <div className='field-group'>
              <div className='field'>
                <label>
                  Default signup status&nbsp;
                  <Tooltip title="Characters with 'Unknown' status will be hidden from the raid page for non-admins. This setting only impacts raids that are created manually.">
                    <Icon type='info-circle' />
                  </Tooltip>
                </label>
                <Select
                  placeholder='Status'
                  name='default_signup_status'
                  optionFilterProp='children'
                >
                  {[
                    'present',
                    'absent',
                    'tentative',
                    'late',
                    'standby',
                    'unknown',
                  ].map((status, index) => (
                    <Select.Option key={index} value={status}>
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className='field'>
                <label>Default selection status</label>
                <Select
                  placeholder='Status'
                  name='default_selection_status'
                  optionFilterProp='children'
                >
                  {['Queued', 'Selected'].map((status, index) => (
                    <Select.Option key={index} value={index === 1}>
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className='field'>
                <Popconfirm
                  title='Are you sure you want to wipe ALL signup and selection data of ALL raids in the future?'
                  onConfirm={() => resetSignups(values)}
                  okText='Reset'
                >
                  <Button loading={loadingResetSignups}>Reset signups</Button>
                </Popconfirm>
              </div>
            </div>

            <div className='field-group'>
              <div className='field grow'>
                <label>
                  Discord webhook link&nbsp;
                  <Tooltip title='Paste your webhook here to be notified whenever someone changes their signup status for a raid.'>
                    <Icon type='question-circle-o' />
                  </Tooltip>
                </label>
                <Input type='text' className='regular' name='discord_webhook' />
              </div>
            </div>

            <div className='raid-days from-settings'>
              {values.raidDays.map((raidDay, i) => {
                return (
                  <div
                    key={`day-${raidDay.day}`}
                    className={`raid-day ${
                      isActive(values, raidDay) ? 'selected' : ''
                    }`}
                  >
                    <Checkbox
                      checked={isActive(values, raidDay)}
                      onChange={() =>
                        setFieldValue(
                          `raidDays.${i}.inactive`,
                          isActive(values, raidDay),
                        )
                      }
                    >
                      {days[raidDay.day]}
                    </Checkbox>

                    <RaidSettings
                      key={props.guild.selectedTeam.id}
                      instances={instances}
                      raid={raidDay}
                      updateRaid={(_, field, value) =>
                        setFieldValue(
                          `raidDays.${i}.${field}`,
                          value == 'Invalid date' ? '' : value,
                        )
                      }
                      active={isActive(values, raidDay)}
                      guild={props.guild}
                      showLabels={i === 0}
                    />
                  </div>
                )
              })}
            </div>

            <VariableSettingsTable {...props} scope='raids' />

            <h2 id='roster_ranks'>Roster ranks</h2>
            <p>
              You can create your own custom ranks here, and choose where
              characters of each rank are displayed.{' '}
              {props.guild.trackingBased
                ? 'When you grant a rank applications visibility, users that own the character will have access to the applications overview.'
                : ''}
            </p>

            <FieldArray
              name={`character_ranks`}
              render={(arrayHelpers) => (
                <div className='custom-ranks'>
                  {values.character_ranks.map((rank, index) => {
                    if (rank._destroy) {
                      return null
                    } else {
                      return (
                        <div key={index} className='rank-configuration'>
                          <div className='main-details'>
                            <div className='field'>
                              <label>Name</label>
                              <Input name={`character_ranks.${index}.name`} />
                            </div>

                            <div className='field'>
                              <label>
                                Default signup status&nbsp;
                                <Tooltip title='When this is configured for a rank, it will take precedence over the default signup status of raids.'>
                                  <Icon type='info-circle' />
                                </Tooltip>
                              </label>
                              <Select
                                placeholder='None'
                                name={`character_ranks.${index}.default_signup_status`}
                                optionFilterProp='children'
                                allowClear={true}
                                defaultActiveFirstOption={false}
                              >
                                {[
                                  'present',
                                  'absent',
                                  'tentative',
                                  'late',
                                  'standby',
                                  'unknown',
                                ].map((status, index) => (
                                  <Select.Option key={index} value={status}>
                                    {status}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>

                            <div className='field'>
                              <label>Default selection status</label>
                              <Select
                                placeholder='None'
                                name={`character_ranks.${index}.default_selection_status`}
                                optionFilterProp='children'
                                allowClear={true}
                                defaultActiveFirstOption={false}
                              >
                                {['Queued', 'Selected'].map((status, index) => (
                                  <Select.Option
                                    key={index}
                                    value={index === 1}
                                  >
                                    {status}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>

                            <div className='field'>
                              <label>Default rank</label>
                              <Checkbox
                                name={`character_ranks.${index}.default`}
                                onClick={() => {
                                  if (!rank.default) {
                                    values.character_ranks.map((_, i) =>
                                      index === i
                                        ? null
                                        : setFieldValue(
                                            `character_ranks.${i}.default`,
                                            false,
                                          ),
                                    )
                                  }
                                }}
                              />
                            </div>

                            <a
                              onClick={() => {
                                if (rank.id) {
                                  setFieldValue(
                                    `character_ranks.${index}._destroy`,
                                    true,
                                  )
                                } else {
                                  arrayHelpers.remove(index)
                                }
                              }}
                            >
                              <Icon type='delete' />
                            </a>
                          </div>

                          <div className='access'>
                            <div className='field'>
                              <label>Visible in spreadsheet</label>
                              <div className='rank-groups'>
                                <div>
                                  <div>
                                    <Checkbox
                                      name={`character_ranks.${index}.spreadsheet_summary_visibility`}
                                    >
                                      Summary
                                    </Checkbox>
                                  </div>
                                  <div>
                                    <Checkbox
                                      name={`character_ranks.${index}.spreadsheet_overview_visibility`}
                                    >
                                      Overview
                                    </Checkbox>
                                  </div>
                                  {props.guild.kind === 'live' && (
                                    <div>
                                      <Checkbox
                                        name={`character_ranks.${index}.spreadsheet_vault_visibility`}
                                      >
                                        Great Vault & Gear
                                      </Checkbox>
                                    </div>
                                  )}
                                </div>

                                <div>
                                  {props.guild.kind !== 'classic_era' && (
                                    <div>
                                      <Checkbox
                                        name={`character_ranks.${index}.spreadsheet_raids_visibility`}
                                      >
                                        Raids
                                      </Checkbox>
                                    </div>
                                  )}

                                  <div>
                                    <Checkbox
                                      name={`character_ranks.${index}.spreadsheet_roster_visibility`}
                                    >
                                      Roster
                                    </Checkbox>
                                  </div>

                                  {props.guild.kind === 'live' && (
                                    <div>
                                      <Checkbox
                                        name={`character_ranks.${index}.spreadsheet_profession_visibility`}
                                      >
                                        Professions
                                      </Checkbox>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className='field'>
                              <label>Visible in tools</label>
                              {props.guild.kind === 'live' && (
                                <div>
                                  <Checkbox
                                    name={`character_ranks.${index}.wishlist_visibility`}
                                  >
                                    Wishlists
                                  </Checkbox>
                                </div>
                              )}

                              <div>
                                <Checkbox
                                  name={`character_ranks.${index}.raid_visibility`}
                                >
                                  Raids
                                </Checkbox>
                              </div>

                              {props.guild.trackingBased && (
                                <div>
                                  <Checkbox
                                    name={`character_ranks.${index}.applications_visibility`}
                                  >
                                    Applications
                                  </Checkbox>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })}

                  <Button onClick={() => arrayHelpers.push(newRank)}>
                    Add new rank <Icon type='plus' />
                  </Button>
                </div>
              )}
            />

            {props.guild.kind == 'live' && (
              <React.Fragment>
                <h2>Wishlists</h2>

                {props.user.accessLevel === 0 && (
                  <div className='reset-wishlists'>
                    <VariableSettingsTable {...props} scope='wishlist' />

                    <WishlistConfigurations {...props} />

                    <h3>Reset wishlists</h3>
                    <p>
                      You can optionally reset all wishlist information manually
                      here. Keep in mind that when characters equip gear that is
                      listed on their wishlist, it is already automatically
                      removed. If a piece is equipped that doesn&apos;t match
                      what the Droptimizer was performed with an{' '}
                      <em>outdated</em> warning will be shown for the related
                      items.
                    </p>
                    <div>
                      <Button
                        type='danger'
                        loading={removingWishlists}
                        onClick={() => {
                          if (
                            confirm(
                              'Are you sure you want to delete all entered wishlist information? This cannot be undone.',
                            )
                          ) {
                            removeWishlists()
                          }
                        }}
                      >
                        Reset all wishlists
                      </Button>
                      <Button
                        type='danger'
                        loading={removingLootHistory}
                        onClick={() => {
                          if (
                            confirm(
                              'Are you sure you want to delete all loot history information? This cannot be undone.',
                            )
                          ) {
                            removeLootHistory()
                          }
                        }}
                      >
                        Reset loot history
                      </Button>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}

            <h2>Miscellaneous</h2>

            <VariableSettingsTable {...props} scope='team' />

            <h2>API credentials</h2>
            <p>
              You can optionally configure your own API credentials. If you do,
              all your data will be refreshed using your credentials. Your API
              key will <strong>never</strong> be used to refresh the data of
              other guilds. Since data refreshing is bottlenecked by API limits
              configuring your own credentials will <strong>double</strong> the
              speed that your data refreshes at.
            </p>

            <p>
              First, request or retrieve your API credentials from{' '}
              <a
                href='https://develop.battle.net/access'
                target='_blank'
                rel='noreferrer'
              >
                Blizzard
              </a>
              . You can leave the Service URL and Redirect URL fields empty, or
              you may paste your Google Docs link there. When you have your key,
              enter your Client ID and Client Secret here. Once the credentials
              are validated your refresh speed will be doubled.
            </p>

            {values.api_key && values.api_key.pending ? (
              <Alert
                type='warning'
                message='Your API configuration is pending, your refresh speed will increase once it is validated'
                showIcon
              />
            ) : values.api_key && values.api_key.active ? (
              <Alert
                type='success'
                message='Your current API configuration is valid'
                showIcon
              />
            ) : (
              values.api_key &&
              values.api_key.id &&
              !values.api_key.active && (
                <Alert
                  type='warning'
                  message='Your API configuration is invalid'
                  showIcon
                />
              )
            )}

            {values.api_key && values.api_key.id && (
              <p>
                Previously saved keys are stored even when the inputs below
                appear empty, for security reasons. If you wish to change your
                credentials, simply enter new values below.
              </p>
            )}

            <div className='api-key-container field-group'>
              <div className='field'>
                <label>Client ID</label>
                <Input
                  type='text'
                  name='api_key.client_id'
                  autoComplete='new-client-id'
                />
              </div>

              <div className='field'>
                <label>Client Secret</label>
                <Input
                  type='text'
                  name='api_key.client_secret'
                  autoComplete='new-password'
                />
              </div>
            </div>

            <div className='danger-actions'>
              {props.guild.teams.length > 1 && (
                <Popconfirm
                  title='Delete this team? This can NOT be undone!'
                  onConfirm={deleteTeam}
                  okText='Delete'
                >
                  <Button type='danger' loading={loadingRemove}>
                    Delete team
                  </Button>
                </Popconfirm>
              )}
            </div>

            <div className='save-changes-bar'>
              <SubmitButton loading={loadingSave}>Save changes</SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SettingsPage
