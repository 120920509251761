import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { subdomainForKind } from '../../Home'

import { Icon, Tooltip, Alert, Spin, Input, Button, message } from 'antd'
const { TextArea } = Input

import ActivityAlert from '../../../components/ActivityAlert'

import TeamService from '../../../services/TeamService'

class GuildHomePage extends React.Component {
  state = {
    keyCopied: false,
    currentTeam: this.props.guild.selectedTeam.id,
    description: this.props.guild.selectedTeam.description,
    refreshingGuildActivity: false,
  }

  componentDidMount() {
    this.fetchDashboardData()
  }

  componentDidUpdate() {
    // TODO: Refactor this, improve state management.
    if (this.state.currentTeam != this.props.guild.selectedTeam.id) {
      this.setState({
        team: undefined,
        currentTeam: this.props.guild.selectedTeam.id,
        description: this.props.guild.selectedTeam.description,
      })
      this.fetchDashboardData()
    }
  }

  fetchDashboardData = () => {
    TeamService.fetchDashboardData(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
    ).then((result) =>
      this.setState({
        team: result.data,
        description: result.data.description,
      }),
    )
  }

  copyKey = () => {
    navigator.clipboard.writeText(this.props.guild.selectedTeam.key)
    this.setState({ keyCopied: true })
    window.getSelection().removeAllRanges()
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value })
  }

  saveDescriptionChanges = () => {
    this.setState({ descriptionChanging: true })
    TeamService.update(this.props.guild.id, this.props.guild.selectedTeam.id, {
      description: this.state.description,
    })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          'Something went wrong saving your changes. Please try again.',
          3,
        )
      })
      .then(() => {
        this.setState({ descriptionChanging: false, editingDescription: false })
      })
  }

  content = () => {
    const teamSize = this.props.guild.selectedTeam.characters.length
    const notTrackedSize = this.props.guild.selectedTeam.characters.filter(
      (member) => !['tracking', 'pending'].includes(member.status),
    ).length
    const lastRefreshed =
      this.state.team && moment.unix(this.state.team.lastRefreshedCore)
    const upcomingRaid = this.state.team && this.state.team.upcomingRaid
    const openApplications = this.state.team && this.state.team.newApplications
    return (
      <div className='dashboard-blocks'>
        <div className='block wide description'>
          <h2>
            <Icon type='team' />
            Team {this.props.guild.selectedTeam.name}
            {this.props.user.accessLevel == 0 &&
              !this.state.editingDescription && (
                <Icon
                  type='edit'
                  onClick={() => this.setState({ editingDescription: true })}
                />
              )}
          </h2>

          {this.state.editingDescription && (
            <React.Fragment>
              <TextArea
                value={this.state.description}
                rows={5}
                onChange={(event) =>
                  this.handleInputChange('description', event.target.value)
                }
              />
              <div className='actions'>
                <Button
                  disabled={this.state.descriptionChanging}
                  onClick={() => this.setState({ editingDescription: false })}
                >
                  Cancel
                </Button>
                <Button
                  type='primary'
                  loading={this.state.descriptionChanging}
                  onClick={this.saveDescriptionChanges}
                >
                  Save changes
                </Button>
              </div>
            </React.Fragment>
          )}
          {!this.state.editingDescription && (
            <React.Fragment>
              {this.state.description && <p>{this.state.description}</p>}
              {!this.state.description && (
                <p className='no-description'>No description</p>
              )}
            </React.Fragment>
          )}
        </div>

        <div className='block spreadsheet'>
          <h2>
            <Icon type='link' />
            Spreadsheet
          </h2>
          {this.props.guild.selectedTeam.spreadsheet_url && (
            <React.Fragment>
              <p>Everything is set up! Visit your sheet here:</p>
              <a
                href={`https://wowaudit.com/sheet${this.props.guild.selectedTeam.path}`}
                target='_blank'
                rel='noreferrer'
              >
                https://wowaudit.com/sheet{this.props.guild.selectedTeam.path}
              </a>
            </React.Fragment>
          )}
          {!this.props.guild.selectedTeam.spreadsheet_url && (
            <React.Fragment>
              <p>
                You&apos;re almost ready.
                <a
                  href={`https://${
                    subdomainForKind[this.props.guild.kind] || ''
                  }wowaudit.com/copy`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  Click here
                </a>{' '}
                to make your own copy of the spreadsheet and paste this key in
                its Settings tab:
              </p>
              <span className='key' onClick={this.copyKey}>
                <Tooltip
                  placement='bottom'
                  title={this.state.keyCopied ? 'Copied' : 'Click to copy'}
                >
                  {this.props.guild.selectedTeam.key}
                </Tooltip>
              </span>
            </React.Fragment>
          )}
          {teamSize == 0 && (
            <Alert
              showIcon
              type='warning'
              message="Your team currently has no characters. Your spreadsheet won't show any data."
            />
          )}
        </div>

        {this.props.user.accessLevel == 0 && (
          <div className='block patreon'>
            <h2
              className={
                this.props.guild.patreonLevel
                  ? `level-${this.props.guild.patreonLevel.toLowerCase()}`
                  : ''
              }
            >
              <Icon type={this.props.guild.patreonLevel ? 'sketch' : 'close'} />
              Patreon
            </h2>
            {this.props.guild.patreonLevel && (
              <p>
                Your guild has{' '}
                <strong className={this.props.guild.patreonLevel.toLowerCase()}>
                  {this.props.guild.patreonLevel}
                </strong>{' '}
                level Patreon status. Thank you for your support!
              </p>
            )}
            {!this.props.guild.patreonLevel && (
              <p>
                Your guild does not have Patreon status. You can visit the
                <Link to={`${this.props.guild.selectedTeam.path}/premium`}>
                  {' '}
                  Patreon page{' '}
                </Link>{' '}
                to set it up and look at the benefits.
              </p>
            )}
          </div>
        )}

        <div className='block roster'>
          <h2>
            <Icon type='team' />
            Roster
          </h2>

          <p>
            Your team currently consists of <strong>{teamSize}</strong>{' '}
            character{teamSize == 1 ? '' : 's'}. You can visit the{' '}
            <Link to={`${this.props.guild.selectedTeam.path}/roster`}>
              {' '}
              roster page{' '}
            </Link>
            {this.props.accessLevel == 0
              ? ' to add or remove characters.'
              : 'to view the roster.'}
          </p>

          <div className='tracked-counter'>
            <span className='counter'>
              <Icon type={notTrackedSize > 0 ? 'warning' : 'check-circle'} />
              <span className={notTrackedSize > 0 ? 'incomplete' : 'complete'}>
                {teamSize - notTrackedSize}
              </span>{' '}
              / <span>{teamSize}</span>
            </span>
            tracking normally
          </div>
        </div>

        {(!this.state.team || lastRefreshed.isValid()) && (
          <div className='block refresh-status'>
            <h2>
              <Icon
                type={
                  this.props.guild.active && this.props.guild.expiry > 7
                    ? 'sync'
                    : 'warning'
                }
              />
              Refresh Status
            </h2>

            <p>Your team&apos;s spreadsheet data last refreshed</p>

            <div className='last-refreshed'>
              {this.state.team ? (
                lastRefreshed._i == 0 ? (
                  '-'
                ) : (
                  lastRefreshed.fromNow()
                )
              ) : (
                <Spin />
              )}
            </div>

            <p>
              You can visit the{' '}
              <Link
                to={`${this.props.guild.selectedTeam.path}/spreadsheet/status`}
              >
                {' '}
                status page{' '}
              </Link>{' '}
              to view more details about the refresh status
              {this.props.guild.manualRefresh
                ? ' and manually refresh your data'
                : ''}
              .
            </p>

            {!this.props.guild.patreonLevel && this.props.guild.expiry <= 7 && (
              <ActivityAlert
                guild={this.props.guild}
                refreshGuild={this.props.refreshGuild}
              />
            )}
          </div>
        )}

        <div className='block raids'>
          <h2>
            <Icon type='calendar' />
            Upcoming raid
          </h2>

          {!this.state.team && <Spin />}
          {!upcomingRaid && this.state.team && (
            <p>
              There aren&apos;t any upcoming raids.
              {this.props.user.accessLevel == 0 && (
                <React.Fragment>
                  You can set up persistent raid days on the
                  <Link to={`${this.props.guild.selectedTeam.path}/settings`}>
                    {' '}
                    settings page{' '}
                  </Link>{' '}
                  or create raids yourself in the{' '}
                  <Link to={`${this.props.guild.selectedTeam.path}/raids`}>
                    {' '}
                    raid calendar
                  </Link>
                  .
                </React.Fragment>
              )}
              {this.props.user.accessLevel > 0 && (
                <React.Fragment>
                  &nbsp;You can ask your guild&apos;s administrator to set up a
                  raid schedule.
                </React.Fragment>
              )}
            </p>
          )}
          {upcomingRaid && (
            <React.Fragment>
              <p>
                Your next{' '}
                <Link
                  to={`${this.props.guild.selectedTeam.path}/raids/${moment(
                    upcomingRaid.date,
                  ).format('YYYY-MM-DD')}`}
                >
                  {' '}
                  raid
                </Link>{' '}
                to
                <strong> {upcomingRaid.instance}</strong> start
                {moment(upcomingRaid.date).isBefore(moment()) ? 'ed' : 's'}
                <strong> {moment(upcomingRaid.date).fromNow()}</strong>.
              </p>

              <div className='tracked-counter'>
                <span className='counter'>
                  <Icon
                    type={
                      upcomingRaid.presentCount < upcomingRaid.okThreshold
                        ? 'warning'
                        : 'check-circle'
                    }
                  />
                  <span
                    className={
                      upcomingRaid.presentCount < upcomingRaid.okThreshold
                        ? 'incomplete'
                        : 'complete'
                    }
                  >
                    {upcomingRaid.presentCount}
                  </span>{' '}
                  / <span>{upcomingRaid.totalCount}</span>
                </span>
                signed up
              </div>
            </React.Fragment>
          )}
        </div>

        {this.props.user.accessLevel < 2 && (
          <div className='block recruitment'>
            <h2>
              <Icon type='unordered-list' />
              Recruitment
            </h2>

            <p>Your team currently has</p>

            <div className='pending-applications'>
              {this.state.team ? (
                <span className={openApplications > 0 ? 'alert' : ''}>
                  <Icon
                    type={openApplications > 0 ? 'warning' : 'check-circle'}
                  />
                  {openApplications}
                </span>
              ) : (
                <Spin />
              )}
            </div>

            <p>
              pending application{openApplications == 1 ? '' : 's'}. You can
              visit the
              <a
                href={`https://apply.wowaudit.com${this.props.guild.selectedTeam.path}`}
                target='_blank'
                rel='noreferrer'
              >
                {' '}
                application overview
              </a>{' '}
              to view more details about the pending applications.
            </p>
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className='dashboard-page'>
        <h1>
          <div className='title-caption'>
            Team {this.props.guild.selectedTeam.name}
          </div>
          <Icon type='dashboard' />
          {this.props.guild.name}&apos;s Dashboard
        </h1>

        {this.content()}
      </div>
    )
  }
}

export default GuildHomePage
