import React from 'react'
import { withRouter } from 'react-router-dom'
import Image from '../components/Image'
import bnetLogo from '../../../../assets/images/bnet-logo-white.svg'

const Logo = () => <img src={bnetLogo} />

import { Button, Icon } from 'antd'
import LandingPageContainer from '../components/LandingPageContainer'

class LinkDiscord extends React.Component {
  render() {
    return (
      <LandingPageContainer withoutSession={true}>
        <h1 className='logo'>
          <Image name='new-logo-icon' className='icon-only' extension='svg' />
          Link account
        </h1>
        <p>
          Log in here to link your wowaudit characters to your Discord account.
        </p>
        <p>
          <Button
            href={`/auth/bnet?origin=${encodeURIComponent(
              '/commit_discord_link',
            )}${encodeURIComponent(this.props.location.search)}`}
          >
            <Icon component={Logo} />
            Log in with Battle.net
          </Button>
        </p>
      </LandingPageContainer>
    )
  }
}

export default withRouter(LinkDiscord)
