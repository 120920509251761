import React from 'react'
import { HashLink } from 'react-router-hash-link'

import {
  Table,
  Popconfirm,
  Tooltip,
  Button,
  Select,
  Input,
  Icon,
  message,
} from 'antd'

import CharacterService from '../../services/CharacterService'

import Image from '../Image'

class RosterTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideError: () => null,
      characters: this.props.guild.selectedTeam.characters,
      pendingRemove: [],
      activeSorting: {},
    }
  }

  componentDidUpdate() {
    if (this.state.characters != this.props.guild.selectedTeam.characters) {
      this.setState({ characters: this.props.guild.selectedTeam.characters })
    }
  }

  handleInputChange = (name, value, characterId) => {
    this.setState({
      characters: this.state.characters.map((c) =>
        c.id === characterId && (c[name] = value) ? c : c,
      ),
    })
    return true
  }

  handleCharacterDelete = (record) => {
    this.setState((prevState, _) => {
      return { pendingRemove: prevState.pendingRemove.concat([record.key]) }
    })
    CharacterService.delete(this.props.guild.id, record.key)
      .then((result) => {
        message.success(`No longer tracking ${record.name}.`, 1.5)
        this.props.refreshGuild(result.data)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          `Something went wrong trying to untrack ${record.name}. Please try again.`,
          3,
        )
        this.setState((prevState, _) => {
          prevState.pendingRemove.splice(
            prevState.pendingRemove.indexOf(record.key),
            1,
          )
          return { pendingRemove: prevState.pendingRemove }
        })
      })
  }

  saveCharacterChanges = (characterId) => {
    this.state.hideError()
    const finishLoading = message.loading('Saving changes..', 0)
    CharacterService.update(
      this.props.guild.id,
      characterId,
      this.state.characters.find((c) => c.id === characterId),
    )
      .then((result) => {
        message.success('Changes saved.', 1)
        this.props.refreshGuild(result.data)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        this.setState({
          hideError: message.error(
            <React.Fragment>
              Something went wrong saving your changes. Please
              <a onClick={() => this.saveCharacterChanges(characterId)}>
                {' try again.'}
              </a>
            </React.Fragment>,
            0,
          ),
        })
      })
      .then(() => {
        finishLoading()
      })
  }

  handleTableChange = (_, _2, sorter) => {
    this.setState({ activeSorting: sorter })
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sortOrder:
          this.state.activeSorting.columnKey === 'name' &&
          this.state.activeSorting.order,
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, record) => {
          if (this.props.guild.kind == 'live') {
            return (
              <a
                className='roster-name'
                href={record.armoryLink}
                target='_blank'
                rel='noreferrer'
              >
                <Image name={`${record.class.replace(' ', '')}-2`} />
                <span>{record.name}</span>
              </a>
            )
          } else {
            return (
              <span>
                <Image name={`${record.class.replace(' ', '')}-2`} />
                <span>{record.name}</span>
              </span>
            )
          }
        },
      },
      { title: 'Realm', dataIndex: 'realm', key: 'realm' },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        sortOrder:
          this.state.activeSorting.columnKey === 'role'
            ? this.state.activeSorting.order
            : 'descend',
        sorter: (a, b) => a.role.localeCompare(b.role),
        render: (_, record) => (
          <div className='role-input-wrapper'>
            <Image name={record.role} />
            {this.props.user.accessLevel == 0 && (
              <Select
                placeholder='Role'
                defaultValue={record.role}
                onChange={(value) =>
                  this.handleInputChange('role', value, record.key) &&
                  this.saveCharacterChanges(record.key)
                }
              >
                {['Melee', 'Ranged', 'Heal', 'Tank'].map((role, index) => (
                  <Select.Option key={role} value={role}>
                    {role}
                  </Select.Option>
                ))}
              </Select>
            )}
            {this.props.user.accessLevel > 0 && <span>{record.role}</span>}
          </div>
        ),
      },
      {
        title: 'Rank',
        dataIndex: 'rank',
        key: 'rank_name',
        render: (_, record) => {
          return (
            <React.Fragment>
              {this.props.user.accessLevel == 0 && (
                <Select
                  placeholder='Rank'
                  className='rank-select'
                  defaultValue={record.team_rank_id}
                  dropdownRender={(dropdown) => (
                    <div>
                      {dropdown}
                      <li
                        className='ant-select-dropdown-menu-item add-entity'
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        <HashLink
                          to={`${this.props.guild.selectedTeam.path}/settings#roster_ranks`}
                        >
                          <Icon type='plus' /> Create rank
                        </HashLink>
                      </li>
                    </div>
                  )}
                  onChange={(value) =>
                    this.handleInputChange('team_rank_id', value, record.key) &&
                    this.saveCharacterChanges(record.key)
                  }
                >
                  {this.props.guild.selectedTeam.character_ranks.map((rank) => (
                    <Select.Option
                      key={rank.id}
                      value={rank.id}
                      className='lower'
                    >
                      {rank.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
              {this.props.user.accessLevel > 0 && (
                <span>{record.rank_name}</span>
              )}
            </React.Fragment>
          )
        },
      },
      {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
        render: (_, record) => {
          return (
            <React.Fragment>
              {this.props.user.accessLevel == 0 && (
                <Input
                  defaultValue={record.note}
                  onChange={(event) =>
                    this.handleInputChange(
                      'note',
                      event.target.value,
                      record.key,
                    )
                  }
                  onBlur={() => this.saveCharacterChanges(record.key)}
                />
              )}
              {this.props.user.accessLevel > 0 && <span>{record.note}</span>}
            </React.Fragment>
          )
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => (
          <span className={record.status.replace(' ', '-')}>
            {record.status}
            {record.status === 'invisible' ? (
              <Tooltip
                title="This character's rank has no visibility on any spreadsheet tab (change in settings)"
                placement='left'
              >
                <Icon type='info-circle' />
              </Tooltip>
            ) : null}
          </span>
        ),
      },
    ].concat(
      this.props.user.accessLevel > 0
        ? {}
        : {
            title: '',
            dataIndex: 'delete',
            render: (_, record) => (
              <React.Fragment>
                <Popconfirm
                  title='Untrack this character?'
                  onConfirm={() => this.handleCharacterDelete(record)}
                  okText='Untrack'
                >
                  {record.trackedByRule &&
                    !this.state.pendingRemove.includes(record.key) && (
                      <div className='auto-tracked'>
                        <Tooltip placement='left' title='Tracked automatically'>
                          <Icon type='setting' />
                        </Tooltip>
                      </div>
                    )}
                  {(!record.trackedByRule ||
                    this.state.pendingRemove.includes(record.key)) && (
                    <Button
                      loading={this.state.pendingRemove.includes(record.key)}
                    >
                      untrack
                    </Button>
                  )}
                </Popconfirm>
              </React.Fragment>
            ),
          },
    )

    return (
      <Table
        id='roster-table'
        size='small'
        pagination={false}
        scroll={{ x: 'max-content' }}
        columns={columns}
        onChange={this.handleTableChange}
        dataSource={this.props.guild.selectedTeam.characters}
      />
    )
  }
}

export default RosterTable
