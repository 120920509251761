import axios from 'axios'

axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true

import { message } from 'antd'

class CharacterService {
  async update(guildId, id, character) {
    return await axios.put(`/api/guilds/${guildId}/characters/${id}`, {
      character,
    })
  }

  async create(guildId, teamId, details) {
    return await axios.post(`/api/guilds/${guildId}/characters`, {
      team_id: teamId,
      details,
    })
  }

  async delete(guildId, id) {
    return await axios.delete(`/api/guilds/${guildId}/characters/${id}`)
  }

  async updateWishlist(guildId, character) {
    character.wishlist_items_attributes = character.items
    return await axios.post(
      `/api/guilds/${guildId}/characters/${character.id}/wishlist_items`,
      { character },
    )
  }

  async updateRaidStatus(guildId, teamId, id, raids, presence, comment, limit) {
    return await axios
      .put(
        `/api/guilds/${guildId}/characters/${id}/raid_status${
          limit ? `?limit=${limit}` : ''
        }`,
        {
          raids: raids,
          team_id: teamId,
          presence: presence,
          comment: comment,
        },
      )
      .then((result) => {
        return result.data.raids
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error('Saving raid status failed. Please try again later.', 5)
        return []
      })
  }
}

export default new CharacterService()
