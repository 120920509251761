import React, { useEffect, useState } from 'react'
import { Button, message, Modal, Slider } from 'antd'

import WishlistService from '../services/WishlistService'

const SpecWeightModal = ({
  specs,
  editingWeights,
  setEditingWeights,
  adjustedWeights,
  setAdjustedWeights,
  guildId,
  character,
}) => {
  const [savingWeights, setSavingWeights] = useState(false)
  const [weights, setWeights] = useState([])

  useEffect(() => {
    if (specs) {
      const newWeights = adjustedWeights
        ? Object.values(adjustedWeights)
        : specs.map((spec) => spec.weight || 0)

      if (newWeights.reduce((a, b) => a + b, 0) === 100) {
        setWeights(newWeights)
      } else {
        setWeights(specs.map((spec, i) => (i + 1 === specs.length ? 100 : 0)))
      }
    }
  }, [specs, character])

  useEffect(() => {
    const changes = {}
    specs.forEach((spec, i) => (changes[spec.id] = weights[i]))

    if (savingWeights) {
      WishlistService.updateSpecWeights(guildId, character.id, changes)
        .then(() => {
          message.success('Specialization weight changes saved.', 5)
          setAdjustedWeights(changes)
          setEditingWeights(false)
          setSavingWeights(false)
          setWeights(Object.values(changes))
        })
        .catch(() => {
          message.error(
            'Specialization weight changes failed. Please try again.',
            5,
          )
          setSavingWeights(false)
        })
    }
  }, [savingWeights])

  return (
    <Modal
      visible={editingWeights}
      title='Edit specialization weights'
      onOk={() => setSavingWeights(true)}
      onCancel={() => setEditingWeights(false)}
      footer={[
        <Button
          key='back'
          disabled={savingWeights}
          onClick={() => setEditingWeights(false)}
        >
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          disabled={savingWeights}
          loading={savingWeights}
          onClick={() => setSavingWeights(true)}
        >
          Save changes
        </Button>,
      ]}
    >
      <div className='spec-weight-modal'>
        {specs.map((spec, i) => {
          const start = weights.reduce((total, weight, sumIndex) => {
            if (sumIndex >= i) return total
            return total + weight
          }, 0)
          return (
            <div className='spec' key={spec.id}>
              <h4>{spec.name}</h4>
              <div>
                <figure>
                  <img
                    alt={spec.name}
                    title={spec.name}
                    src={`https://render.worldofwarcraft.com/us/icons/36/${spec.icon}.jpg`}
                  />
                </figure>
                <Slider
                  range
                  onChange={([newStart, newEnd]) => {
                    if (newStart !== start) {
                      if (i === 0) return

                      const changeRequired = start - newStart
                      setWeights(
                        weights.map((weight, weightIndex) => {
                          if (
                            i - 3 === weightIndex &&
                            changeRequired > weights[i - 1] + weights[i - 2]
                          ) {
                            return (
                              weight -
                              (changeRequired - weights[i - 1] - weights[i - 2])
                            )
                          }

                          if (
                            i - 2 === weightIndex &&
                            changeRequired > weights[i - 1]
                          ) {
                            if (changeRequired - weights[i - 1] > weight) {
                              return 0
                            } else {
                              return weight - (changeRequired - weights[i - 1])
                            }
                          }

                          if (i - 1 === weightIndex) {
                            if (changeRequired > weight) {
                              return 0
                            } else {
                              return weight - changeRequired
                            }
                          }

                          if (i === weightIndex) {
                            return weight + changeRequired
                          }

                          return weight
                        }),
                      )
                    } else if (newEnd !== weights[i]) {
                      if (i + 1 === weights.length) return

                      const changeRequired = newEnd - (start + weights[i])
                      setWeights(
                        weights.map((weight, weightIndex) => {
                          if (i === weightIndex) {
                            return weight + changeRequired
                          }

                          if (i + 1 === weightIndex) {
                            if (changeRequired > weight) {
                              return 0
                            } else {
                              return weight - changeRequired
                            }
                          }

                          if (
                            i + 2 === weightIndex &&
                            changeRequired > weights[i + 1]
                          ) {
                            if (changeRequired - weights[i + 1] > weight) {
                              return 0
                            } else {
                              return weight - (changeRequired - weights[i + 1])
                            }
                          }

                          if (
                            i + 3 === weightIndex &&
                            changeRequired > weights[i + 1] + weights[i + 2]
                          ) {
                            return (
                              weight -
                              (changeRequired - weights[i + 1] - weights[i + 2])
                            )
                          }

                          return weight
                        }),
                      )
                    }
                  }}
                  value={[start, start + weights[i]]}
                />
                <span>{weights[i]}%</span>
              </div>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

export default SpecWeightModal
