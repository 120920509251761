class InstanceService {
  async fetchAll() {
    const response = await fetch(`/api/instances`, { credentials: 'include' })
    return await response.json()
  }

  async fetchByKind(kind, include_fated = false) {
    const response = await fetch(
      `/api/instances?kind=${kind}&include_fated=${include_fated}`,
      {
        credentials: 'include',
      },
    )
    return await response.json()
  }
}

export default new InstanceService()
