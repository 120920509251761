import React from 'react'
import { Icon, Tooltip } from 'antd'
import { groupBy, sumBy } from 'lodash'

import Image from './Image'
import { specIcon } from '../helpers'

const WishlistOverviewWish = ({
  wishlist,
  characterInfo,
  valueDisplay,
  difficultyIndex,
  currentTrack,
  trackOnly = false,
  equippedItemLevels,
  item,
}) => {
  const baseWowheadUrl = `https://wowhead.com/`
  const tracks = [
    'Myth',
    'Hero',
    'Champion',
    'Veteran',
    'Adventurer',
    'Explorer',
    'previous season',
  ]

  const wishlistUpgrade = (wishlist) => {
    if (wishlist.manual_upgrade) {
      return (
        <span>
          <Tooltip title={wishlist.manual_upgrade} placement='top'>
            <span
              className={`priority ${wishlist.manual_upgrade.toLowerCase()}`}
            />
          </Tooltip>
        </span>
      )
    } else if (valueDisplay === 'percentage' || wishlist.edited) {
      return (
        <span className='value'>
          {wishlist.weighted_percentage?.toFixed(2)}%
        </span>
      )
    } else {
      return (
        <span className='value'>{wishlist.weighted_absolute?.toFixed(0)}</span>
      )
    }
  }

  const wishUpgrade = (wishes) => {
    const customLevelWishes = wishes.filter((wish) => wish.upgrade_level)
    if (customLevelWishes.length > 0) {
      return customLevelWishes.map((wish, i) => (
        <span key={i}>
          <Tooltip title={wish.upgrade_level} placement='top'>
            <span className={`priority ${wish.upgrade_level.toLowerCase()}`} />
          </Tooltip>
        </span>
      ))
    } else if (
      valueDisplay === 'percentage' ||
      wishes.filter((wish) => wish.wish_type === 'percentage').length > 0
    ) {
      return (
        <span className='value'>
          {sumBy(
            wishes,
            (wish) =>
              (wish.percentage || 0) * (wish.configuration_percentage / 100.0),
          ).toFixed(2)}
          %
        </span>
      )
    } else {
      return (
        <span className='value'>
          {sumBy(
            wishes,
            (wish) =>
              (wish.absolute || 0) * (wish.configuration_percentage / 100.0),
          ).toFixed(0)}
        </span>
      )
    }
  }

  return (
    <div
      className={`wish${wishlist.edited ? ' edited' : ''}${
        wishlist.outdated ? ' outdated' : ''
      }${trackOnly ? ' track-only' : ''}`}
      style={{
        width: 'min(24rem, calc(100vw - 14.5rem))',
        height: `${
          0.8 +
          2.1 *
            (wishlist.wishes
              ? groupBy(wishlist.wishes, (wish) => wish.specialization_id)
              : ['']
            ).length
        }rem`,
      }}
    >
      <Image name={`${characterInfo.class.replace(' ', '')}-2`} />
      <a
        className='name'
        href={characterInfo.armoryLink}
        target='_blank'
        rel='noreferrer'
      >
        {characterInfo.name}
      </a>

      {wishlist.comment && (
        <div className='comment'>
          <Tooltip
            title={wishlist.comment}
            placement='top'
            overlayStyle={{ whiteSpace: 'pre-line' }}
          >
            <Icon type='message' />
          </Tooltip>
        </div>
      )}

      {currentTrack && currentTrack - difficultyIndex > 0 ? (
        <Tooltip
          title={`Currently equipped item is ${
            currentTrack === 6
              ? `not on an upgrade track (item level ${
                  equippedItemLevels[item.equipment_slot] || 'unknown'
                })`
              : ` on the ${tracks[currentTrack]} track`
          }`}
          className='track-difference'
        >
          <Icon type='arrow-up' />
          <span>
            {currentTrack === 6 ? '?' : currentTrack - difficultyIndex}
          </span>
        </Tooltip>
      ) : (
        ''
      )}

      {trackOnly ? (
        ''
      ) : (
        <React.Fragment>
          <div className='wishes-list'>
            {Object.values(
              groupBy(wishlist.wishes, (wish) => wish.specialization_id),
            ).map((wishes, i) => (
              <span key={i}>
                {specIcon(
                  wishes[0].specialization_icon,
                  wishes[0].specialization_name,
                )}{' '}
                {wishUpgrade(wishes)}
              </span>
            ))}
          </div>
          <div
            className={`wishlist-details${
              wishlist.alternative ? ' suboptimal' : ''
            }`}
          >
            <Icon type='info-circle' />
            <span className='transition' />
            <div className='wishlist-details-container'>
              <div>
                {wishlist.alternative ? (
                  <div className='warning'>
                    <Icon type='warning' />
                    <div>
                      There is a{' '}
                      {valueDisplay === 'percentage'
                        ? `${wishlist.alternative[valueDisplay]?.toFixed(2)}%`
                        : `${wishlist.alternative[valueDisplay]?.toFixed(
                            0,
                          )} value`}{' '}
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={`${baseWowheadUrl}item=${wishlist.alternative.item}?ilvl=${wishlist.alternative.item_level}`}
                      >
                        upgrade
                      </a>{' '}
                      that is better in this slot than this item.
                    </div>
                  </div>
                ) : !wishlist.manual_upgrade ? (
                  <div className='info'>
                    <Icon type='check-circle' />
                    <div>
                      This is the best in slot item for this character on this
                      difficulty.
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {wishlist.edited ? (
                  <div className='warning'>
                    <Icon type='warning' />
                    <div>This is a manually edited wish.</div>
                  </div>
                ) : wishlist.outdated ? (
                  <div className='warning'>
                    <Icon type='warning' />
                    <div>
                      This wish is outdated, the{' '}
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={`${baseWowheadUrl}item=${wishlist.outdated.old.id}?ilvl=${wishlist.outdated.old.item_level}`}
                      >
                        item
                      </a>{' '}
                      worn when running the Droptimizer report doesn&apos;t
                      match the{' '}
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={`${baseWowheadUrl}item=${wishlist.outdated.new.id}?ilvl=${wishlist.outdated.new.item_level}`}
                      >
                        item
                      </a>{' '}
                      that is currently worn.
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>Style</th>
                      <th>Weight</th>
                      <th>Upgrade</th>
                      <th>
                        <Tooltip placement='top' title='Age in days'>
                          <Icon type='clock-circle' />
                        </Tooltip>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {wishlist.wishes.map((wish) => (
                      <tr key={wish.id}>
                        <td>
                          {specIcon(
                            wish.specialization_icon,
                            wish.specialization_name,
                          )}
                        </td>
                        <td>{wish.fight_style}</td>
                        <td className='weight'>{wish.weight_percentage}%</td>
                        <td>
                          {wish.percentage ? (
                            valueDisplay === 'percentage' ? (
                              `${wish.percentage?.toFixed(2)}%`
                            ) : (
                              `${wish.absolute?.toFixed(0) || 0}`
                            )
                          ) : (
                            <span
                              className={`priority ${wish.upgrade_level}`}
                            />
                          )}
                        </td>
                        <td
                          className={`age ${
                            wish.age > 21
                              ? 'month'
                              : wish.age > 7
                              ? 'week'
                              : 'day'
                          }`}
                        >
                          {wish.age}
                        </td>
                        <td>
                          {wish.report_url && (
                            <a
                              href={wish.report_url}
                              target='_blank'
                              rel='noreferrer'
                              title='Raidbots'
                            >
                              <Icon type='link' />
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr className='total'>
                      <td />
                      <td />
                      <td />
                      <td>{wishlistUpgrade(wishlist)}</td>
                      <td />
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default WishlistOverviewWish
