import React from 'react'
import { Icon } from 'antd'
import ProfileInfo from '../../components/ProfileInfo'

const ProfilePage = (props) => {
  return (
    <div className='guild-dashboard-profile'>
      <h1>
        <div className='title-caption'>Profile</div>
        <Icon type='user' />
        {props.user.battletag}
      </h1>

      <ProfileInfo
        user={props.user}
        kind={props.kind}
        region={props.guild.region}
        refreshUrl={`/api/guilds/${props.guild.id}/teams/${props.guild.selectedTeam.id}/refresh_characters`}
      />
    </div>
  )
}

export default ProfilePage
