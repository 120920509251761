import React, { useEffect, useMemo, useState } from 'react'
import { Icon, Table, Tooltip } from 'antd'
import { keyBy, groupBy, maxBy, sortBy, intersection } from 'lodash'
import moment from 'moment'

import Image from './Image'
import LootStatisticsBar from './LootStatisticsBar'
import LootHistoryResponseTypeModal from './LootHistoryResponseTypeModal'

const LootStatisticsTable = (props) => {
  const [hiddenResponseTypes, setHiddenResponseTypes] = useState(
    JSON.parse(localStorage.getItem('hiddenResponseTypes') || '[]'),
  )
  const [sortingBy, setSortingBy] = useState({
    columnKey: 'total',
    order: 'ascend',
  })

  useEffect(() => {
    localStorage.setItem(
      'hiddenResponseTypes',
      JSON.stringify(hiddenResponseTypes),
    )
  }, [hiddenResponseTypes])

  const charactersById = useMemo(() => {
    keyBy(props.characters, 'id')
  }, [props.characters])

  const handleTableChange = (_pagination, _filters, sorter) => {
    setSortingBy(sorter)
  }

  const averageForAwards = (awards, type) => {
    const filteredAwards = awards.filter((award) => award[type] !== null)

    if (filteredAwards.length === 0) {
      return null
    } else {
      return (
        filteredAwards.reduce((acc, award) => {
          return acc + award[type]
        }, 0) / filteredAwards.length
      )
    }
  }

  const columns = useMemo(() => {
    const filteredItems = sortBy(
      props.items.filter(
        (item) =>
          !item.discarded &&
          !item.propagated_reponse_type.excluded &&
          !hiddenResponseTypes.includes(item.propagated_reponse_type.id),
      ),
      (item) =>
        props.responseTypeOrder.indexOf(item.propagated_reponse_type.id),
    )
    const awardsByCharacter = groupBy(filteredItems, 'character_id')
    const maxSize = Math.max(
      ...Object.values(awardsByCharacter).map((awards) => awards.length),
    )
    const showCompetition = filteredItems.some(
      (item) => item.same_response_amount !== null,
    )
    const showWishValue = filteredItems.some((item) => item.wish_value !== null)

    return [
      {
        title: 'Character',
        dataIndex: 'name',
        key: 'name',
        sortOrder: sortingBy.columnKey === 'name' && sortingBy.order,
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: function renderName(_, record) {
          return (
            <a
              className='roster-name'
              href={record.armoryLink}
              target='_blank'
              rel='noreferrer'
            >
              <Image name={record.role} />
              <Image
                name={`${record.class && record.class.replace(' ', '')}-2`}
              />
              <span>{record.name}</span>
            </a>
          )
        },
      },
      {
        title: 'Total',
        dataIndex: '',
        key: 'total',
        render: function renderTotal(_, record) {
          if (props.loading) return ''

          const awards = awardsByCharacter[record.id] || []
          return awards.length
        },
        defaultSortOrder: 'ascend',
        className: 'centered hide-sorter',
        sortOrder: sortingBy.columnKey === 'total' && sortingBy.order,
        sorter: (a, b) => {
          const awardsA = awardsByCharacter[a.id] || []
          const awardsB = awardsByCharacter[b.id] || []
          return awardsB.length - awardsA.length
        },
      },
      {
        title: 'Awarded',
        dataIndex: '',
        key: 'awarded',
        width: '100%',
        render: function renderAwarded(_, record) {
          const awards = awardsByCharacter[record.id] || []
          return (
            <LootStatisticsBar
              groupedAwards={groupBy(
                awards,
                (a) => a.propagated_reponse_type.id,
              )}
              maxSize={maxSize}
              categories={props.responseTypes}
              categoryOrder={props.responseTypeOrder}
            />
          )
        },
      },
    ]
      .concat(
        showCompetition
          ? [
              {
                title: (
                  <Tooltip
                    title={
                      <span>
                        <p>
                          Average competition. Displays the average amount of
                          other people that had the same response for each
                          awarded item.
                        </p>
                        <div style={{ opacity: 0.5 }}>
                          This is only available for items that are awarded when
                          the wowaudit addon is enabled.
                        </div>
                      </span>
                    }
                    placement='left'
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                  >
                    <Icon type='info-circle' />
                  </Tooltip>
                ),
                dataIndex: '',
                key: 'same_response_amount',
                className: 'centered',
                render: function renderCompetition(_, record) {
                  if (props.loading) return ''

                  const competition = averageForAwards(
                    awardsByCharacter[record.id] || [],
                    'same_response_amount',
                  )
                  return competition === null ? '-' : competition.toFixed(1)
                },
              },
            ]
          : [],
      )
      .concat(
        showWishValue
          ? [
              {
                title: (
                  <span>
                    Value{' '}
                    <Tooltip
                      title={
                        <span>
                          <p>
                            Displays the average wishlist upgrade value of
                            awarded items.
                          </p>
                          <div style={{ opacity: 0.5 }}>
                            This is only available for items that are awarded
                            when the wowaudit addon is enabled.
                          </div>
                        </span>
                      }
                      placement='left'
                      overlayStyle={{ whiteSpace: 'pre-line' }}
                    >
                      <Icon type='info-circle' />
                    </Tooltip>
                  </span>
                ),
                dataIndex: '',
                key: 'wish_value',
                className: 'centered',
                render: function renderWishValue(_, record) {
                  if (props.loading) return ''

                  const wishValue = averageForAwards(
                    awardsByCharacter[record.id] || [],
                    'wish_value',
                  )
                  return wishValue === null ? '-' : wishValue.toFixed(0)
                },
              },
            ]
          : [],
      )
      .concat([
        {
          title: 'Last loot',
          dataIndex: '',
          key: 'last_loot',
          className: 'hide-sorter',
          sortOrder: sortingBy.columnKey === 'last_loot' && sortingBy.order,
          sorter: (a, b) => {
            const lastAwardA = maxBy(awardsByCharacter[a.id] || [], (loot) =>
              moment(loot.awarded_at).unix(),
            )
            const lastAwardB = maxBy(awardsByCharacter[b.id] || [], (loot) =>
              moment(loot.awarded_at).unix(),
            )

            if (lastAwardA && lastAwardB) {
              return lastAwardA.awarded_at.localeCompare(lastAwardB.awarded_at)
            } else if (lastAwardA) {
              return 1
            } else {
              return -1
            }
          },
          render: function renderDate(_, record) {
            if (props.loading) return ''

            const awards = awardsByCharacter[record.id] || []
            const lastAward = maxBy(awards, (a) => moment(a.awarded_at).unix())
            return lastAward
              ? moment(lastAward.awarded_at).format('MMM D')
              : 'Never'
          },
        },
      ])
    // Categories, total, average competition, average wish value, last item date
  }, [charactersById, props.items, sortingBy, hiddenResponseTypes])

  const awardCountByType = useMemo(() => {
    const filteredItems = props.items.filter((item) => !item.discarded)
    return groupBy(filteredItems, 'propagated_reponse_type.id')
  }, [props.items])

  return (
    <div>
      {Object.keys(awardCountByType).length ? (
        <div className='legend-container'>
          <div className='legend'>
            {intersection(
              props.responseTypeOrder,
              Object.keys(awardCountByType).map((typeId) => parseInt(typeId)),
            ).map((typeId) => {
              const type = props.responseTypes.find(
                (t) => t.id === parseInt(typeId),
              )

              if (type.excluded) {
                return null
              } else {
                return (
                  <div
                    key={type.id}
                    className={
                      hiddenResponseTypes.includes(type.id) ? 'hidden' : ''
                    }
                    onClick={() => {
                      if (hiddenResponseTypes.includes(type.id)) {
                        setHiddenResponseTypes(
                          hiddenResponseTypes.filter((t) => t !== type.id),
                        )
                      } else {
                        setHiddenResponseTypes([
                          ...hiddenResponseTypes,
                          type.id,
                        ])
                      }
                    }}
                  >
                    <div
                      className='legend-color'
                      style={{ backgroundColor: type.rgba }}
                    />
                    <span className='name'>{type.name}</span>
                    <span>{awardCountByType[type.id]?.length}</span>
                  </div>
                )
              }
            })}
          </div>
          <div>
            <div className='toggle-all'>
              <span onClick={() => setHiddenResponseTypes([])}>show</span> /{' '}
              <span
                onClick={() =>
                  setHiddenResponseTypes(props.responseTypes.map((t) => t.id))
                }
              >
                hide
              </span>{' '}
              all
            </div>
            <LootHistoryResponseTypeModal {...props} />
          </div>
        </div>
      ) : null}

      <Table
        id='loot-statistics-table'
        size='small'
        scroll={{ x: 'max-content' }}
        loading={props.loading}
        rowKey='id'
        onChange={handleTableChange}
        pagination={false}
        columns={columns}
        dataSource={props.characters}
      />
    </div>
  )
}

export default LootStatisticsTable
