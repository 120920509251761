import React from 'react'
import Image from '../components/Image'

import { Layout } from 'antd'
const { Footer } = Layout

import { labelForKind, subdomainForKind } from '../containers/Home'

class HomepageFooter extends React.Component {
  render() {
    const kind = document.getElementById('subdomain')?.getAttribute('data-kind')

    return (
      <Footer>
        <div className='left'>
          <a className='logo' href='/'>
            <span>
              <Image name='new-logo' extension='svg' />
            </span>
            <span className='kind'>-</span>
            <span className='kind'>{labelForKind[kind] || 'War Within'}</span>
          </a>
        </div>
        <div className='right'>
          {kind !== 'live' && <a href={`https://wowaudit.com/`}>War Within</a>}
          {kind !== 'classic_progression' && (
            <a href={`https://classic.wowaudit.com/`}>Cataclysm</a>
          )}
          {kind !== 'classic_era' && (
            <a href={`https://vanilla.wowaudit.com/`}>Classic Era</a>
          )}
          <a
            target='_blank'
            rel='noreferrer'
            href={`https://${subdomainForKind[kind] || ''}wowaudit.com/demo`}
          >
            Demo
          </a>
          <a target='_blank' rel='noreferrer' href='https://discord.gg/86eUAFz'>
            Discord
          </a>
          <a href='/desktop'>Desktop</a>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.patreon.com/auditspreadsheet'
          >
            Patreon
          </a>
          <a href='/privacy'>Privacy</a>
        </div>
      </Footer>
    )
  }
}

export default HomepageFooter
