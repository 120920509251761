import React from 'react'

import { Icon, Spin, Button, message, Select, Alert } from 'antd'

import Image from '../Image'

import TeamService from '../../services/TeamService'

class TrackingRulesForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      trackingRules: this.props.guild.selectedTeam.trackingRules,
      currentTeam: this.props.guild.selectedTeam.id,
      loading: false,
    }
  }

  componentDidUpdate() {
    // TODO: Refactor this, improve state management
    if (this.state.currentTeam != this.props.guild.selectedTeam.id) {
      this.setState({
        trackingRules: this.props.guild.selectedTeam.trackingRules,
        currentTeam: this.props.guild.selectedTeam.id,
      })
    }
  }

  handleInputChange = (name, value, rule) => {
    this.setState({
      trackingRules: this.state.trackingRules.map((c) =>
        c.rank === rule.rank && (c[name] = value) ? c : c,
      ),
    })
    return true
  }

  updateTrackingRules = () => {
    this.setState({ loading: true })
    TeamService.update(this.props.guild.id, this.props.guild.selectedTeam.id, {
      tracking_rules_attributes: this.state.trackingRules,
    })
      .then((result) => this.props.refreshGuild(result.data))
      .then(() => {
        message.success(`Updated tracking rules.`, 2)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          `Something went wrong trying to update the tracking rules. Please try again.`,
          3,
        )
      })
      .then(() => this.setState({ loading: false }))
  }

  sampleForRank = (rank) => {
    if (this.props.characters) {
      return this.props.characters.filter(
        (character) => character.rank == rank,
      )[0]
    }
  }

  render() {
    let record
    return (
      <div className='tracking-rules-form'>
        <Spin spinning={this.state.loading}>
          <h3>Select guild ranks to track automatically</h3>
          {this.props.guild.selectedTeam.characters.length >=
            this.props.guild.characterLimit && (
            <Alert
              message={`Your team's character limit of ${this.props.guild.characterLimit} has been reached.`}
              type='warning'
              showIcon
            />
          )}
          <div className='ranks'>
            {this.state.trackingRules.map((rule, _) => (
              <div
                className={`rank ${rule.tracking ? 'tracking' : ''}`}
                key={`tracking-rule-rank-${rule.rank}`}
                onClick={(e) => {
                  if (
                    e.target.tagName === 'svg' ||
                    e.target.tagName === 'path' ||
                    !e.target.className?.includes('ant-select')
                  ) {
                    this.handleInputChange('tracking', !rule.tracking, rule)
                  }
                }}
              >
                <div>
                  <div className='rank-name'>
                    Rank
                    <span>{rule.rank + 1}</span>
                  </div>
                  <div className='selected-check'>
                    {rule.tracking && <Icon type='check-circle' />}
                  </div>
                  <div className='sample-character'>
                    Sample
                    {this.props.guild.kind === 'live' && (
                      <Spin spinning={!this.props.characters}>
                        {(record = this.sampleForRank(rule.rank)) && record && (
                          <a
                            className='roster-name'
                            href={record.armoryLink || '#'}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <Image
                              name={`${record.class.replace(' ', '')}-2`}
                            />
                            <span>{record.name}</span>
                          </a>
                        )}
                        {!this.sampleForRank(rule.rank) &&
                          this.props.characters &&
                          'N/A'}
                      </Spin>
                    )}
                    {this.props.guild.kind != 'live' && (
                      <Spin spinning={!this.props.characters}>
                        {(record = this.sampleForRank(rule.rank)) && record && (
                          <span>
                            <Image
                              name={`${record.class.replace(' ', '')}-2`}
                            />
                            <span>{record.name}</span>
                          </span>
                        )}
                        {!this.sampleForRank(rule.rank) &&
                          this.props.characters &&
                          'N/A'}
                      </Spin>
                    )}
                  </div>
                </div>

                <div className='custom-rank'>
                  <span>Add as</span>
                  <Select
                    placeholder='default'
                    defaultValue={
                      rule.team_rank_id === null ? undefined : rule.team_rank_id
                    }
                    allowClear={true}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(value) => {
                      this.handleInputChange('team_rank_id', value, rule)
                      this.handleInputChange('tracking', true, rule)
                    }}
                  >
                    {this.props.guild.selectedTeam.character_ranks.map(
                      (rank) => (
                        <Select.Option key={rank.id} value={rank.id}>
                          {rank.name}
                        </Select.Option>
                      ),
                    )}
                  </Select>
                </div>
              </div>
            ))}
          </div>
          <Button onClick={this.updateTrackingRules}>Track ranks</Button>
        </Spin>
      </div>
    )
  }
}

export default TrackingRulesForm
