import axios from 'axios'

axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true

class RaidService {
  async updateSelection(guildId, teamId, raidId, signups) {
    return await axios.post(
      `/api/guilds/${guildId}/teams/${teamId}/raids/${raidId}/signups`,
      { signups },
    )
  }

  async updateRaid(guildId, teamId, raidId, raid) {
    return await axios.put(
      `/api/guilds/${guildId}/teams/${teamId}/raids/${raidId}`,
      { raid },
    )
  }

  async updateRaidNotes(guildId, teamId, raidId, encounter, notes, global) {
    return await axios.put(
      `/api/guilds/${guildId}/teams/${teamId}/raids/${raidId}/raid_notes`,
      { encounter, global, notes },
    )
  }

  async updatePresence(guildId, teamId, raidId, presence) {
    return await axios.put(
      `/api/guilds/${guildId}/teams/${teamId}/raids/${raidId}/update_presence`,
      { presence },
    )
  }

  async createRaid(guildId, teamId, date, default_signup_status) {
    return await axios.post(`/api/guilds/${guildId}/teams/${teamId}/raids`, {
      date,
      default_signup_status,
    })
  }

  async toggleEncounter(guildId, teamId, raidId, encounter, signups) {
    return await axios.put(
      `/api/guilds/${guildId}/teams/${teamId}/raids/${raidId}/toggle_encounter`,
      { encounter, signups },
    )
  }

  async addGuest(guildId, teamId, raidId, guest) {
    return await axios.post(
      `/api/guilds/${guildId}/teams/${teamId}/raids/${raidId}/add_guest.json`,
      { guest },
    )
  }

  async removeGuest(guildId, teamId, raidId, signup_id) {
    return await axios.delete(
      `/api/guilds/${guildId}/teams/${teamId}/raids/${raidId}/remove_guest.json?signup_id=${signup_id}`,
    )
  }

  async fetchCopyableRaids(guildId, teamId, raidId, instanceId, encounterId) {
    return await axios.get(
      `/api/guilds/${guildId}/teams/${teamId}/raids/${raidId}/copyable_raids?instance_id=${instanceId}${
        encounterId ? `&encounter_id=${encounterId}` : ''
      }`,
    )
  }

  async copySelections(guildId, teamId, raidId, sourceRaidId, encounterId) {
    return await axios.put(
      `/api/guilds/${guildId}/teams/${teamId}/raids/${raidId}/copy_selections?source_raid_id=${sourceRaidId}${
        encounterId ? `&encounter_id=${encounterId}` : ''
      }`,
    )
  }

  async fetchPlanningInfo(guildId, teamId, raidId) {
    return await axios.get(
      `/api/guilds/${guildId}/teams/${teamId}/raids/${raidId}/planning_info`,
    )
  }
}

export default new RaidService()
