/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { useSetting } from '../../../helpers'
import moment from 'moment'

import { Button, Icon, Input, Select, Spin, Tooltip, message } from 'antd'
import Image from '../../../components/Image'

import TeamService from '../../../services/TeamService'
import React from 'react'
import CharacterService from '../../../services/CharacterService'

const difficulties = ['Mythic', 'Heroic', 'Normal', 'LFR']

const UpcomingPage = (props) => {
  const [loadingRaids, setLoadingRaids] = useState(true)
  const [loadingMoreRaids, setLoadingMoreRaids] = useState(false)
  const [loadedAllRaids, setLoadedAllRaids] = useState(false)
  const [upcomingRaids, setUpcomingRaids] = useState([])
  const [selectedCharacter, setSelectedCharacter] = useState()
  const [checkedRaids, setCheckedRaids] = useState([])
  const [presence, setPresence] = useState('present')
  const [comment, setComment] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const availableStatuses = useSetting(props, 'raids_available_statuses')

  const availableCharacters = () => {
    return props.guild.selectedTeam.characters.filter(
      (character) =>
        character.raid_visibility &&
        props.user.character_data
          .map((ch) => ch.key)
          .includes(character.blizzard_key),
    )
  }

  useEffect(() => {
    setLoadingRaids(true)
    setSelectedCharacter(availableCharacters()[0])

    TeamService.fetchUpcomingRaids(
      props.guild.id,
      props.guild.selectedTeam.id,
      10,
    ).then((raids) => {
      setUpcomingRaids(raids)
      setLoadingRaids(false)
    })
  }, [props.guild.selectedTeam])

  useEffect(() => {
    if (loadingMoreRaids) {
      TeamService.fetchUpcomingRaids(
        props.guild.id,
        props.guild.selectedTeam.id,
      ).then((raids) => {
        setUpcomingRaids(raids)
        setLoadingMoreRaids(false)
        setLoadedAllRaids(true)
      })
    }
  }, [loadingMoreRaids])

  useEffect(() => {
    if (submitting) {
      CharacterService.updateRaidStatus(
        props.guild.id,
        props.guild.selectedTeam.id,
        selectedCharacter?.id,
        allowedCheckedRaids().map((raid) => raid.id),
        presence,
        comment,
        loadedAllRaids ? null : 10,
      ).then((raids) => {
        setUpcomingRaids(raids)
        setComment('')
        setCheckedRaids([])
        setSubmitting(false)
        message.success('New status saved.', 3)
      })
    }
  }, [submitting])

  const statusForCharacter = (raid) => {
    const status = raid.signups.find(
      (signup) => signup.character.id === selectedCharacter?.id,
    )?.status

    const capitalized = status
      ? status.charAt(0).toUpperCase() + status.slice(1)
      : 'N/A'

    return <Tooltip title={capitalized}>{signupStatus(status)}</Tooltip>
  }

  const raidSelectionStatusForCharacter = (raid) => {
    const selected = raid.signups.find(
      (signup) => signup.character.id === selectedCharacter?.id,
    )?.selected

    if (selected) {
      return <Icon type='check' />
    } else {
      return <Icon type='close' />
    }
  }

  const encounterSelectionStatusForCharacter = (encounter) => {
    const selected = encounter.selections.find(
      (selection) => selection.character.id === selectedCharacter?.id,
    )?.selected

    if (encounter.selections.length === 0) {
      return '-'
    } else if (selected) {
      return <Icon type='check' />
    } else {
      return <Icon type='close' />
    }
  }

  const signupStatus = (status) => {
    if (status == 'present') {
      return <Icon type='check' />
    } else if (status == 'absent') {
      return <Icon type='close' />
    } else if (status == 'late') {
      return <Icon type='clock-circle' />
    } else if (status == 'tentative') {
      return <Icon type='question' />
    } else if (status == 'standby') {
      return <Icon type='pause-circle' />
    } else if (status == 'missing') {
      return <Icon type='stop' />
    } else {
      return <span className='no-status'>-</span>
    }
  }

  const hasEncounterSelections = (raid) => {
    return (
      raid.instance.encounters.filter(
        (encounter) => encounter.selections.length > 0,
      ).length > 0
    )
  }

  const showDifficulty = () => {
    const difficultyMap = upcomingRaids.map((raid) => raid.difficulty).sort()
    return difficultyMap[0] !== difficultyMap[difficultyMap.length - 1]
  }

  const allowedCheckedRaids = () => {
    return checkedRaids.filter((raid) => raid.status === 'Planned')
  }

  const raidElement = (raid) => {
    return (
      <div
        key={raid.id}
        css={SingleRaidStyles}
        className={`upcoming-raid ${raid.status} ${
          allowedCheckedRaids().includes(raid)
            ? 'checked'
            : allowedCheckedRaids().length > 0
            ? 'unchecked'
            : ''
        }`}
        onClick={() => {
          if (allowedCheckedRaids().includes(raid)) {
            setCheckedRaids(
              checkedRaids.filter((otherRaid) => otherRaid !== raid),
            )
          } else {
            setCheckedRaids(checkedRaids.concat([raid]))
          }
        }}
      >
        <div className='select'>
          {allowedCheckedRaids().includes(raid) && (
            <Image name='selected' className='selected' extension='svg' />
          )}
          {!allowedCheckedRaids().includes(raid) && (
            <Image
              name={`${raid.instance.background}-small`}
              extension='jpeg'
            />
          )}
        </div>

        <div className='date'>
          <div className='day'>{moment(raid.date).format('ddd')}</div>
          <div className='month'>{moment(raid.date).format('MMM D')}</div>
        </div>

        {showDifficulty() && (
          <div className='time'>
            <div>{difficulties[raid.difficulty]}</div>
            <div>{raid.start_time}</div>
          </div>
        )}
        {!showDifficulty() && (
          <div className='time'>
            <div>{raid.start_time}</div>
            <div>{raid.end_time}</div>
          </div>
        )}

        <div className='status'>{statusForCharacter(raid)}</div>

        {raid.status === 'Cancelled' && (
          <div css={EncounterBlockStyles}>This raid has been cancelled</div>
        )}

        {raid.status !== 'Cancelled' && (
          <div css={EncounterBlockStyles}>
            {hasEncounterSelections(raid) &&
              raid.instance.encounters.map((encounter) => (
                <div
                  key={`${raid.id}-${encounter.id}-${encounter.difficulty}`}
                  className={`encounter ${
                    encounter.selections.length === 0 ? 'inactive' : ''
                  }`}
                >
                  <div className='header'>
                    <Tooltip
                      title={
                        <span>
                          {encounter.name} ({encounter.instance_name}
                          {encounter.difficulty !== raid.difficulty
                            ? `, ${difficulties[encounter.difficulty]}`
                            : ''}
                          )
                        </span>
                      }
                    >
                      <div>
                        <Image name={encounter.background} extension='jpg' />
                      </div>
                    </Tooltip>
                  </div>

                  <div>{encounterSelectionStatusForCharacter(encounter)}</div>
                </div>
              ))}

            {!hasEncounterSelections(raid) && (
              <div className='encounter'>
                <div className='header'>
                  <Tooltip title='All encounters'>
                    <div>
                      <Image
                        name={`${raid.instance.background}-small`}
                        extension='jpeg'
                      />
                    </div>
                  </Tooltip>

                  <div>{raidSelectionStatusForCharacter(raid)}</div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className='cta'>
          <a
            href={`${props.guild.selectedTeam.path}/raids/${raid.id}`}
            target='_blank'
            rel='noreferrer'
            onClick={(e) => e.stopPropagation()}
          >
            <Icon type='link' />
          </a>
        </div>
      </div>
    )
  }

  return (
    <div className='upcoming-raid-page' css={UpcomingRaidsStyles}>
      <h1>
        <div className='title-caption'>
          Raids - Team {props.guild.selectedTeam.name}
        </div>
        <Icon type='contacts' />
        Upcoming
      </h1>

      {availableCharacters().length > 0 && (
        <React.Fragment>
          <div className='edit-bar'>
            <div className='field static'>
              <label>Character</label>
              <Select
                placeholder='Character'
                value={selectedCharacter?.id}
                onChange={(value) =>
                  setSelectedCharacter(
                    availableCharacters().find((ch) => ch.id === value),
                  )
                }
              >
                {availableCharacters().map((character) => (
                  <Select.Option key={character.id} value={character.id}>
                    {character.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            {allowedCheckedRaids().length === 0 && (
              <div className='field message'>
                <label />
                <div>
                  Click on upcoming raids to change your signup status in bulk.
                </div>
              </div>
            )}

            {allowedCheckedRaids().length > 0 && (
              <React.Fragment>
                <div className='field static'>
                  <label>Presence</label>
                  <Select
                    placeholder='Presence'
                    optionFilterProp='children'
                    value={presence}
                    onChange={(value) => setPresence(value)}
                  >
                    {availableStatuses.map((status, index) => (
                      <Select.Option key={index} value={status}>
                        {signupStatus(status)} <span>{status}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className='field grow'>
                  <label>Comment</label>
                  <Input
                    placeholder='Comment'
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                  />
                </div>

                <div className='field'>
                  <label />
                  <Button
                    loading={submitting}
                    onClick={() => setSubmitting(true)}
                  >
                    Save
                  </Button>
                </div>
              </React.Fragment>
            )}
          </div>

          <Spin spinning={loadingRaids} size='large' />

          {!loadingRaids && upcomingRaids.length === 0 && (
            <div className='empty-message'>
              You do not have any upcoming raids with the selected character.
            </div>
          )}

          <div css={RaidListStyles}>
            {upcomingRaids.length > 0 && (
              <div css={MultiSelectStyles}>
                {checkedRaids.length !== upcomingRaids.length && (
                  <a onClick={() => setCheckedRaids(upcomingRaids)}>
                    select all
                  </a>
                )}
                {checkedRaids.length === upcomingRaids.length && (
                  <a onClick={() => setCheckedRaids([])}>deselect all</a>
                )}
              </div>
            )}

            {!loadingRaids &&
              upcomingRaids.map((raid) => {
                if (raid.status === 'Planned') {
                  return raidElement(raid)
                } else {
                  return (
                    <Tooltip
                      key={raid.id}
                      title={`This raid is ${raid.status.toLowerCase()}`}
                    >
                      {raidElement(raid)}
                    </Tooltip>
                  )
                }
              })}
          </div>

          {!loadedAllRaids && !loadingRaids && upcomingRaids.length > 0 && (
            <Button
              loading={loadingMoreRaids}
              onClick={() => setLoadingMoreRaids(true)}
            >
              Load more raids
            </Button>
          )}
        </React.Fragment>
      )}

      {availableCharacters().length === 0 && (
        <span className='empty-message'>
          You don&apos;t own any characters on this team&apos;s roster
        </span>
      )}
    </div>
  )
}

export default UpcomingPage

const UpcomingRaidsStyles = css`
  max-width: 80rem;

  .ant-spin {
    margin-top: 4rem;
    width: 100%;
    text-align: center;
  }

  .edit-bar {
    justify-content: space-between;
    align-items: center;

    .message {
      max-width: unset;
      flex-grow: 1;
      opacity: 0.8;
    }
  }

  .empty-message {
    margin-top: 4rem;
  }
`

const RaidListStyles = css`
  display: flex;
  flex-direction: column;
`

const MultiSelectStyles = css`
  width: 100%;
  padding: 2rem 0;
  text-align: right;
  user-select: none;
`

const SingleRaidStyles = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: -1rem 0 2rem;
  padding: 0 2.5rem 1.5rem;
  border-radius: 0.5rem;
  opacity: 1;
  transition: 0.3s opacity ease-in-out;
  &.unchecked {
    opacity: 0.5;
  }
  &.Cancelled,
  &.Locked {
    cursor: not-allowed;
    user-select: none;
  }

  > div {
    margin-top: 1.5rem;
  }

  > div:not(:last-of-type) {
    margin-right: 2rem;
    &.status {
      margin-right: 3rem;
    }
  }

  img {
    display: inline-block;
    min-width: 4.5rem;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    object-fit: cover;
    &.selected {
      padding: 1.2rem;
      opacity: 0.8;
    }
  }

  .date {
    width: 5rem;
    text-align: center;
    line-height: 2.2rem;

    .month {
      white-space: nowrap;
    }
  }

  .time {
    font-size: 1.2rem;
    line-height: 1.9rem;
    opacity: 0.8;
    text-align: center;
  }

  .status {
    font-size: 2.5rem;
  }

  .cta {
    font-size: 2rem;
  }
`

const EncounterBlockStyles = css`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;

  .encounter {
    margin-right: 1rem;
    text-align: center;
    &.inactive {
      filter: grayscale(1);
      opacity: 0.4;
    }

    img {
      min-width: 3rem;
      width: 3rem;
      height: 3rem;
    }
  }
`
