import React from 'react'
import EncounterRow from './EncounterRow'

import Image from '../Image'
import AddEncounterModal from './AddEncounterModal'

const EncounterSelection = (props) => {
  return (
    <div className='encounter-selection'>
      {(!props.hasBossSelections() || props.user.accessLevel == 0) && (
        <div>
          <div
            key='all'
            onClick={() =>
              props.hasBossSelections()
                ? null
                : props.changeEncounterScope(null)
            }
            className={`${props.hasBossSelections() ? 'disabled' : 'active'} ${
              props.selectedEncounter ? '' : 'selected'
            }`}
          >
            {props.guild.kind === 'classic_progression' ? (
              <img src={props.raid.instance.tile_url} />
            ) : (
              <Image
                name={`portrait-${props.raid.instance.background}`}
                extension='jpg'
              />
            )}

            <span className='boss-name'>All encounters</span>
          </div>
        </div>
      )}
      {props.raid.instance.encounters.length > 1
        ? props.encounterOrder.map((encounterOrderId, _) => {
            const encounter = props.raid.instance.encounters.find(
              (e) => e.order_id === encounterOrderId,
            )
            return (
              <React.Fragment key={encounterOrderId}>
                {(props.hasBossSelections(encounter) ||
                  props.user.accessLevel == 0) && (
                  <EncounterRow encounter={encounter} {...props} />
                )}
              </React.Fragment>
            )
          })
        : null}

      {props.user.accessLevel === 0 && <AddEncounterModal {...props} />}
    </div>
  )
}

export default EncounterSelection
