import React, { useState } from 'react'

import { Button, Icon, Table, Modal } from 'antd'
import Image from './Image'
import bnetLogo from '../../../../assets/images/bnet-logo-white.svg'
import TroubleshootingContent from './TroubleshootingContent'

const Logo = () => <img src={bnetLogo} />

const ProfileInfo = (props) => {
  const [refreshing, setRefreshing] = useState(false)
  const [helpModalShown, setHelpModalShown] = useState(false)
  const [sortingBy, setSortingBy] = useState({
    columnKey: 'name',
    order: 'asc',
  })

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sortOrder: sortingBy.columnKey === 'name' && sortingBy.order,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, record) => {
        if (props.kind == 'live') {
          return (
            <a
              className='roster-name'
              href={record.armory_link}
              target='_blank'
              rel='noreferrer'
            >
              <Image name={`${record.class_name.replace(' ', '')}-2`} />
              <span>{record.name}</span>
            </a>
          )
        } else {
          return (
            <span>
              <Image name={`${record.class_name.replace(' ', '')}-2`} />
              <span>{record.name}</span>
            </span>
          )
        }
      },
    },
    {
      title: 'Realm',
      dataIndex: 'realm_name',
      key: 'realm_name',
      sortOrder: sortingBy.columnKey === 'realm_name' && sortingBy.order,
      sorter: (a, b) => a.realm_name.localeCompare(b.realm_name),
    },
  ]

  return (
    <div className='profile-info'>
      <section>
        <h3>Linked accounts</h3>
        <p>
          {props.user.discord_tag
            ? 'Your Discord account is linked! To link it to a different '
            : 'To link your '}
          Discord account, perform the <code>/link_account</code>
          command from a server where the wowaudit bot is installed.
        </p>
        <div className='linked-account'>
          <span>Discord</span>
          {props.user.discord_tag ? (
            <div>
              <span>{props.user.discord_tag}</span>
              <Icon type='check-circle' />
            </div>
          ) : (
            <div>
              <Icon type='close-circle' />
            </div>
          )}
        </div>
      </section>

      <section>
        <h3>
          Account characters{props.region ? ` (${props.region})` : ''}{' '}
          <Button
            loading={refreshing}
            onClick={() => setRefreshing(true)}
            href={`/auth/bnet?origin=${encodeURIComponent(
              props.refreshUrl,
            )}&region=${props.region || 'eu'}`}
          >
            <Icon component={Logo} />
            Refresh
          </Button>
        </h3>
        <p>
          In order to interact with your team you need to have your account
          characters listed in this table.
        </p>
        {props.user.character_data.length ? (
          <p>
            If there are characters missing, you can use the refresh button to
            update the list.
          </p>
        ) : (
          <p>
            It looks like wowaudit doesn&apos;t have access to your characters.
            If refreshing doesn&apos;t work, please refer to the{' '}
            <a onClick={() => setHelpModalShown(true)}>
              troubleshooting instructions
            </a>
            .
          </p>
        )}

        <Modal
          title={<span>Troubleshooting</span>}
          className='troubleshooting-modal'
          visible={helpModalShown}
          width='90%'
          onOk={() => setHelpModalShown(false)}
          footer={[
            <Button key='back' onClick={() => setHelpModalShown(false)}>
              OK
            </Button>,
          ]}
          onCancel={() => setHelpModalShown(false)}
        >
          <TroubleshootingContent inModal={true} />
        </Modal>

        <Table
          id='character-table'
          size='small'
          pagination={false}
          scroll={{ x: 'max-content' }}
          columns={columns}
          sortingBy
          onChange={(_, filters, sorter) => setSortingBy(sorter)}
          dataSource={props.user.character_data}
        />
      </section>
    </div>
  )
}

export default ProfileInfo
