import React from 'react'
import { Button, Icon } from 'antd'

import LandingPageContainer from '../components/LandingPageContainer'
import Image from '../components/Image'

const Desktop = () => {
  const macUrl = document
    .getElementById('subdomain')
    ?.getAttribute('data-client-mac-installer-url')

  const windowsUrl = document
    .getElementById('subdomain')
    ?.getAttribute('data-client-windows-installer-url')

  const userAgent = navigator.userAgent.toLowerCase()

  return (
    <LandingPageContainer>
      <h1 className='logo'>
        <Image name='new-logo-icon' className='icon-only' extension='svg' />
        Desktop client
      </h1>
      <p>
        The wowaudit desktop client allows you to automatically synchronise
        wishlist data to RCLootCouncil and synchronise the RCLootCouncil loot
        history back to wowaudit. More functionality is coming soon, including
        synchronising calendar events to the in-game calendar.
      </p>

      <h2>Installation</h2>
      <p>
        Download the client for your operating system below. Once downloaded,
        simply run the installer! Updates can be downloaded from within the
        client, and the client can be configured to start up automatically when
        you boot your computer (it&apos;s very lightweight and should not be
        noticeable at all).
      </p>

      <Button href={userAgent.includes('mac') ? macUrl : windowsUrl}>
        <Icon type={userAgent.includes('mac') ? 'apple' : 'windows'} />
        Download {userAgent.includes('mac') ? '(macOS)' : '(Windows)'}
      </Button>
      <span className='alt-download'>
        or download the{' '}
        <a href={userAgent.includes('mac') ? windowsUrl : macUrl}>
          {userAgent.includes('mac') ? 'Windows' : 'macOS'}
        </a>{' '}
        installer
      </span>

      <h2>Usage</h2>
      <p>
        In order to use the client you need to have admin access to your team.
        Visit the API page on the website and paste the API key into the client.
      </p>
      <p>
        Then, install the RCLootCouncil plugin for wowaudit at{' '}
        <a
          href='https://www.curseforge.com/wow/addons/rclootcouncil-wowaudit'
          target='_blank'
          rel='noreferrer'
        >
          CurseForge
        </a>{' '}
        or{' '}
        <a
          href='https://addons.wago.io/addons/rclootcouncil-wowaudit'
          target='_blank'
          rel='noreferrer'
        >
          Wago
        </a>
        . Since it is a plugin, you also need the main RCLootCouncil addon.
        After that, you need to point the client to the path where the plugin
        addon is installed. Once the configuration is active, everything else
        will happen automatically. Your data will be synchronised every 15
        minutes, but you can also sync manually whenever you like. Remember that
        you need to <code>/reload</code> your game after data has changed,
        otherwise it will not be reflected in the game.
      </p>
      <p>
        Only the master looter of your raid team has to run the client, the
        wishlist data will be shared with all other players using the
        RCLootCouncil - wowaudit plugin automatically. It can still be useful to
        have multiple players in the raid running the client, since the loot
        history of one player will likely not cover everything (unless that
        player is always in the raid).
      </p>

      <p>
        If you need any more help, please reach out on{' '}
        <a href='https://discord.gg/86eUAFz'>Discord</a>.
      </p>
    </LandingPageContainer>
  )
}

export default Desktop
