import React, { useState } from 'react'
import { DndContext } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { isEqual, sortBy } from 'lodash'

import EntityDroppable from './raid/EntityDroppable'
import LootHistorySingleResponseType from './LootHistorySingleResponseType'
import { useFormikContext } from 'formik'

const LootHistoryResponseTypes = () => {
  const { values, setFieldValue } = useFormikContext()
  const [dragging, setDragging] = useState(false)

  const handleDragEnd = (event) => {
    const newTypeOrder = [...values.loot_history_response_type_order]

    if (event.over?.id) {
      const movedType = newTypeOrder.indexOf(event.active.id)
      const movedTo = newTypeOrder.indexOf(
        parseInt(event.over.id.split('-')[1]),
      )

      if (movedTo !== movedType) {
        newTypeOrder.splice(movedType, 1)
        newTypeOrder.splice(
          movedTo +
            (movedType < movedTo ? -1 : 0) +
            (event.over.id.split('-')[0] == 'before' ? 0 : 1),
          0,
          event.active.id,
        )

        if (!isEqual(newTypeOrder, values.loot_history_response_type_order)) {
          setFieldValue('loot_history_response_type_order', newTypeOrder)
        }
      }
    }

    setDragging(false)
  }

  const handleDragStart = () => {
    setDragging(true)
  }

  const topLevelTypes = values.loot_history_response_types.filter(
    (type) => !type.synonym_of_id,
  )

  return (
    <div
      className={`draggable-container ${dragging ? 'dragging' : ''}`}
      style={{ position: 'relative' }}
    >
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToWindowEdges]}
      >
        {sortBy(
          values.loot_history_response_types.filter((t) => !t.excluded),
          (type) => values.loot_history_response_type_order.indexOf(type.id),
        ).map((responseType) => (
          <div key={responseType.id}>
            <EntityDroppable orientation='before' id={responseType.id} />

            <LootHistorySingleResponseType
              responseType={responseType}
              typeOptions={topLevelTypes}
            />

            <EntityDroppable orientation='after' id={responseType.id} />
          </div>
        ))}
      </DndContext>
    </div>
  )
}

export default LootHistoryResponseTypes
