import React from 'react'
import { Link } from 'react-router-dom'

import Image from '../../../components/Image'
import { Icon, Button } from 'antd'
import discordLogo from '../../../../../../assets/images/discord-logo-white.svg'
const DiscordLogo = () => <img src={discordLogo} />

class DiscordBotPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='discord-bot-page'>
        <h1>
          <div className='title-caption'>Raids</div>
          <span>
            <Icon component={DiscordLogo} />
            Discord Bot
          </span>
        </h1>

        <div className='reading-width'>
          <p>
            You can add the wowaudit Discord bot to your server to manage and
            share upcoming raids with your team. Users can change their signup
            status and view raid details directly from Discord. To add the bot,
            simply click the button below. Once added, you can type{' '}
            <strong>/help</strong> in your server to get started.
          </p>

          <Button
            block
            type='primary'
            href='https://wowaudit.com/get_discord_bot'
            target='_blank'
            rel='noreferrer'
          >
            Add Discord bot to server
          </Button>

          <p>
            Before you start, you should run the <strong>/setup</strong>{' '}
            command. This will link your Discord server to your wowaudit guild.
            You have to enable and use the API key that is shown on the{' '}
            <Link to={`${this.props.guild.selectedTeam.path}/api`}>
              {' '}
              API page
            </Link>
            . You can use the <em>restricted_to_channel</em> option to set up
            the bot only in a specific channel, allowing you to have the bot
            work for multiple teams in one server. Once you have linked your
            server, these are the most important commands:
          </p>

          <ul>
            <li>
              <strong>/subscribe</strong>: Configure a channel to automatically
              have raids posted a number of days in advance. You can also let
              the bot mention a role when new raids are posted. Raid messages
              will be automatically deleted once they are in the past. Raids are
              posted 5 minutes past the end time hour of the raid, the amount of
              days in advance that you configured. For example, when a raid ends
              at 22:00 the bot will post it at 22:05 by default 7 days in
              advance. You can use the <em>day_of_the_week</em> option to only
              post raids that happen on a specific day of the week.
            </li>

            <li>
              <strong>/raid</strong>: Post a single raid event from your
              wowaudit calendar in this channel. Through this message users (and
              guests, if enabled) can change their signup status, set a comment,
              and view most information related to the raid. Players who
              aren&apos;t selected on any encounter will be shown separately as{' '}
              <em>queued</em>. It looks like this:
              <figure>
                <Image name='discord-bot-raid-v2' />
              </figure>
              In addition to being able to view personal selections with the
              button, the thumbnail of the post is an image that shows the
              up-to-date selections of all players for each boss. It looks like
              this:
              <figure>
                <Image name='discord-bot-selections' />
              </figure>
              You can enable reminders to be sent to users who haven&apos;t
              signed up yet, in the{' '}
              <Link to={`${this.props.guild.selectedTeam.path}/settings`}>
                settings
              </Link>{' '}
              page. You can also configure options there to allow guests to sign
              up.
            </li>

            <li>
              <strong>/create_raid</strong>: Create a single raid event on a
              specific date. You must specify the date, start time, end time,
              and instance. Requires you to have admin access on wowaudit.
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default DiscordBotPage
