import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { Icon, Tooltip } from 'antd'
import { RgbaStringColorPicker } from 'react-colorful'

import { useClickOutside } from '../helpers'
import { Select } from 'formik-antd'
import { useFormikContext } from 'formik'

const LootHistorySingleResponseType = ({
  responseType,
  staticType = false,
  typeOptions,
}) => {
  const { setFieldValue, values } = useFormikContext()
  const colorPicker = useRef()
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [selectedColor, setSelectedColor] = useState(responseType.rgba)
  const close = useCallback(() => setShowColorPicker(false), [])
  useClickOutside(colorPicker, close)

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: responseType.id,
  })

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined

  useEffect(() => {
    if (!showColorPicker && selectedColor !== responseType.rgba) {
      setFieldValue(
        `loot_history_response_types.${responseType.index}.rgba`,
        selectedColor,
      )
    }
  }, [showColorPicker])

  const disabled = values.loot_history_response_types.some(
    (type) => type.synonym_of_id === responseType.id,
  )

  return (
    <div
      className={`response-type draggable-item ${transform ? 'dragging' : ''} ${
        responseType.synonym_of_id ? 'merged' : ''
      }`}
      ref={setNodeRef}
      style={style}
      {...(staticType ? {} : attributes)}
    >
      {!staticType && <Icon type='more' {...listeners} />}
      <div
        className='legend-color'
        style={{ backgroundColor: responseType.rgba }}
        onClick={() => setShowColorPicker(true)}
      >
        {showColorPicker && (
          <div className='popover' ref={colorPicker}>
            <RgbaStringColorPicker
              color={responseType.rgba}
              onChange={(value) => setSelectedColor(value)}
            />
          </div>
        )}
      </div>
      <span>{responseType.name}</span>
      {disabled && (
        <Tooltip title='Another type is already merged with this one, so this cannot be merged with something else'>
          <Icon type='info-circle' />
        </Tooltip>
      )}
      <Select
        size='small'
        disabled={disabled}
        name={`loot_history_response_types.${responseType.index}.synonym_of_id`}
        optionFilterProp='children'
        allowClear={true}
      >
        {typeOptions
          .filter((type) => type.id !== responseType.id)
          .map((option, index) => (
            <Select.Option key={index} value={option.id}>
              <span className={option.excluded ? 'excluded' : ''}>
                {option.name}
              </span>
            </Select.Option>
          ))}
      </Select>
    </div>
  )
}

export default LootHistorySingleResponseType
