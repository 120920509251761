import React, { useEffect, useState } from 'react'
import { Button, message, Modal, Select } from 'antd'

import RaidService from '../../services/RaidService'
import moment from 'moment'

const CopyRaidPlanningModal = ({
  open,
  guildId,
  teamId,
  encounter,
  raid,
  onComplete,
}) => {
  const [loading, setLoading] = useState(true)
  const [copying, setCopying] = useState(false)
  const [raids, setRaids] = useState([])
  const [selectedRaid, setSelectedRaid] = useState()

  useEffect(() => {
    if (open) {
      setLoading(true)
      setRaids([])
      setSelectedRaid(undefined)

      RaidService.fetchCopyableRaids(
        guildId,
        teamId,
        raid.id,
        raid.instance.id,
        encounter?.id,
      ).then((result) => {
        setRaids(result.data.raids)
        setLoading(false)
        setSelectedRaid(result.data.raids[0])
      })
    }
  }, [open])

  useEffect(() => {
    if (copying) {
      RaidService.copySelections(
        guildId,
        teamId,
        raid.id,
        selectedRaid?.id,
        encounter?.id,
      )
        .then((result) => {
          message.success(
            `Raid selection copied from the raid on ${moment(
              selectedRaid.date,
            ).format('MMMM D')}.`,
            5,
          )
          setCopying(false)
          onComplete(result)
        })
        .catch(() => {
          message.error('Failed to copy raid selection. Please try again.', 5)
          setCopying(false)
        })
    }
  }, [copying])

  return (
    <Modal
      visible={open}
      title={`Import selections from past raid`}
      onOk={() => setCopying(true)}
      onCancel={() => onComplete()}
      width='40rem'
      footer={[
        <Button key='back' disabled={copying} onClick={() => onComplete()}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          disabled={copying || loading || !selectedRaid}
          loading={copying}
          onClick={() => setCopying(true)}
        >
          Copy selections
        </Button>,
      ]}
    >
      <div className='copy-planning-modal'>
        <p>
          Select another raid here to import its selections of{' '}
          {encounter ? encounter.name : 'all encounters'}.
        </p>

        <Select
          placeholder='Select'
          value={selectedRaid?.id}
          optionLabelProp='label'
          loading={loading}
          onChange={(value) => {
            setSelectedRaid(raids.find((raid) => raid.id === value))
          }}
        >
          {raids.map((raid) => {
            const date = moment(raid.date)
            return (
              <Select.Option
                key={raid.id}
                value={raid.id}
                className='lower flexed'
                label={
                  <div className='flexed'>
                    <span>{date.format('dddd')}</span>
                    <span>{date.format('MMMM D, YYYY')}</span>
                  </div>
                }
              >
                <span>{date.format('dddd')}</span>
                <span>{date.format('MMMM D, YYYY')}</span>
              </Select.Option>
            )
          })}
        </Select>
      </div>
    </Modal>
  )
}

export default CopyRaidPlanningModal
