import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'

import en_GB from 'antd/lib/locale-provider/en_GB'
import moment from 'moment'
import 'moment/locale/en-gb'

moment.locale('en-gb')

import {
  Icon,
  Calendar,
  ConfigProvider,
  Spin,
  Modal,
  Select,
  Button,
  Alert,
  DatePicker,
  message,
} from 'antd'

import TeamService from '../../../services/TeamService'
import RaidService from '../../../services/RaidService'

class RaidCalendar extends React.Component {
  constructor(props) {
    super(props)

    const routerState = props.location.state || {}

    this.state = {
      loading: true,
      selectedRaid: null,
      createRaidModalOpen: false,
      modalLoading: false,
      modalError: false,
      selectedDate: moment(routerState.selectedDate || new Date()),
      currentTeam: this.props.guild.selectedTeam.id,
      newRaidStatus: this.props.guild.selectedTeam.default_signup_status,
      raids: [],
    }

    // Clear remember state from previous visit
    // Don't use forwarded calendar state when refreshing
    window.history.replaceState(null, null)
  }

  componentDidMount() {
    this.refresh()
  }

  componentDidUpdate() {
    // TODO: Refactor this, improve state management.
    if (this.state.currentTeam != this.props.guild.selectedTeam.id) {
      this.setState({
        loading: true,
        currentTeam: this.props.guild.selectedTeam.id,
      })
      this.refresh()
    }
  }

  refresh = () => {
    TeamService.fetchRaids(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      this.state.selectedDate.format('YYYY-MM-DD'),
    ).then((raids) => {
      this.setState({
        loading: false,
        raids: raids.data,
      })
    })
  }

  findRaids = (date, raids = this.state.raids) => {
    return raids.filter((raid, _) => raid.date == date.format('YYYY-MM-DD'))
  }

  goToRaid = (date) => {
    const raid = this.state.raids.find((r) =>
      moment(r.date).isSame(date, 'day'),
    )
    if (raid && !this.state.creatingRaid) {
      this.setState({ selectedRaid: raid })
    } else if (!this.state.creatingRaid && this.props.user.accessLevel == 0) {
      this.createRaid(date)
    }
  }

  createRaid = (date, status = this.state.newRaidStatus) => {
    this.setState({ creatingRaid: date })
    RaidService.createRaid(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      date.format('YYYY-MM-DD'),
      status,
    )
      .then((response) => {
        this.setState({
          selectedRaid: response.data,
          createRaidModalOpen: false,
        })
      })
      .catch((result) => {
        if (result.response.status == 401) window.location.reload()
        this.setState({
          creatingRaid: false,
          modalError: result.response.data.error,
        })
        message.error(`Couldn't create raid. Please try again.`, 3)
      })
  }

  signupStatus = (status) => {
    if (status == 'present') {
      return <Icon type='check' />
    } else if (status == 'absent') {
      return <Icon type='close' />
    } else if (status == 'standby') {
      return <Icon type='pause-circle' />
    } else if (status == 'missing') {
      return <Icon type='stop' />
    } else if (status == 'late') {
      return <Icon type='clock-circle' />
    } else {
      return <Icon type='question' />
    }
  }

  raidsForDate = (value) => {
    const raids = this.findRaids(value)
    if (raids.length > 0) {
      return raids.map((raid) => (
        <div
          key={raid.id}
          className={`raid ${raid.status} ${
            raids.length > 1 ? 'has-multiple' : ''
          }`}
          title=''
          onClick={(event) => {
            event.stopPropagation()
            this.setState({ selectedRaid: raid })
          }}
        >
          <span className='raid-background' title=''>
            <div
              title=''
              style={{
                backgroundImage:
                  this.props.guild.kind === 'classic_progression' ||
                  this.props.guild.subkind === 'season_of_discovery'
                    ? `url(${raid.instance.tile_url})`
                    : `url(https://data.wowaudit.com/img/${raid.instance.background}-small.jpeg)`,
              }}
            />
          </span>
          <span className='raid-name'>
            {raids.length > 1 ? 'Multiple' : raid.instance.name}
          </span>

          <div className='times'>
            <span>{raid.start_time}</span>
            <span>{raid.end_time}</span>
          </div>

          {raid.userPresence && this.signupStatus(raid.userPresence)}

          <div className='signups'>
            {raid.signed}
            <span className='total'>{` / ${raid.total}`}</span>
          </div>
        </div>
      ))
    } else if (this.props.user.accessLevel == 0) {
      const creating =
        this.state.creatingRaid && this.state.creatingRaid.isSame(value, 'day')
          ? true
          : false
      return (
        <div
          className={`create-new-raid ${creating ? 'creating-raid' : ''}`}
          title=''
        >
          <Spin spinning={creating} tip='creating...'>
            <a>create</a>
          </Spin>
        </div>
      )
    }
  }

  closeModals = () => {
    this.setState({ createRaidModalOpen: false, modalLoading: false })
  }

  render() {
    if (this.state.selectedRaid) {
      return (
        <Redirect
          push
          to={{
            pathname: `${this.props.guild.selectedTeam.path}/raids/${this.state.selectedRaid.id}`,
            state: {
              selectedDate: this.state.selectedDate.format('YYYY-MM-DD'),
            },
          }}
        />
      )
    } else {
      return (
        <div className='calendar-page'>
          <h1>
            <div className='title-caption'>
              Raids - Team {this.props.guild.selectedTeam.name}
            </div>
            <Icon type='calendar' />
            Calendar
            {this.props.user.accessLevel == 0 && (
              <Button
                onClick={() => this.setState({ createRaidModalOpen: true })}
              >
                Create raid
              </Button>
            )}
          </h1>

          <Modal
            visible={this.state.createRaidModalOpen}
            title='Create new raid'
            onOk={() =>
              this.state.newRaidDate
                ? this.createRaid(
                    this.state.newRaidDate,
                    this.state.newRaidStatus,
                  )
                : null
            }
            onCancel={this.closeModals}
            footer={[
              <Button key='back' onClick={this.closeModals}>
                Cancel
              </Button>,
              <Button
                key='submit'
                type='primary'
                disabled={!this.state.newRaidDate}
                loading={!!this.state.creatingRaid}
                onClick={() =>
                  this.createRaid(
                    this.state.newRaidDate,
                    this.state.newRaidStatus,
                  )
                }
              >
                Create raid
              </Button>,
            ]}
          >
            {this.state.modalError && (
              <Alert message={this.state.modalError} type='error' showIcon />
            )}

            <div className='create-raid-modal-field'>
              <label>Date</label>
              <DatePicker
                placeholder='Date'
                onChange={(value) => this.setState({ newRaidDate: value })}
              />
            </div>

            <div className='create-raid-modal-field'>
              <label>Default signup status</label>
              <Select
                placeholder='Status'
                defaultValue={
                  this.props.guild.selectedTeam.default_signup_status
                }
                optionFilterProp='children'
                onChange={(value) => this.setState({ newRaidStatus: value })}
              >
                {[
                  'present',
                  'absent',
                  'tentative',
                  'late',
                  'standby',
                  'unknown',
                ].map((status, index) => (
                  <Select.Option key={index} value={status}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Modal>

          <Spin spinning={this.state.loading}>
            <ConfigProvider locale={en_GB}>
              <Calendar
                mode='month'
                onSelect={this.goToRaid}
                value={this.state.selectedDate}
                dateCellRender={this.raidsForDate}
                headerRender={({ value, _1, onChange, _2 }) => {
                  const prevDate = value.clone().subtract(1, 'months')
                  const nextDate = value.clone().add(1, 'months')
                  return (
                    <div className='calendar-header'>
                      <span
                        className='left'
                        onClick={() => {
                          this.setState(
                            {
                              selectedDate: prevDate,
                              loading: true,
                              raids: [],
                            },
                            () => {
                              this.refresh()
                            },
                          )
                          onChange(prevDate)
                        }}
                      >
                        <Icon type='left' />
                        {prevDate.format('MMMM')}
                      </span>

                      <div className='calendar-range-picker'>
                        <Select
                          optionFilterProp='children'
                          className='month'
                          onChange={(value) =>
                            this.setState(
                              {
                                selectedDate: this.state.selectedDate.set(
                                  'month',
                                  value,
                                ),
                                loading: true,
                                raids: [],
                              },
                              () => this.refresh(),
                            )
                          }
                          value={value.format('MMMM')}
                        >
                          {[
                            'January',
                            'February',
                            'March',
                            'April',
                            'May',
                            'June',
                            'July',
                            'August',
                            'September',
                            'October',
                            'November',
                            'December',
                          ].map((status, index) => (
                            <Select.Option key={index} value={index}>
                              {status}
                            </Select.Option>
                          ))}
                        </Select>

                        <Select
                          optionFilterProp='children'
                          className='year'
                          onChange={(value) =>
                            this.setState(
                              {
                                selectedDate: this.state.selectedDate.set(
                                  'year',
                                  value,
                                ),
                                loading: true,
                                raids: [],
                              },
                              () => this.refresh(),
                            )
                          }
                          value={value.format('YYYY')}
                        >
                          {Array.from(Array(12).keys()).map((index) => (
                            <Select.Option
                              key={index}
                              value={moment().year() + 1 - index}
                            >
                              {moment().year() + 1 - index}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>

                      <span
                        className='right'
                        onClick={() => {
                          this.setState(
                            {
                              selectedDate: nextDate,
                              loading: true,
                              raids: [],
                            },
                            () => {
                              this.refresh()
                            },
                          )
                          onChange(nextDate)
                        }}
                      >
                        {nextDate.format('MMMM')}
                        <Icon type='right' />
                      </span>
                    </div>
                  )
                }}
              />
            </ConfigProvider>
          </Spin>
        </div>
      )
    }
  }
}

export default withRouter(RaidCalendar)
