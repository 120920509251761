import React from 'react'

import { Icon, Layout } from 'antd'
const { Content } = Layout

import HomepageFooter from './HomepageFooter'

import bnetLogo from '../../../../assets/images/bnet-logo-white.svg'
const Logo = () => <img src={bnetLogo} />

const LandingPageContainer = (props) => {
  const kind = document.getElementById('subdomain')?.getAttribute('data-kind')
  const session = JSON.parse(
    document.getElementById('session')?.getAttribute('data'),
  ).session

  return (
    <Layout className={`landing-page ${kind}`}>
      <div />

      {props.withoutSession ? null : (
        <React.Fragment>
          {window.location.pathname === '/profile' ? (
            <a href='/' className='session-button'>
              Back to home
            </a>
          ) : session.loggedIn && session.battletag ? (
            <a
              href='/profile'
              className={`session-button ${
                session.loggedIn ? 'logged-in' : ''
              }`}
            >
              <Icon type='user' />
              {session.battletag}
            </a>
          ) : (
            <a
              href={`/auth/bnet?origin=${encodeURIComponent('/profile')}`}
              className={`session-button ${
                session.loggedIn ? 'logged-in' : ''
              }`}
            >
              <Icon component={Logo} />
              <span>Log in</span>
            </a>
          )}
        </React.Fragment>
      )}

      <Content className={props.className}>{props.children}</Content>
      <HomepageFooter />
    </Layout>
  )
}

export default LandingPageContainer
