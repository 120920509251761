import React from 'react'
import Image from '../components/Image'

import LandingPageContainer from '../components/LandingPageContainer'

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <LandingPageContainer>
        <h1 className='logo'>
          <Image name='new-logo-icon' className='icon-only' extension='svg' />
          Privacy Policy
        </h1>
        <p>
          This policy applies to all information collected or submitted on the
          wowaudit website
        </p>
        <h2>In short</h2>
        <p>
          wowaudit collects as little information as possible, uses that
          information to provide the services of the site, and only shares that
          information with third parties that assist in performing those
          services (e.g. sending messages to Discord webhooks that you request).
          We do not sell your information to third parties for commercial
          purposes (e.g. selling lists of email addresses).
        </p>
        <h2>Information collected</h2>
        <p>
          The wowaudit website does not allow the creation of accounts. You can
          sign in with either your Google account, Patreon account, or your
          Battle.net account. In those cases, only the information required to
          keep track of your session and access is stored. To be precise, the
          UUID provided by either Google, Patreon or Blizzard.
        </p>
        <p>
          Furthermore, we collect data from Blizzard. We obtain information
          about you from video game publisher Blizzard Entertainment
          (“Blizzard”) as part of being an authenticated developer with
          Blizzard, including information relating to your characters, gameplay,
          and achievements. We obtain the information through the Blizzard
          Developer API made available to us and other Blizzard sources, and
          process the information as a service provider for Blizzard, including
          to benefit the Blizzard player community through our own services. In
          particular, we compile the information we obtain into our own
          databases, and make the information available to the Blizzard player
          community, both on the website and on the spreadsheet. If you use your
          Blizzard account to log in to the wowaudit website, we may collect and
          process additional information, which may be used in accordance with
          this privacy policy. Blizzard, and not us, determines what information
          is shared with us, and if you wish to not have your information shared
          by Blizzard or used by us in this way, please contact Blizzard
          directly and restrict data sharing in accordance with Blizzard’s
          instructions. We are not responsible for Blizzard’s data practices,
          and you should carefully review Blizzard’s policies and account
          settings.
        </p>
        <h2>Security</h2>
        <p>
          wowaudit uses a variety of techniques and technologies to take
          reasonable steps to keep your information secure. All website access
          must occur using HTTPS, passwords are hashed using industry standard
          methods, and encryption is used for much of the data at rest.
        </p>

        <h2>Contact</h2>
        <p>
          If you have any questions regarding this privacy policy, please
          contact us at privacy@wowaudit.com.
        </p>
      </LandingPageContainer>
    )
  }
}

export default PrivacyPolicy
