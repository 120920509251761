import { Button, Icon } from 'antd'
import React, { useState } from 'react'

const CallToAction = (props) => {
  const [closed, setClosed] = useState(
    localStorage.getItem(`cta-${props.uid}`) === 'closed',
  )

  if (closed) {
    return null
  }

  return (
    <div className='cta'>
      <h3>
        {props.title}
        <Icon
          type='close'
          onClick={() => {
            setClosed(true)
            localStorage.setItem(`cta-${props.uid}`, 'closed')
          }}
        />
      </h3>
      <div>{props.children}</div>
      <div className='actions'>
        {props.actions.map((action, i) => (
          <Button
            key={i}
            href={action.href}
            target='_blank'
            rel='noreferrer'
            disabled={!!action.disabled}
          >
            {action.label}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default CallToAction
