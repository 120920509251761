import React from 'react'

import { Icon, Button, message, Switch, Popconfirm, Spin } from 'antd'

import AccessType from '../../../components/AccessType'

import GuildService from '../../../services/GuildService'

class AccessControlPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      linkedAccounts: [],
      adminThreshold: this.props.guild.adminThreshold,
      raiderThreshold: this.props.guild.raiderThreshold,
      basicThreshold: this.props.guild.basicThreshold,
    }
  }

  componentDidMount() {
    GuildService.fetchLinkedAccounts(this.props.guild.id).then((result) =>
      this.setState({
        linkedAccounts: result.data.accounts,
        fetchedAccounts: true,
      }),
    )
  }

  handleSliderChange = (type, value) => {
    if (type == 'admin') {
      this.setState({
        adminThreshold: value,
        raiderThreshold: Math.max(...[value, this.state.raiderThreshold]),
        basicThreshold: Math.max(...[value, this.state.basicThreshold]),
      })
    } else if (type == 'raider') {
      this.setState({
        adminThreshold: Math.min(...[value, this.state.adminThreshold]),
        raiderThreshold: value,
        basicThreshold: Math.max(...[value, this.state.basicThreshold]),
      })
    } else if (type == 'basic') {
      this.setState({
        adminThreshold: Math.min(...[value, this.state.adminThreshold]),
        raiderThreshold: Math.min(...[value, this.state.raiderThreshold]),
        basicThreshold: value,
      })
    } else if (type == 'none') {
      this.setState({
        adminThreshold: Math.min(
          ...[
            Math.max(10 - value, this.props.user.highestRank),
            this.state.adminThreshold,
          ],
        ),
        raiderThreshold: Math.min(
          ...[
            Math.max(10 - value, this.props.user.highestRank),
            this.state.raiderThreshold,
          ],
        ),
        basicThreshold: Math.max(10 - value, this.props.user.highestRank),
      })
    }
  }

  hasChanges = () => {
    return (
      this.state.adminThreshold != this.props.guild.adminThreshold ||
      this.state.raiderThreshold != this.props.guild.raiderThreshold ||
      this.state.basicThreshold != this.props.guild.basicThreshold
    )
  }

  resetSliders = () => {
    this.setState({
      adminThreshold: this.props.guild.adminThreshold,
      raiderThreshold: this.props.guild.raiderThreshold,
      basicThreshold: this.props.guild.basicThreshold,
    })
  }

  saveSliderChanges = () => {
    this.setState({ savingSliderChanges: true })
    GuildService.updateAccess(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      {
        basic_access_threshold: this.state.basicThreshold,
        raider_access_threshold: this.state.raiderThreshold,
        admin_access_threshold: this.state.adminThreshold,
      },
    )
      .then((result) => {
        message.success(
          this.hasChanges() ? 'Changes saved.' : 'Cache reset successfully',
          1,
        )
        this.props.refreshGuild(result.data)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          'Something went wrong saving your changes. Please try again.',
          3,
        )
      })
      .then(() => {
        this.setState({ savingSliderChanges: false })
      })
  }

  toggleAccessControlMethod = () => {
    this.setState({ savingControlMethodChanges: true })
    GuildService.updateAccess(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      {
        access_control_method: this.props.guild.trackingBased ? 0 : 1,
      },
    )
      .then((result) => {
        message.success('Control method changed.', 1)
        this.props.refreshGuild(result.data)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          'Something went wrong saving your changes. Please try again.',
          3,
        )
      })
      .then((_) => {
        this.setState({ savingControlMethodChanges: false })
      })
  }

  removeGoogleAccount = (id) => {
    this.setState({ removingGoogleAccount: id })
    GuildService.removeLinkedAccount(this.props.guild.id, id)
      .then((result) => this.setState({ linkedAccounts: result.data.accounts }))
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          'Something went wrong removing this Google account. Please try again.',
          3,
        )
      })
      .then(() => {
        this.setState({ removingGoogleAccount: null })
      })
  }

  render() {
    return (
      <div id='access-control-page'>
        <h1>
          <div className='title-caption'>General</div>
          <Icon type='unlock' />
          Access Control
        </h1>

        <p>
          By default access to your guild&apos;s dashboard is unrestricted.
          Anyone who is logged in can freely change settings and information
          until you restrict access here.
        </p>

        <h2>Guild rank-based access</h2>
        <p>
          The easiest way to restrict access is to set thresholds based on the
          in-game guild rank of users. New members who join the guild in the
          future will automatically receive access based on their rank. You
          can&apos;t restrict access to a higher rank than your own highest
          rank.
        </p>

        <div className='thresholds'>
          <AccessType
            type='admin'
            guild={this.props.guild}
            user={this.props.user}
            handleChange={this.handleSliderChange}
            value={this.state.adminThreshold}
            description='Users with these ranks will have complete access.'
          />

          <AccessType
            type='raider'
            guild={this.props.guild}
            user={this.props.user}
            disabled={this.props.guild.trackingBased}
            handleChange={this.handleSliderChange}
            value={this.state.raiderThreshold}
            description='Users with these ranks will also have view access to the overview of Applications.'
          />

          <AccessType
            type='basic'
            guild={this.props.guild}
            user={this.props.user}
            disabled={this.props.guild.trackingBased}
            handleChange={this.handleSliderChange}
            value={this.state.basicThreshold}
            description='Users with these ranks will have view access to the Roster, Refresh Status and Raids pages,
                         and can interact with their own characters in the Raids section.'
          />

          <AccessType
            type='none'
            guild={this.props.guild}
            user={this.props.user}
            disabled={this.props.guild.trackingBased}
            handleChange={this.handleSliderChange}
            reverse={true}
            value={10 - this.state.basicThreshold}
            description='Users with these ranks will not have any access at all.'
          />

          <div className='actions'>
            <Button
              disabled={!this.hasChanges() || this.state.savingSliderChanges}
              onClick={this.resetSliders}
            >
              Undo changes
            </Button>

            <Button
              type='primary'
              disabled={!this.hasChanges()}
              loading={this.state.savingSliderChanges && this.hasChanges()}
              onClick={this.saveSliderChanges}
            >
              Save changes
            </Button>
          </div>
        </div>

        <h2>Tracking-based access</h2>
        <p>
          Instead of providing access based on guild rank, you can enable
          tracking-based access. This means that anyone that is being tracked in
          the guild will have access according to the visibility of their
          character&apos;s rank. Anyone not in the guild or not logged in will
          not have any access.
        </p>

        <p>
          This setting can be useful if there are multiple users part of your
          roster that aren&apos;t a part of the guild, since guild rank-based
          access won&apos;t work in that situation. When this option is enabled
          admin access will still be provided based on the guild rank, but all
          other access is managed by the tracking status.
        </p>

        <Switch
          checkedChildren={
            <React.Fragment>
              <Icon type='check' />
              &nbsp;&nbsp;&nbsp;enabled
            </React.Fragment>
          }
          unCheckedChildren={
            <React.Fragment>
              disabled&nbsp;&nbsp;&nbsp;
              <Icon type='close' />
            </React.Fragment>
          }
          defaultChecked={this.props.guild.trackingBased}
          loading={this.state.savingControlMethodChanges}
          onChange={this.toggleAccessControlMethod}
        />

        <h2>Admin accounts (Google)</h2>
        <p>
          If you would like to provide admin access to a user irrespective of
          their guild rank you can add Google accounts here. Any Google account
          listed has full admin access to the guild&apos;s dashboard. You can
          only log in with a Google account when you&apos;re already logged in
          with your Blizzard account and you don&apos;t already have admin
          access.
        </p>

        <div className='google-auth-url'>
          <span>
            Use this URL to link a new Google account to your guild. When
            sharing it, remember that it expires at midnight.
          </span>
          <a
            target='_blank'
            rel='noreferrer'
            className='key'
            href={this.props.guild.googleAuthUrl}
          >
            {this.props.guild.googleAuthUrl}
          </a>
        </div>

        <h3>Linked accounts</h3>

        <div className='linked-accounts'>
          <Spin spinning={!this.state.fetchedAccounts}>
            {this.state.linkedAccounts.map((account, _) => (
              <div className='account' key={account.id}>
                <span>
                  <a href={`mailto:${account.email}`}>{account.email}</a>
                </span>
                {!account.isCurrentUser && (
                  <Popconfirm
                    title='Remove this Google account?'
                    onConfirm={() => this.removeGoogleAccount(account.id)}
                    okText='Remove'
                    disabled={this.state.removingGoogleAccount}
                  >
                    <Icon
                      type={
                        this.state.removingGoogleAccount == account.id
                          ? 'loading'
                          : 'delete'
                      }
                    />
                  </Popconfirm>
                )}
              </div>
            ))}
            {this.state.linkedAccounts.length == 0 &&
              this.state.fetchedAccounts && (
                <span className='no-linked-accounts'>
                  No Google accounts linked
                </span>
              )}
          </Spin>
        </div>

        <h2>Reset access cache</h2>
        <p>
          To improve the speed and performance of the website access is heavily
          cached. If a user gains or loses ranks in the guild (or their tracked
          status changes) they will keep their access until they explicitly log
          out.
        </p>

        <p>
          All cached access will be reset anytime you make a change on this
          page. Additionally, if you would like to revoke a user&apos;s access
          or grant them a higher access level without the user having to log out
          and in again, you can press this button to reset all cached access
          manually.
        </p>

        <Button
          type='danger'
          onClick={() => {
            this.resetSliders()
            this.saveSliderChanges()
          }}
          loading={this.state.savingSliderChanges && !this.hasChanges()}
        >
          Reset access cache
        </Button>
      </div>
    )
  }
}

export default AccessControlPage
