import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import moment from 'moment'

import { Tooltip, Icon, Button, message } from 'antd'

import TeamService from '../services/TeamService'

class RefreshStatusBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      refreshingManually: false,
    }
  }

  componentDidUpdate() {
    if (
      this.state.refreshingManually &&
      this.props.status &&
      this.props.status.last_refresh
    ) {
      let refreshed =
        moment.unix() - moment(this.props.status.last_refresh).unix()
      if (refreshed < 25) {
        this.setState({ refreshingManually: false })
      }
    }
  }

  refresh = () => {
    this.setState({ refreshingManually: true })
    TeamService.refreshType(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      `last_refreshed_${this.props.type}`,
    ).catch((_) => {
      if (_.response.status == 401) window.location.reload()
      message.error(
        `Something went wrong refreshing your data. Please try again.`,
        3,
      )
      this.setState({ refreshingManually: false })
    })
  }

  render() {
    const data = this.props.status

    let lastRefreshed = data && moment(this.props.status.last_refresh)
    lastRefreshed = data
      ? lastRefreshed.isValid()
        ? lastRefreshed.fromNow()
        : 'refreshing now'
      : '-'

    let nextRefresh = data && moment(this.props.status.estimate)
    nextRefresh = data
      ? nextRefresh.isValid()
        ? nextRefresh.fromNow()
        : '∞'
      : '-'

    const progress =
      data &&
      (lastRefreshed == 'refreshing now' ? 1 : this.props.status.progress)

    const types = {
      collections: {
        title: 'Blizzard',
        description: 'Data from the Blizzard API',
      },
      wcl: { title: 'Warcraft Logs', description: 'Data from Warcraft Logs' },
      raiderio: { title: 'Raider.io', description: 'Data from Raider.io' },
      keystones: {
        title: 'Keystones',
        description:
          "Data from the Mythic Keystone Dungeon Leaderboards of this guild's realm",
      },
    }

    return (
      <div className='refresh-status'>
        <h3>
          {types[this.props.type].title}
          <Tooltip title={types[this.props.type].description}>
            <Icon type='question-circle-o' />
          </Tooltip>
        </h3>
        <div className='progress-bar-circle'>
          <CircularProgressbarWithChildren
            initialAnimation
            strokeWidth={10}
            value={progress && progress * 100}
            styles={{
              root: {
                height: '10rem',
                width: '10rem',
              },
              text: {
                fill: 'rgba(0, 0, 0, 0.65)',
              },
              path: {
                stroke: '#399652',
                transition: 'stroke-dashoffset 2s ease 0s',
                strokeLinecap: 'butt',
              },
              pathTransition:
                progress == 0 ? 'none' : 'stroke-dashoffset 2s ease 0s',
              trail: {
                stroke: 'rgba(0,0,0,0.2)',
              },
            }}
          >
            <div className='progress-bar-text'>
              <span className='progress'>Progress</span>
              <div className='percentage'>
                {progress
                  ? `${Number.parseFloat(progress * 100).toFixed(0)}%`
                  : '0%'}
              </div>
            </div>
          </CircularProgressbarWithChildren>
        </div>

        <div className='stat'>
          <label>Last refreshed</label>
          <div className='value'>{lastRefreshed || '-'}</div>
        </div>

        <div className='stat'>
          <label>Position in queue</label>
          <div className='value'>
            {data && lastRefreshed != 'refreshing now'
              ? this.props.status.position + 1
              : '-'}
          </div>
        </div>

        <div className='stat'>
          <label>Estimated next refresh</label>
          <div className='value'>{nextRefresh || '-'}</div>
        </div>

        {this.props.type !== 'keystones' && (
          <React.Fragment>
            {(!this.props.guild.manualRefresh ||
              !this.props.user.loggedIn ||
              this.props.user.accessLevel != 0) && (
              <Tooltip
                className='refresh-button'
                title={`Refreshing on demand is only available for admins on the Gold and Platinum Patreon tiers.${
                  !this.props.user.loggedIn
                    ? ' Please log in before using this feature.'
                    : ''
                }`}
              >
                <Button type='primary' disabled={true}>
                  Refresh now
                </Button>
              </Tooltip>
            )}
            {this.props.guild.manualRefresh && this.props.user.loggedIn && (
              <Button
                type='primary'
                onClick={this.refresh}
                loading={
                  this.state.refreshingManually ||
                  (data && lastRefreshed == 'refreshing now')
                }
              >
                Refresh now
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default RefreshStatusBlock
