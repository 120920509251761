class RealmService {
  async fetchAll() {
    const response = await fetch('/api/realms', { credentials: 'include' })
    return await response.json()
  }

  async fetchByKind(kind) {
    const response = await fetch(`/api/realms?kind=${kind}`, {
      credentials: 'include',
    })
    return await response.json()
  }
}

export default new RealmService()
