import React from 'react'
import moment from 'moment'

import { TimePicker, Select, Popconfirm, Icon, Checkbox, Tooltip } from 'antd'

class SingleRaidSettings extends React.Component {
  render() {
    const difficulties =
      this.props.kind === 'live'
        ? ['Mythic', 'Heroic', 'Normal', 'LFR']
        : ['', 'Heroic', 'Normal', '']

    return (
      <React.Fragment>
        <div className={this.props.showLabels ? 'field time' : ''}>
          {this.props.showLabels && <label>Start</label>}
          <TimePicker
            type='date'
            format='HH:mm'
            id={`start-time-${this.props.raid.day}`}
            placeholder='Start'
            defaultValue={
              this.props.raid.start_time
                ? moment(this.props.raid.start_time, 'HH:mm')
                : moment('00:00', 'HH:mm')
            }
            disabled={!this.props.active}
            onChange={(value) =>
              this.props.updateRaid(
                this.props.raid,
                'start_time',
                (value && value.format('HH:mm')) || '',
              )
            }
          />
        </div>

        <div className={this.props.showLabels ? 'field time' : ''}>
          {this.props.showLabels && <label>End</label>}
          <TimePicker
            type='date'
            format='HH:mm'
            id={`end-time-${this.props.raid.day}`}
            placeholder='End'
            defaultValue={
              this.props.raid.end_time
                ? moment(this.props.raid.end_time, 'HH:mm')
                : moment('00:00', 'HH:mm')
            }
            disabled={!this.props.active}
            onChange={(value) =>
              this.props.updateRaid(
                this.props.raid,
                'end_time',
                (value && value.format('HH:mm')) || '',
              )
            }
          />
        </div>

        <div className={this.props.showLabels ? 'field grow' : 'instance'}>
          {this.props.showLabels && <label>Instance</label>}
          <Select
            placeholder='Instance'
            value={this.props.instances && this.props.raid.instance_id}
            loading={!this.props.instances}
            disabled={!this.props.active}
            optionFilterProp='children'
            onChange={(value) =>
              this.props.updateRaid(this.props.raid, 'instance_id', value)
            }
          >
            {this.props.instances &&
              this.props.instances.map((instance, _) => (
                <Select.Option key={`inst-${instance.id}`} value={instance.id}>
                  {instance.name}
                </Select.Option>
              ))}
          </Select>
        </div>

        {(this.props.kind === 'live' ||
          this.props.kind === 'classic_progression') && (
          <div
            className={this.props.showLabels ? 'field static' : 'difficulty'}
          >
            {this.props.showLabels && <label>Difficulty</label>}
            <Select
              placeholder='Difficulty'
              defaultValue={this.props.raid.difficulty}
              disabled={!this.props.active}
              optionFilterProp='children'
              onChange={(value) =>
                // Super hacky way to prevent Mythic and LFR from being picked on classic... Please replace this...
                this.props.kind === 'live' || [1, 2].includes(value)
                  ? this.props.updateRaid(this.props.raid, 'difficulty', value)
                  : null
              }
            >
              {difficulties.map((difficulty, index) => (
                <Select.Option key={`diff-${index}`} value={index}>
                  {difficulty}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}

        {this.props.showLabels && (
          <React.Fragment>
            <div className='field static'>
              <label>Status</label>
              <Select
                placeholder='Status'
                defaultValue={this.props.raid.status}
                optionFilterProp='children'
                onChange={(value) =>
                  this.props.updateRaid(this.props.raid, 'status', value)
                }
              >
                {['Planned', 'Locked', 'Cancelled'].map((status, index) => (
                  <Select.Option key={index} value={status.toLowerCase()}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className='field with-checkbox'>
              <label>
                Optional{' '}
                <Tooltip
                  placement='left'
                  title='Optional raids will be ignored for attendance and other statistics'
                >
                  <Icon type='question-circle' />
                </Tooltip>
              </label>
              <Checkbox
                defaultChecked={this.props.raid.optional}
                onChange={(e) =>
                  this.props.updateRaid(
                    this.props.raid,
                    'optional',
                    e.target.checked,
                  )
                }
              />
            </div>

            <Popconfirm
              className='delete-raid'
              title='Delete this raid?'
              onConfirm={() =>
                this.props.updateRaid(this.props.raid, 'status', 2)
              }
              okText='Delete'
            >
              <Icon type='delete' />
            </Popconfirm>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default SingleRaidSettings
