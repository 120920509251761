import React from 'react'
import { withRouter } from 'react-router-dom'

import Image from '../components/Image'

class NotFound extends React.Component {
  render() {
    return (
      <div className='not-found-page'>
        <h1>Not Found</h1>
        <p>This page does not exist.</p>
        <Image name='page-not-found' extension='jpg' />
      </div>
    )
  }
}

export default withRouter(NotFound)
