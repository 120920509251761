import React from 'react'

class Image extends React.Component {
  setFallbackImage = (event) => {
    event.target.src = 'https://data.wowaudit.com/img/not-found.jpg'
  }

  render() {
    return (
      <img
        className={this.props.className}
        src={`https://data.wowaudit.com/img/${this.props.name}.${
          this.props.extension || 'png'
        }`}
        onError={this.setFallbackImage}
      />
    )
  }
}

export default Image
