import React from 'react'

import { Icon, Alert } from 'antd'

import PatreonCard from '../../../components/PatreonCard'

class PatreonPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
    }
  }

  selectTier = (level) => {
    this.setState({ selected: level })
  }

  render() {
    return (
      <div className='patreon-page'>
        <h1>
          <div className='title-caption'>General</div>
          <Icon type='star' />
          Patreon
        </h1>

        <p>
          Server upkeep is expensive and API requests to Blizzard&apos;s servers
          are limited. For this reason your guild&apos;s data can only be
          refreshed at a certain frequency.
        </p>
        <p>
          For a faster refresh speed and more control over your guild&apos;s
          data, or if you would like to support this project&apos;s development,
          you can upgrade your guild to one of several different premium Patreon
          tiers:
        </p>

        {this.props.serverError && (
          <Alert message={this.props.serverError} type='error' showIcon />
        )}

        <div className='patreon-levels'>
          <PatreonCard
            level='Bronze'
            refreshFrequency='3'
            teamLimit='2'
            characterLimit='40'
            price='1'
            patreonId='1415179'
            guild={this.props.guild}
            selectTier={this.selectTier}
            selected={this.state.selected}
            user={this.props.user}
          />

          <PatreonCard
            level='Silver'
            refreshFrequency='6'
            teamLimit='5'
            characterLimit='50'
            price='3'
            patreonId='1415200'
            guild={this.props.guild}
            selectTier={this.selectTier}
            selected={this.state.selected}
            user={this.props.user}
          />

          <PatreonCard
            level='Gold'
            refreshFrequency='10'
            teamLimit='7'
            characterLimit='75'
            price='5'
            patreonId='4489422'
            guild={this.props.guild}
            selectTier={this.selectTier}
            selected={this.state.selected}
            user={this.props.user}
          />

          <PatreonCard
            level='Platinum'
            refreshFrequency='15'
            teamLimit='10'
            characterLimit='100'
            price='10'
            patreonId='1772543'
            guild={this.props.guild}
            selectTier={this.selectTier}
            selected={this.state.selected}
            user={this.props.user}
          />
        </div>
      </div>
    )
  }
}

export default PatreonPage
