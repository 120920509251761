import React from 'react'

import Image from '../components/Image'

class Exception extends React.Component {
  render() {
    const quotes = [
      'Well look at you! Da mighty hero... ha!',
      'What you be doin’ here, mon? It not your time to pass on... yet.',
      'Oh, da spirits be with ya now, mon.',
      "Tsk, tsk. I did not expect to be seein' ya here so soon.",
      'Let me guess… ya got in over ya head?',
      'Haha! Now dat was an impressive death!',
      'Ah, ya come ta spend some quality time with Bwonsamdi?',
      "Oh, poor little thing, ya be needin' some help from old Bwonsamdi?",
    ]

    return (
      <div className='exception-page'>
        <h1>{quotes[Math.floor(Math.random() * quotes.length)]}</h1>
        <p>
          Something went wrong. Please{' '}
          <a href='javascript:location.reload()'>try again</a> or reach out on
          <a href='https://discord.gg/86eUAFz' target='_blank' rel='noreferrer'>
            {' '}
            Discord
          </a>
          .
        </p>
        <Image name='bwonsamdi' />
      </div>
    )
  }
}

export default Exception
