import React from 'react'
import { Switch, Route } from 'react-router-dom'

import SidebarContent from '../components/SidebarContent'
import ProtectedPage from '../components/ProtectedPage'

import TeamService from '../services/TeamService'

import Exception from './Exception'
import Unauthorized from './Unauthorized'
import NotFound from './NotFound'
import GuildHomePage from './guild/general/GuildHomePage'
import RosterPage from './guild/general/RosterPage'
import AccessControlPage from './guild/general/AccessControlPage'
import PatreonPage from './guild/general/PatreonPage'
import ApiPage from './guild/general/ApiPage'
import SettingsPage from './guild/general/SettingsPage'
import DiscordBotPage from './guild/raids/DiscordBotPage'
import SpreadsheetStatusPage from './guild/spreadsheet/SpreadsheetStatusPage'
import RaidCalendar from './guild/raids/RaidCalendar'
import UpcomingPage from './guild/raids/UpcomingPage'
import ProfilePage from './guild/ProfilePage'
import RaidPage from './guild/raids/RaidPage'
import RaidStatisticsPage from './guild/raids/RaidStatisticsPage'
import WishlistUploadPage from './guild/wishlists/WishlistUploadPage'
import WishlistOverviewPage from './guild/wishlists/WishlistOverviewPage'
import LootHistoryPage from './guild/wishlists/LootHistoryPage'

import Cookies from 'universal-cookie'
const cookies = new Cookies()

import { Layout, Modal, Alert, Input, Button, Spin } from 'antd'
const { Sider, Content } = Layout

class Guild extends React.Component {
  constructor(props) {
    super(props)
    const data = document.getElementById('guild-data')
    const session = document.getElementById('session')

    this.state = {
      collapsed: false,
      creatingTeam: false,
      switchingTheme: false,
      modalLoading: false,
      modalError: false,
      menuOpen: false,
      patreonStatus: 'unchanged',
      hasException: false,
      guild: JSON.parse(data.getAttribute('data')).guild,
      user: JSON.parse(session.getAttribute('data')).session,
      instances: JSON.parse(data.getAttribute('data')).instances,
      seasons: JSON.parse(data.getAttribute('data')).seasons,
      droptimizer_upgrade_levels: JSON.parse(data.getAttribute('data'))
        .droptimizer_upgrade_levels,
      serverError: data.getAttribute('error'),
    }

    data.parentNode.removeChild(data)
  }

  componentDidCatch() {
    this.setState({ hasException: true })
  }

  openNewTeamModal = () => {
    this.setState({ creatingTeam: true })
  }

  changeTeam = (id) => {
    // TODO: Improve this, avoid nested state? How to deal with changing path here?
    this.state.guild.selectedTeam = this.state.guild.teams.find(
      (team) => team.id === id,
    )
    const url = this.props.match.params
    var path = [
      url.region,
      url.realm,
      url.guild,
      this.state.guild.selectedTeam.slug,
      url.category,
      url.page,
    ]
    history.pushState(null, null, `/${path.filter(Boolean).join('/')}`)
    this.forceUpdate()
  }

  createTeam = () => {
    this.setState({ modalLoading: true, modalError: false })
    TeamService.create(this.state.guild.id, this.state.teamName)
      .then((result) => {
        this.closeModals()
        setTimeout(() => {
          this.setState({ guild: result.data.guild })
          this.changeTeam(this.state.guild.selectedTeam.id)
        }, 200)
      })
      .catch((result) => {
        if (result.response.status == 401) window.location.reload()
        this.setState({
          modalLoading: false,
          modalError: result.response.data.error,
        })
      })
  }

  closeModals = () => {
    this.setState({ creatingTeam: false, modalLoading: false })
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value })
  }

  refresh = (data) => {
    this.setState({ guild: data.guild })
  }

  switchToTheme = (theme) => {
    this.setState({ switchingTheme: true })
    cookies.set('theme', theme, { path: '/', expires: new Date(2035, 1, 1) })
    window.location.reload()
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
    window.scrollTo(0, 0)
  }

  commonProps = () => {
    // TODO: Refactor this, proper state management?
    return {
      user: this.state.user,
      guild: this.state.guild,
      refreshGuild: this.refresh,
      serverError: this.state.serverError,
      switchToTheme: this.switchToTheme,
      path: this.props.match.params,
      instances: this.state.instances,
      seasons: this.state.seasons,
      droptimizer_upgrade_levels: this.state.droptimizer_upgrade_levels,
    }
  }

  render() {
    return (
      <Spin size='large' spinning={this.state.switchingTheme}>
        <Layout>
          <Modal
            visible={this.state.creatingTeam}
            title='Create new team'
            onOk={this.createTeam}
            onCancel={this.closeModals}
            footer={[
              <Button key='back' onClick={this.closeModals}>
                Cancel
              </Button>,
              <Button
                key='submit'
                type='primary'
                disabled={
                  !this.state.teamName ||
                  this.state.guild.teams.length >= this.state.guild.teamLimit
                }
                loading={this.state.modalLoading}
                onClick={this.createTeam}
              >
                Create team
              </Button>,
            ]}
          >
            {(this.state.guild.teams.length >= this.state.guild.teamLimit ||
              this.state.modalError) && (
              <Alert
                message={
                  this.state.modalError ||
                  'You have reached the team limit for your current Patreon level.'
                }
                type='error'
                showIcon
              />
            )}
            <Input
              placeholder='Team name'
              maxLength={20}
              disabled={
                this.state.guild.teams.length >= this.state.guild.teamLimit
              }
              onChange={(event) =>
                this.handleInputChange('teamName', event.target.value)
              }
            />
          </Modal>

          <Sider
            breakpoint='lg'
            trigger={null}
            collapsible
            className={this.state.menuOpen ? 'open' : ''}
            collapsed={this.state.collapsed}
          >
            <SidebarContent
              changeTeam={this.changeTeam}
              openNewTeamModal={this.openNewTeamModal}
              user={this.state.user}
              toggleMenu={this.toggleMenu}
              guild={this.state.guild}
              path={this.props.match.params}
            />
          </Sider>
          <Content className={this.state.menuOpen ? 'menu-open' : ''}>
            {this.state.hasException && <Exception />}
            {!this.state.guild.selectedTeam.active &&
              this.state.user.accessLevel == 0 && (
                <SettingsPage
                  user={this.state.user}
                  guild={this.state.guild}
                  refreshGuild={this.refresh}
                  path={this.props.match.params}
                  switchToTheme={this.switchToTheme}
                  changeTeam={this.changeTeam}
                />
              )}
            {!this.state.guild.selectedTeam.active &&
              !(this.state.user.accessLevel == 0) && (
                <Unauthorized
                  user={this.state.user}
                  guild={this.state.guild}
                  region={this.state.guild.region}
                  threshold={0}
                />
              )}
            {!this.state.hasException && this.state.guild.selectedTeam.active && (
              <Switch>
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?'
                  render={() => (
                    <ProtectedPage
                      threshold={2}
                      component={GuildHomePage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/roster'
                  render={() => (
                    <ProtectedPage
                      threshold={2}
                      component={RosterPage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                {this.state.user.loggedIn && (
                  <Route
                    exact
                    path='/:region/:realm/:guild/:team?/access'
                    render={() => (
                      <ProtectedPage
                        threshold={0}
                        component={AccessControlPage}
                        commonProps={this.commonProps()}
                      />
                    )}
                  />
                )}
                {!this.state.user.loggedIn && (
                  <Route
                    exact
                    path='/:region/:realm/:guild/:team?/access'
                    render={() => (
                      <Unauthorized
                        user={this.state.user}
                        guild={this.state.guild}
                        region={this.state.guild.region}
                        threshold={0}
                      />
                    )}
                  />
                )}
                {this.state.user.loggedIn && (
                  <Route
                    exact
                    path='/:region/:realm/:guild/:team?/api'
                    render={() => (
                      <ProtectedPage
                        threshold={0}
                        component={ApiPage}
                        commonProps={this.commonProps()}
                      />
                    )}
                  />
                )}
                {!this.state.user.loggedIn && (
                  <Route
                    exact
                    path='/:region/:realm/:guild/:team?/api'
                    render={() => (
                      <Unauthorized
                        user={this.state.user}
                        guild={this.state.guild}
                        region={this.state.guild.region}
                        threshold={0}
                      />
                    )}
                  />
                )}
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/premium'
                  render={() => (
                    <ProtectedPage
                      threshold={2}
                      component={PatreonPage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/settings'
                  render={() => (
                    <ProtectedPage
                      threshold={0}
                      component={SettingsPage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/discord_bot'
                  render={() => (
                    <ProtectedPage
                      threshold={2}
                      component={DiscordBotPage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/spreadsheet/status'
                  render={() => (
                    <ProtectedPage
                      threshold={2}
                      component={SpreadsheetStatusPage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/raids'
                  render={() => (
                    <ProtectedPage
                      threshold={2}
                      component={RaidCalendar}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                {this.state.user.loggedIn && (
                  <Route
                    exact
                    path='/:region/:realm/:guild/:team?/raids/upcoming'
                    render={() => (
                      <ProtectedPage
                        threshold={2}
                        component={UpcomingPage}
                        commonProps={this.commonProps()}
                      />
                    )}
                  />
                )}
                {!this.state.user.loggedIn && (
                  <Route
                    exact
                    path='/:region/:realm/:guild/:team?/raids/upcoming'
                    render={() => (
                      <Unauthorized
                        user={this.state.user}
                        guild={this.state.guild}
                        region={this.state.guild.region}
                        threshold={2}
                      />
                    )}
                  />
                )}
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/raids/statistics'
                  render={() => (
                    <ProtectedPage
                      threshold={2}
                      component={RaidStatisticsPage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/wishlists/personal'
                  render={() => (
                    <ProtectedPage
                      threshold={2}
                      component={WishlistUploadPage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/wishlists/overview'
                  render={() => (
                    <ProtectedPage
                      threshold={
                        this.state.guild.selectedTeam.settings.find(
                          (s) => s.field === 'wishlist_overview_visibility',
                        )?.value === 'admins_only'
                          ? 0
                          : 2
                      }
                      component={WishlistOverviewPage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/wishlists/loot_history'
                  render={() => (
                    <ProtectedPage
                      threshold={
                        this.state.guild.selectedTeam.settings.find(
                          (s) => s.field === 'wishlist_loot_history_visibility',
                        )?.value === 'admins_only'
                          ? 0
                          : 2
                      }
                      component={LootHistoryPage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />

                {this.state.user.loggedIn && this.state.user.bnet && (
                  <Route
                    exact
                    path='/:region/:realm/:guild/:team?/profile'
                    render={() => <ProfilePage {...this.commonProps()} />}
                  />
                )}

                <Route
                  exact
                  path='/:region/:realm/:guild/:team?/raids/:raid'
                  render={() => (
                    <ProtectedPage
                      threshold={2}
                      component={RaidPage}
                      commonProps={this.commonProps()}
                    />
                  )}
                />
                <Route
                  path='/*'
                  render={() => (
                    <NotFound user={this.state.user} guild={this.state.guild} />
                  )}
                />
              </Switch>
            )}
          </Content>
        </Layout>
      </Spin>
    )
  }
}

export default Guild
