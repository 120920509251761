import React from 'react'
import { Link } from 'react-router-dom'

import { Alert, message, Button } from 'antd'

import GuildService from '../services/GuildService'

class ActivityAlert extends React.Component {
  state = {
    refreshingGuildActivity: false,
  }

  markAsActive = () => {
    this.setState({ refreshingGuildActivity: true })
    const finishLoading = message.loading('Activating guild..', 0)
    GuildService.touch(this.props.guild.id, this.props.guild.selectedTeam.id)
      .then((result) => {
        message.success('Refreshing activated.', 2)
        finishLoading()
        this.props.refreshGuild(result.data)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        finishLoading()
        this.setState({ refreshingGuildActivity: false })
        message.error(
          `Something went wrong reactivating your guild. Please try again.`,
          3,
        )
      })
      .then(() => null)
  }
  render() {
    return (
      <Alert
        type='warning'
        className='activity-alert'
        showIcon
        message={
          <span>
            {this.props.guild.active &&
              `Your guild's data will stop refreshing after ${this.props.guild.expiry} more days of inactivity. `}
            {!this.props.guild.active &&
              `Your guild's data is no longer refreshing due to inactivity. `}
            You can visit the
            <Link to={`${this.props.guild.selectedTeam.path}/premium`}>
              {' '}
              Patreon page{' '}
            </Link>{' '}
            to lift this restriction or click the button to{' '}
            {this.props.guild.active ? 'keep' : 'start'} refreshing for{' '}
            <strong>60</strong> more days.
            <Button
              type='success'
              onClick={this.markAsActive}
              loading={this.state.refreshingGuildActivity}
            >
              {this.props.guild.active ? 'Keep refreshing' : 'Start refreshing'}
            </Button>
          </span>
        }
      />
    )
  }
}

export default ActivityAlert
