import React from 'react'
import RealmService from '../services/RealmService'
import GuildService from '../services/GuildService'
import Image from '../components/Image'

import { Select, Input, Alert } from 'antd'
import LandingPageContainer from '../components/LandingPageContainer'
const { Option } = Select
const InputGroup = Input.Group

export const labelForKind = {
  classic_era: 'Vanilla',
  classic_progression: 'Cataclysm',
  season_of_discovery: 'Season of Discovery',
  live: 'War Within',
  tournament: 'Tournament',
}

export const subdomainForKind = {
  classic_era: 'vanilla.',
  classic_progression: 'classic.',
  live: '',
  tournament: 'tournament.',
}

class Home extends React.Component {
  state = {
    realms: [],
    searching: false,
  }

  componentDidMount() {
    const kind = document.getElementById('subdomain')?.getAttribute('data-kind')
    RealmService.fetchByKind(kind === 'live' ? '' : kind).then((realms) => {
      this.setState({ realms })
    })
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value })
  }

  search = () => {
    this.setState({ searching: true, error: false })
    GuildService.validate(this.state.realm, this.state.guildName)
      .then((result) => {
        if (!result.data.error) {
          window.location = result.data.path
        } else {
          this.setState({ searching: false, error: result.data.error })
        }
      })
      .catch((response) => {
        this.setState({ searching: false, error: response.response.data.error })
      })
      .catch((_) =>
        this.setState({ searching: false, error: 'An error occurred' }),
      )
  }

  render() {
    return (
      <LandingPageContainer
        className={
          this.state.realm
            ? this.state.error
              ? 'with-alert'
              : 'with-search'
            : ''
        }
      >
        <h1 className='logo'>
          <Image name='new-logo-icon' className='icon-only' extension='svg' />
          Search guild
        </h1>
        <InputGroup compact>
          <Select
            style={{ width: '30%' }}
            placeholder='EU'
            value={this.state.region}
            onChange={(value) => {
              this.handleInputChange('region', value)
              this.handleInputChange('realm', '')
            }}
          >
            {['EU', 'US', 'TW', 'KR'].map((region, index) => (
              <Option key={region} value={region}>
                {region}
              </Option>
            ))}
          </Select>
          <Select
            style={{ width: '70.34%' }}
            placeholder='Realm'
            disabled={!this.state.region}
            showSearch
            value={this.state.realm}
            onChange={(value) => this.handleInputChange('realm', value)}
            optionFilterProp='children'
          >
            {this.state.realms[this.state.region] &&
              this.state.realms[this.state.region].map((realm, index) => (
                <Option key={realm.id} value={realm.id}>
                  {realm.name}
                  <div className='kind'>{labelForKind[realm.subkind]}</div>
                </Option>
              ))}
          </Select>
        </InputGroup>
        <Input.Search
          placeholder='Guild name'
          className={!this.state.realm && 'disabled'}
          disabled={!this.state.realm}
          onSearch={this.search}
          onChange={(event) =>
            this.handleInputChange('guildName', event.target.value)
          }
          loading={this.state.searching ? 'true' : undefined}
          enterButton
        />
        <Alert
          className={!this.state.error && 'inactive'}
          message={this.state.error}
          type='error'
          showIcon
        />
      </LandingPageContainer>
    )
  }
}

export default Home
