import React from 'react'
import { Checkbox, Icon, Tooltip } from 'antd'
import { useDraggable } from '@dnd-kit/core'

import Image from '../Image'
import EntityDroppable from './EntityDroppable'
import CustomDifficultyTooltip from './CustomDifficultyTooltip'

const EncounterRow = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.encounter.order_id,
  })

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined

  return (
    <div>
      <EntityDroppable orientation='before' id={props.encounter.order_id} />
      <div
        key={props.encounter.order_id}
        ref={setNodeRef}
        onClick={() => props.changeEncounterScope(props.encounter)}
        className={`encounter-row draggable-item ${
          transform ? 'dragging' : ''
        } ${props.hasBossSelections(props.encounter) ? 'active' : ''} ${
          props.selectedEncounter == props.encounter ? 'selected' : ''
        }`}
        style={style}
        {...attributes}
      >
        <Icon type='more' {...listeners} />
        {props.guild.kind === 'classic_progression' ? (
          <img src={props.encounter.zoom_url} />
        ) : (
          <Image name={props.encounter.background} extension='jpg' />
        )}

        <span className='boss-name'>
          {props.encounter.difficulty !== props.raid.difficulty && (
            <CustomDifficultyTooltip
              encounter={props.encounter}
              raid={props.raid}
            />
          )}
          {props.encounter.name}
        </span>
        <span className='selected-counter'>
          {props.hasBossSelections(props.encounter)
            ? props.selectedCounter(false, props.encounter)
            : ''}
        </span>
        {props.user.accessLevel == 0 && (
          <Tooltip title='Show selections' placement='right'>
            <Checkbox
              checked={props.activeEncounterCheckboxes.includes(
                props.encounter.order_id,
              )}
              defaultChecked={props.hasBossSelections(props.encounter)}
              onChange={() => props.toggleEncounterSelections(props.encounter)}
            />
          </Tooltip>
        )}
        {props.user.loggedIn && (
          <Tooltip
            title={
              props.selectedForEncounter(props.encounter)
                ? 'Selected'
                : 'Not selected'
            }
            placement='right'
          >
            <Icon
              type={
                props.selectedForEncounter(props.encounter) ? 'check' : 'close'
              }
            />
          </Tooltip>
        )}
      </div>
      <EntityDroppable orientation='after' id={props.encounter.order_id} />
    </div>
  )
}

export default EncounterRow
