import React from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { countBy, flatten, isEqual, uniqBy } from 'lodash'
import { useSetting } from '../../../helpers'
import { DndContext } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'

import { Icon, Spin, message, Tooltip, Select, Input, Button } from 'antd'

import moment from 'moment'
import 'moment/locale/en-gb'

import SingleRaidSettings from '../../../components/SingleRaidSettings'
import CharacterSet from '../../../components/raid/CharacterSet'
import Strategy from '../../../components/raid/Strategy'
import EncounterSelection from '../../../components/raid/EncounterSelection'
import Image from '../../../components/Image'
import CopyRaidPlanningModal from '../../../components/raid/CopyRaidPlanningModal'
import CustomDifficultyTooltip from '../../../components/raid/CustomDifficultyTooltip'

import TeamService from '../../../services/TeamService'
import RaidService from '../../../services/RaidService'
import InstanceService from '../../../services/InstanceService'
import AddManualModal from '../../../components/raid/AddManualModal'

class RaidPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideError: () => null,
      loading: !props.location.state,
      raidUpdating: false,
      dragging: false,
      pendingChanges: [],
      saveInProgress: false,
      showPlanningDetails:
        localStorage.getItem('showPlanningDetails') === 'true' &&
        this.props.user.accessLevel == 0,
      inviteStringCopied: false,
      activeEncounterCheckboxes: [],
      selectedDate: props.location.state && props.location.state.selectedDate,
      selectedCharacter: null,
      customRole: null,
      manuallyAddedCharacters: [],
      saveSelectionChanges: AwesomeDebouncePromise(
        this.saveSelectionChanges,
        2000,
      ),
      currentTeam: this.props.guild.selectedTeam.id,
      planningInfo: undefined,
    }

    // Don't use forwarded calendar state when refreshing
    window.history.replaceState(null, null)
  }

  componentDidMount() {
    TeamService.fetchRaid(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      this.props.path.page,
    )
      .then((raid) => {
        this.refresh(raid)
        if (this.state.showPlanningDetails) this.fetchPlanningDetails(raid.data)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        this.goToCalendar()
      })

    InstanceService.fetchByKind(this.props.guild.subkind).then((instances) => {
      this.setState({ instances })
    })
  }

  componentDidUpdate() {
    // TODO: Refactor this, improve state management
    if (this.state.currentTeam != this.props.guild.selectedTeam.id) {
      this.goToCalendar()
    }
  }

  fetchPlanningDetails = async (raid) => {
    return await RaidService.fetchPlanningInfo(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      raid.id,
    )
      .then((response) => {
        this.setState({ planningInfo: response.data })
      })
      .catch((_) => {
        message.error(
          'Failed to retrieve planning info. You can try reloading the page.',
          3,
        )
      })
  }

  refresh = (raid) => {
    let encounter
    if (
      (encounter =
        (this.state.selectedEncounter &&
          raid.data.instance.encounters.find(
            (e) => e.order_id == this.state.selectedEncounter.order_id,
          )) ||
        raid.data.instance.encounters.filter((e) => e.active)[0])
    ) {
      this.setState({
        selectedSignups: encounter.selections,
        selectedEncounter: encounter,
      })
    } else {
      this.setState({
        selectedSignups: raid.data.signups,
        selectedEncounter: null,
      })
    }
    this.setState({
      loading: false,
      raid: raid.data,
      encounter_order: raid.data.instance.encounters.map((e) => e.order_id),
      activeEncounterCheckboxes: raid.data.instance.encounters
        .filter((e) => e.active)
        .map((e) => e.order_id),
      saveInProgress: false,
      selectionCount: countBy(
        flatten(
          raid.data.instance.encounters.map((encounter) =>
            encounter.selections.map((selection) =>
              selection.selected ? selection.character.id : null,
            ),
          ),
        ),
      ),
      characterPresence:
        this.characterForRaid(raid.data) &&
        this.characterForRaid(raid.data).status,
      characterComment:
        this.characterForRaid(raid.data) &&
        this.characterForRaid(raid.data).comment,
    })
  }

  savePresenceChanges = () => {
    this.setState({ presenceLoading: true })
    RaidService.updatePresence(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      this.state.raid.id,
      {
        character: this.state.selectedCharacter
          ? {
              blizzard_realm_id: this.state.selectedCharacter.blizzard_realm_id,
              name: this.state.selectedCharacter.name,
            }
          : null,
        custom_role: this.state.customRole,
        status: this.state.characterPresence || 'present',
        comment: this.state.characterComment,
      },
    )
      .then((result) => {
        this.refresh(result)
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          'Something went wrong saving your changes. Please try again.',
          3,
        )
      })
      .then((_) => {
        this.setState({ presenceLoading: false })
      })
  }

  updateRaidDay = (_, field, value) => {
    if (field == 'instance_id') this.setState({ raidUpdating: true })
    if (field == 'status' && value == 2) {
      const finishLoading = message.loading('Deleting raid..', 0)
      RaidService.updateRaid(
        this.props.guild.id,
        this.props.guild.selectedTeam.id,
        this.state.raid.id,
        { [field]: value },
      ).then((_) => {
        finishLoading()
        this.goToCalendar()
      })
    } else {
      const finishLoading = message.loading('Saving changes..', 0)
      RaidService.updateRaid(
        this.props.guild.id,
        this.props.guild.selectedTeam.id,
        this.state.raid.id,
        { [field]: value },
      )
        .then((result) => {
          message.success('Changes saved.', 1)
          this.refresh(result)
        })
        .catch((_) => {
          if (_.response.status == 401) window.location.reload()
          this.state.hideError = message.error(
            <React.Fragment>
              Something went wrong saving your changes. Please
              <a onClick={(_) => this.updateRaidDay(null, field, value)}>
                {' try again.'}
              </a>
            </React.Fragment>,
            0,
          )
        })
        .then(() => {
          finishLoading()
          this.setState({ raidUpdating: false })
        })
    }
  }

  updateSelections = async (signup, field, value) => {
    this.setState((prevState, _) => {
      let countChange = 0

      return {
        selectedSignups: prevState.selectedSignups.map((prevSignup) => {
          if (prevSignup.id == signup.id) {
            if (!field && !value) {
              countChange = prevSignup.selected ? -1 : 1
              prevSignup.selected = !prevSignup.selected
            } else {
              prevSignup[field] = value
            }
            signup = JSON.parse(JSON.stringify(prevSignup))
          }
          signup.encounter_id =
            this.state.selectedEncounter && this.state.selectedEncounter.id
          return prevSignup
        }),
        selectionCount: {
          ...prevState.selectionCount,
          [signup.character.id]:
            (prevState.selectionCount[signup.character.id] || 0) + countChange,
        },
      }
    })

    this.state.pendingChanges.push(signup)
    this.state.saveSelectionChanges()
  }

  handleDragEnd = (event) => {
    let newEncounterOrder = [...this.state.encounter_order]
    if (event.over?.id) {
      const movedEncounter = newEncounterOrder.indexOf(event.active.id)
      const movedTo = newEncounterOrder.indexOf(event.over.id.split('-')[1])

      if (movedTo !== movedEncounter) {
        newEncounterOrder.splice(movedEncounter, 1)
        newEncounterOrder.splice(
          movedTo +
            (movedEncounter < movedTo ? -1 : 0) +
            (event.over.id.split('-')[0] == 'before' ? 0 : 1),
          0,
          event.active.id,
        )

        if (!isEqual(newEncounterOrder, this.state.encounter_order)) {
          this.updateRaidDay(null, 'encounter_order', newEncounterOrder)
        }
      }
    }

    this.setState({ encounter_order: newEncounterOrder, dragging: false })
  }

  handleDragStart = () => {
    this.setState({ dragging: true })
  }

  addManualCharacter = (result) => {
    this.setState((prevState, _) => {
      return {
        selectedCharacter: result,
        manuallyAddedCharacters: prevState.manuallyAddedCharacters.concat([
          result,
        ]),
      }
    })
  }

  saveSelectionChanges = () => {
    this.state.hideError()
    const finishLoading = message.loading('Saving changes..', 0)
    if (this.state.saveInProgress) finishLoading()
    const changes = this.state.pendingChanges
    this.setState({ pendingChanges: [], saveInProgress: changes })

    RaidService.updateSelection(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      this.state.raid.id,
      changes,
    )
      .then((result) => {
        message.success('Changes saved.', 1)
        if (
          this.state.pendingChanges.length == 0 &&
          this.state.saveInProgress == changes
        ) {
          this.refresh(result)
        }
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        this.setState({ pendingChanges: changes, saveInProgress: false })
        this.state.hideError = message.error(
          <React.Fragment>
            Something went wrong saving your changes. Please
            <a onClick={(_) => this.saveSelectionChanges()}>{' try again.'}</a>
          </React.Fragment>,
          0,
        )
      })
      .then((_) => {
        finishLoading()
      })
  }

  signupStatus = (status) => {
    if (status == 'present') {
      return <Icon type='check' />
    } else if (status == 'absent') {
      return <Icon type='close' />
    } else if (status == 'missing') {
      return <Icon type='stop' />
    } else if (status == 'late') {
      return <Icon type='clock-circle' />
    } else if (status == 'standby') {
      return <Icon type='pause-circle' />
    } else {
      return <Icon type='question' />
    }
  }

  togglePlanningDetails = (event) => {
    event.stopPropagation()
    localStorage.setItem('showPlanningDetails', !this.state.showPlanningDetails)

    if (
      !this.state.showPlanningDetails &&
      this.state.planningInfo === undefined
    ) {
      this.fetchPlanningDetails(this.state.raid)
    }

    this.setState({ showPlanningDetails: !this.state.showPlanningDetails })
  }

  toggleEncounterSelections = (encounter) => {
    this.state.hideError()
    const finishLoading = message.loading('Saving encounter status..', 0)
    if (this.state.saveInProgress) finishLoading()
    this.setState({ saveInProgress: encounter })

    if (this.state.activeEncounterCheckboxes.includes(encounter.order_id)) {
      this.setState((prevState, _) => {
        prevState.activeEncounterCheckboxes.splice(
          prevState.activeEncounterCheckboxes.indexOf(encounter.order_id),
          1,
        )
        return {
          activeEncounterCheckboxes: prevState.activeEncounterCheckboxes,
        }
      })
    } else {
      this.setState((prevState, _) => {
        return {
          activeEncounterCheckboxes: prevState.activeEncounterCheckboxes.concat(
            [encounter.order_id],
          ),
        }
      })
    }

    RaidService.toggleEncounter(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      this.state.raid.id,
      encounter,
      this.state.selectedSignups,
    )
      .then((result) => {
        if (this.state.saveInProgress == encounter) {
          message.success('Encounter status saved.', 1)
          let newEncounterData = result.data.instance.encounters.find(
            (newEncounter) => newEncounter.order_id == encounter.order_id,
          )
          this.setState({
            raid: result.data,
            selectedEncounter: newEncounterData,
            selectedSignups: newEncounterData.selections,
            saveInProgress: false,
            selectionCount: countBy(
              flatten(
                result.data.instance.encounters.map((encounter) =>
                  encounter.selections.map((selection) =>
                    selection.selected ? selection.character.id : null,
                  ),
                ),
              ),
            ),
          })
        }
      })
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        this.state.hideError = message.error(
          <React.Fragment>
            Something went wrong saving the encounter status. Please
            <a onClick={(_) => this.toggleEncounterSelections(encounter)}>
              {' try again.'}
            </a>
          </React.Fragment>,
          0,
        )
      })
      .then((_) => {
        finishLoading()
      })
  }

  changeEncounterScope = (encounter) => {
    this.setState({
      selectedSignups: this.selectionsForEncounter(encounter),
      selectedEncounter: encounter,
    })
  }

  selectionsForEncounter = (encounter) => {
    return (encounter && encounter.selections.length > 0
      ? encounter.selections
      : this.state.raid.signups
    ).filter((selection) => selection.status != 'unknown')
  }

  selectedForEncounter = (encounter) => {
    return this.selectionsForEncounter(encounter).find(
      (signup) =>
        this.props.user.character_data
          .map((ch) => ch.key)
          .includes(signup.character.key) && signup.selected,
    )
  }

  userCharacters = (guestsEnabled) => {
    const signupIds = this.state.raid.signups.map(
      (signup) => signup.character.id,
    )
    const teamKeys = this.props.guild.selectedTeam.characters.map(
      (ch) => ch.blizzard_key,
    )

    const validCharacters = this.props.user.character_data.filter(
      (character) => {
        if (guestsEnabled === 'with_any_character') return true
        if (guestsEnabled === 'only_with_owned_characters') return true

        if (guestsEnabled === 'only_with_characters_in_team') {
          return teamKeys.includes(character.key)
        }

        return false
      },
    )

    const manuallyAddedSignups = this.props.guild.selectedTeam.guests.filter(
      (guest) => {
        if (guestsEnabled !== 'with_any_character') return false
        return (
          signupIds.includes(guest.id) &&
          guest.added_by_user_id === this.props.user.id
        )
      },
    )

    return uniqBy(
      validCharacters
        .concat(manuallyAddedSignups)
        .concat(this.state.manuallyAddedCharacters),
      (ch) => ch.key,
    )
  }

  characterForRaid = (raid = this.state.raid) => {
    return raid.signups.find((signup) =>
      this.props.user.character_data
        .map((ch) => ch.key)
        .includes(signup.character.key),
    )
  }

  hasBossSelections = (encounter) => {
    if (encounter) {
      return encounter.active
    } else {
      return (
        this.state.raid.instance.encounters.filter(
          (encounter) => encounter.active,
        ).length > 0
      )
    }
  }

  activeSelections = () => {
    return this.state.selectedSignups.filter(
      (signup) => signup.selected && signup.status !== 'unknown',
    )
  }

  goToCalendar = () => {
    this.setState({ backToCalendar: true })
  }

  selectedCounter = (showTotal, encounter = this.state.selectedEncounter) => {
    return `${
      this.selectionsForEncounter(encounter).filter((s) => s.selected).length
    }${
      showTotal
        ? ` / ${
            this.props.guild.kind === 'classic_era' ||
            this.props.guild.kind === 'classic_progression'
              ? this.state.raid.instance.raid_size
              : this.state.raid.difficulty == 0
              ? 20
              : 30
          }`
        : ''
    }`
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value })
  }

  copySelectionsForAddon = () => {
    navigator.clipboard.writeText(
      this.selectionsForEncounter(this.state.selectedEncounter)
        .filter((s) => s.selected)
        .map((signup) => {
          return `${signup.character.name}-${signup.character.realm_name}`
        })
        .join(';'),
    )
    this.setState({ inviteStringCopied: true })
    setTimeout(() => {
      this.setState({ inviteStringCopied: false })
    }, 5000)
  }

  copyRaidDetails = (result) => {
    if (result) this.refresh(result)
    this.setState({ showCopyModal: false })
  }

  render() {
    const statuses = useSetting(this.props, 'raids_available_statuses')
    const difficulties =
      this.props.guild.kind === 'live'
        ? ['Mythic', 'Heroic', 'Normal', 'LFR']
        : ['', 'Heroic', 'Normal', '']
    const classes =
      this.props.guild.kind === 'live'
        ? [
            'Priest',
            'Mage',
            'Warlock',
            'Druid',
            'Rogue',
            'Monk',
            'Demon Hunter',
            'Hunter',
            'Shaman',
            'Evoker',
            'Death Knight',
            'Paladin',
            'Warrior',
          ]
        : this.props.guild.kind === 'classic_progression'
        ? [
            'Priest',
            'Mage',
            'Warlock',
            'Druid',
            'Rogue',
            'Hunter',
            'Shaman',
            'Paladin',
            'Warrior',
            'Death Knight',
          ]
        : [
            'Priest',
            'Mage',
            'Warlock',
            'Druid',
            'Rogue',
            'Hunter',
            'Shaman',
            'Paladin',
            'Warrior',
          ]

    const buffs = {
      0: '5% Intellect',
      1: '5% Attack Power',
      2: '5% Stamina',
      17: '3% Versatility',
      4: '5% Physical Damage',
      5: '5% Magic Damage',
      16: 'Devotion Aura',
      19: '3.6% Damage Reduction',
      20: "Hunter's Mark",
      21: 'Skyfury',
    }

    const utility = {
      6: 'Bloodlust',
      7: 'Combat Resurrection',
      8: 'Movement Speed',
      9: 'Healthstone',
      10: 'Gateway',
      11: 'Innervate',
      12: 'Anti Magic Zone',
      13: 'Blessing of Protection',
      14: 'Rallying Cry',
      15: 'Darkness',
      18: 'Immunity',
    }

    const guestsEnabled = useSetting(this.props, 'raids_allow_guests')

    const unknownSignups =
      this.state.selectedSignups?.filter(
        (signup) => signup.status === 'unknown',
      ) || []

    if (this.state.backToCalendar) {
      return (
        <Redirect
          push
          to={{
            pathname: `${this.props.guild.selectedTeam.path}/raids`,
            state: { selectedDate: this.state.selectedDate },
          }}
        />
      )
    } else {
      return (
        <Spin
          id='raid-loading'
          spinning={!this.state.raid}
          size='large'
          tip='Loading raid...'
        >
          <div className='raid-page'>
            {this.state.raid && (
              <React.Fragment>
                <h1>
                  <div className='title-caption'>
                    Raids - Team {this.props.guild.selectedTeam.name}
                  </div>
                  <Icon type='schedule' />
                  {moment(this.state.raid.date).format('MMMM D')} -{' '}
                  {this.state.raid.instance.name}
                </h1>

                <div className='raid-page-container'>
                  <div className='details-container'>
                    <div className='navigation-bar'>
                      <a href='?previous_raid=true'>
                        <React.Fragment>
                          <Icon type='left' />
                          Previous
                        </React.Fragment>
                      </a>

                      <a
                        className='back-to-calendar'
                        onClick={this.goToCalendar}
                      >
                        <span className='wide'>Back to </span>Calendar
                      </a>

                      <a href='?next_raid=true'>
                        <React.Fragment>
                          Next
                          <Icon type='right' />
                        </React.Fragment>
                      </a>
                    </div>

                    {this.props.user.accessLevel == 0 && (
                      <div className='edit-bar'>
                        <SingleRaidSettings
                          instances={this.state.instances}
                          raid={this.state.raid}
                          updateRaid={this.updateRaidDay}
                          kind={this.props.guild.kind}
                          active={true}
                          showLabels={true}
                          fromSettings={false}
                        />
                      </div>
                    )}

                    {this.props.user.accessLevel > 0 &&
                      !this.props.user.loggedIn && (
                        <div className='edit-bar'>
                          <span className='notification'>
                            You have to{' '}
                            <a
                              href={`/auth/bnet?origin=${encodeURIComponent(
                                this.props.match.url,
                              )}&region=${this.props.guild.region}`}
                            >
                              log in{' '}
                            </a>
                            if you wish to change your signup status.
                          </span>
                        </div>
                      )}
                    {this.props.user.loggedIn &&
                    (this.characterForRaid() ||
                      guestsEnabled !== 'disabled') ? (
                      <div className='edit-bar'>
                        {this.props.user.accessLevel > 0 && (
                          <React.Fragment>
                            <div className='field'>
                              <label>Start</label>
                              <span className='text-info'>
                                {this.state.raid.start_time}
                              </span>
                            </div>

                            <div className='field'>
                              <label>End</label>
                              <span className='text-info'>
                                {this.state.raid.end_time}
                              </span>
                            </div>

                            {this.props.guild.kind === 'live' ||
                            this.props.guild.kind === 'classic_progression' ? (
                              <div className='field'>
                                <label>Difficulty</label>
                                <span className='text-info'>
                                  {difficulties[this.state.raid.difficulty]}
                                </span>
                              </div>
                            ) : null}
                          </React.Fragment>
                        )}

                        {!this.characterForRaid() ? (
                          <React.Fragment>
                            <div
                              className={`field static character-select ${
                                this.state.raid.status === 'Planned'
                                  ? 'enabled'
                                  : 'disabled'
                              }`}
                            >
                              <label>
                                Character{' '}
                                <Tooltip
                                  title={`${
                                    guestsEnabled ===
                                    'only_with_characters_in_team'
                                      ? 'Your team requires characters to be part of the roster. '
                                      : ''
                                  }If the character you expect is not in the list, visit your profile to update your characters.`}
                                  placement='top'
                                >
                                  <Icon type='question-circle' />
                                </Tooltip>
                              </label>
                              <Select
                                value={this.state.selectedCharacter?.key}
                                allowClear
                                filterOption={(input, option) =>
                                  option.key.includes(input.toLowerCase())
                                }
                                showSearch
                                placeholder='Character'
                                dropdownRender={(dropdown) => (
                                  <div>
                                    {dropdown}
                                    {guestsEnabled === 'with_any_character' && (
                                      <li
                                        className='ant-select-dropdown-menu-item add-entity lower'
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={() =>
                                          this.setState({
                                            manualCharacterModalOpen: true,
                                          })
                                        }
                                      >
                                        <span>
                                          <Icon type='search' /> Add manually
                                        </span>
                                      </li>
                                    )}
                                  </div>
                                )}
                                onChange={(value) =>
                                  this.setState({
                                    selectedCharacter: this.userCharacters(
                                      guestsEnabled,
                                    ).find((ch) => ch.key === value),
                                  })
                                }
                              >
                                {this.userCharacters(guestsEnabled).map(
                                  (character) => (
                                    <Select.Option
                                      key={`${character.name}-${character.key}`.toLowerCase()}
                                      value={character.key}
                                      className='lower'
                                    >
                                      <div className='add-guest-option'>
                                        <Image
                                          name={`${(
                                            character.class_name || ''
                                          ).replace(' ', '')}-2`}
                                        />
                                        <div>
                                          <span>{character.name}</span>
                                          <span className='realm'>
                                            {character.realm_name}
                                          </span>
                                        </div>
                                      </div>
                                    </Select.Option>
                                  ),
                                )}
                              </Select>

                              <AddManualModal
                                raid={this.state.raid}
                                guild={this.props.guild}
                                addManualCharacter={this.addManualCharacter}
                                open={this.state.manualCharacterModalOpen}
                                setOpen={(value) =>
                                  this.setState({
                                    manualCharacterModalOpen: value,
                                  })
                                }
                              />
                            </div>

                            <div
                              className={`field static role-select ${
                                this.state.raid.status === 'Planned'
                                  ? 'enabled'
                                  : 'disabled'
                              }`}
                            >
                              <label>Role</label>
                              <Select
                                placeholder='Role'
                                value={this.state.customRole}
                                onChange={(value) =>
                                  this.setState({ customRole: value })
                                }
                              >
                                {['Melee', 'Ranged', 'Heal', 'Tank'].map(
                                  (role, index) => (
                                    <Select.Option key={role} value={role}>
                                      {role}
                                    </Select.Option>
                                  ),
                                )}
                              </Select>
                            </div>
                          </React.Fragment>
                        ) : null}

                        <div
                          className={`field static ${
                            this.state.raid.status === 'Planned'
                              ? 'enabled'
                              : 'disabled'
                          }`}
                        >
                          <label>Presence</label>
                          <Select
                            placeholder='Presence'
                            value={this.state.characterPresence || 'present'}
                            optionFilterProp='children'
                            onChange={(value) =>
                              this.handleInputChange('characterPresence', value)
                            }
                          >
                            {statuses.map((status, index) => (
                              <Select.Option key={index} value={status}>
                                {this.signupStatus(status)}{' '}
                                <span>{status}</span>
                              </Select.Option>
                            ))}
                          </Select>
                        </div>

                        <div
                          className={`field grow ${
                            this.state.raid.status === 'Planned'
                              ? 'enabled'
                              : 'disabled'
                          }`}
                        >
                          <label>Comment</label>
                          <Input
                            placeholder='Comment'
                            value={this.state.characterComment}
                            onChange={(event) =>
                              this.handleInputChange(
                                'characterComment',
                                event.target.value,
                              )
                            }
                          />
                        </div>

                        <div
                          className={`field ${
                            this.state.raid.status === 'Planned'
                              ? 'enabled'
                              : 'disabled'
                          }`}
                        >
                          <label />
                          <Tooltip
                            title='Select a character and role first'
                            placement='left'
                            mouseEnterDelay={
                              !this.characterForRaid() &&
                              (!this.state.selectedCharacter ||
                                !this.state.customRole)
                                ? 0
                                : 30
                            }
                          >
                            <Button
                              loading={this.state.presenceLoading}
                              disabled={
                                !this.characterForRaid() &&
                                (!this.state.selectedCharacter ||
                                  !this.state.customRole)
                              }
                              onClick={this.savePresenceChanges}
                            >
                              Save
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    ) : this.props.user.loggedIn ? (
                      <div className='edit-bar'>
                        <p>
                          You don&apos;t own any characters on this team&apos;s
                          roster, so you cannot sign up. If this is incorrect,
                          please visit your{' '}
                          <Link
                            to={`${this.props.guild.selectedTeam.path}/profile`}
                          >
                            profile
                          </Link>{' '}
                          and update your list of characters.
                        </p>
                      </div>
                    ) : null}

                    <Spin
                      spinning={this.state.raidUpdating}
                      wrapperClassName={`raid ${this.state.raid.status}`}
                    >
                      <div
                        className={`selection-container draggable-container ${
                          this.state.dragging ? 'dragging' : ''
                        }`}
                      >
                        <DndContext
                          onDragEnd={this.handleDragEnd}
                          onDragStart={this.handleDragStart}
                          modifiers={[restrictToWindowEdges]}
                        >
                          <EncounterSelection
                            guild={this.props.guild}
                            user={this.props.user}
                            encounterOrder={this.state.encounter_order}
                            hasBossSelections={this.hasBossSelections}
                            changeEncounterScope={this.changeEncounterScope}
                            selectedEncounter={this.state.selectedEncounter}
                            selectedCounter={this.selectedCounter}
                            activeEncounterCheckboxes={
                              this.state.activeEncounterCheckboxes
                            }
                            selectedForEncounter={this.selectedForEncounter}
                            toggleEncounterSelections={
                              this.toggleEncounterSelections
                            }
                            refreshRaid={this.refresh}
                            raid={this.state.raid}
                          />
                        </DndContext>

                        <div
                          className={`character-selection ${
                            this.hasBossSelections(this.state.selectedEncounter)
                              ? 'active'
                              : this.state.selectedEncounter
                              ? 'inactive'
                              : ''
                          }`}
                        >
                          <h2>
                            {this.state.selectedEncounter &&
                              this.state.selectedEncounter.difficulty !==
                                this.state.raid.difficulty && (
                                <CustomDifficultyTooltip
                                  encounter={this.state.selectedEncounter}
                                  raid={this.state.raid}
                                />
                              )}
                            {this.state.selectedEncounter
                              ? this.state.selectedEncounter.name
                              : 'All encounters'}
                            <span className='selected-counter'>
                              {this.selectedCounter(true)}
                            </span>

                            <span className='invite-string-link'>
                              {this.props.user.accessLevel == 0 && (
                                <React.Fragment>
                                  <Button
                                    type='ghost'
                                    size='small'
                                    className='import-selections'
                                    onClick={() =>
                                      this.setState({ showCopyModal: true })
                                    }
                                  >
                                    Import
                                  </Button>
                                  <CopyRaidPlanningModal
                                    open={!!this.state.showCopyModal}
                                    guildId={this.props.guild.id}
                                    teamId={this.props.guild.selectedTeam.id}
                                    encounter={this.state.selectedEncounter}
                                    raid={this.state.raid}
                                    onComplete={this.copyRaidDetails}
                                  />

                                  <Button
                                    type='ghost'
                                    size='small'
                                    className='show-planning-details'
                                    onClick={this.togglePlanningDetails}
                                  >
                                    {this.state.showPlanningDetails
                                      ? 'Hide'
                                      : 'View'}{' '}
                                    planning info
                                  </Button>
                                </React.Fragment>
                              )}

                              <Tooltip
                                title={
                                  this.state.inviteStringCopied
                                    ? 'Copied!'
                                    : 'Copy invite string'
                                }
                                placement='top'
                              >
                                <Icon
                                  type='copy'
                                  onClick={this.copySelectionsForAddon}
                                />
                              </Tooltip>
                              <Tooltip title='Download add-on' placement='top'>
                                <a
                                  className='addon-download'
                                  href='https://www.curseforge.com/wow/addons/wowaudit-invite-tool'
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  <Icon type='link' />
                                </a>
                              </Tooltip>
                            </span>
                          </h2>
                          <div className='roles'>
                            {[['Tank', 'Heal'], ['Melee'], ['Ranged']].map(
                              (roles, _) => (
                                <CharacterSet
                                  key={roles}
                                  signups={this.state.selectedSignups.filter(
                                    (signup) =>
                                      roles.includes(signup.role) &&
                                      signup.status !== 'unknown',
                                  )}
                                  roles={roles}
                                  user={this.props.user}
                                  signupStatus={this.signupStatus}
                                  showPlanningDetails={
                                    this.state.showPlanningDetails
                                  }
                                  interactable={
                                    (this.hasBossSelections(
                                      this.state.selectedEncounter,
                                    ) ||
                                      !this.state.selectedEncounter) &&
                                    this.props.user.accessLevel == 0
                                  }
                                  selectionCount={this.state.selectionCount}
                                  selectedEncounter={
                                    this.state.selectedEncounter
                                  }
                                  refreshRaid={this.refresh}
                                  updateCharacter={this.updateSelections}
                                  planningInfo={this.state.planningInfo}
                                  raid={this.state.raid}
                                  guild={this.props.guild}
                                />
                              ),
                            )}
                          </div>
                          <div className='roles'>
                            {unknownSignups.length &&
                            this.props.user.accessLevel == 0 ? (
                              <CharacterSet
                                signups={unknownSignups}
                                roles={['Unknown']}
                                signupStatus={this.signupStatus}
                                showPlanningDetails={
                                  this.state.showPlanningDetails
                                }
                                interactable={
                                  (this.hasBossSelections(
                                    this.state.selectedEncounter,
                                  ) ||
                                    !this.state.selectedEncounter) &&
                                  this.props.user.accessLevel == 0
                                }
                                selectionCount={this.state.selectionCount}
                                selectedEncounter={this.state.selectedEncounter}
                                updateCharacter={this.updateSelections}
                                planningInfo={this.state.planningInfo}
                                raid={this.state.raid}
                                user={this.props.user}
                                guild={this.props.guild}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Spin>

                    <Strategy
                      user={this.props.user}
                      guild={this.props.guild}
                      raid={this.state.raid}
                      encounter={this.state.selectedEncounter}
                      refreshRaid={this.refresh}
                      notes={
                        this.state.selectedEncounter
                          ? this.state.selectedEncounter.notes
                          : this.state.raid.notes
                      }
                    />
                  </div>

                  <div className='buff-selection'>
                    <h2>Checklist</h2>
                    <div className='checklist-container'>
                      <div className='checklist'>
                        <h3>Classes</h3>
                        <div className='classes'>
                          {classes.map((className, _) => {
                            let count = this.activeSelections().filter(
                              (signup) => signup.class == className,
                            ).length
                            return (
                              <div
                                key={className}
                                className={`checktype ${
                                  count == 0 ? 'empty' : ''
                                }`}
                              >
                                <span className='frequency'>{count}</span>
                                <span className='name'>
                                  <Image
                                    name={`${className.replace(' ', '')}-2`}
                                  />
                                  {className}
                                </span>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      {this.props.guild.kind === 'live' && (
                        <div className='checklist'>
                          <h3>Buffs / Debuffs</h3>
                          <div className='buffs-debuffs'>
                            {Object.keys(buffs).map((buffId, _) => {
                              let count = this.activeSelections().filter(
                                (signup) =>
                                  signup.buffs.includes(parseInt(buffId)),
                              ).length
                              return (
                                <div
                                  key={buffId}
                                  className={`checktype ${
                                    count == 0 ? 'empty' : ''
                                  }`}
                                >
                                  <span className='frequency'>{count}</span>
                                  <span className='name'>{buffs[buffId]}</span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}

                      {this.props.guild.kind === 'live' && (
                        <div className='checklist'>
                          <h3>Utility</h3>
                          <div className='utility'>
                            {Object.keys(utility).map((utilityId, _) => {
                              let count = this.activeSelections().filter(
                                (signup) =>
                                  signup.buffs.includes(parseInt(utilityId)),
                              ).length
                              return (
                                <div
                                  key={utilityId}
                                  className={`checktype ${
                                    count == 0 ? 'empty' : ''
                                  }`}
                                >
                                  <span className='frequency'>{count}</span>
                                  <span className='name'>
                                    {utility[utilityId]}
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </Spin>
      )
    }
  }
}

export default withRouter(RaidPage)
