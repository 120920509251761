import React from 'react'

import { Icon, Slider, Tooltip } from 'antd'

class AccessType extends React.Component {
  setFallbackImage = (event) => {
    event.target.src = 'https://data.wowaudit.com/img/not-found.jpg'
  }

  render() {
    const marks = {
      0: { label: <Tooltip title='Guild leader only'>1</Tooltip> },
      1: { label: <Tooltip title='Rank 2 or higher'>2</Tooltip> },
      2: { label: <Tooltip title='Rank 3 or higher'>3</Tooltip> },
      3: { label: <Tooltip title='Rank 4 or higher'>4</Tooltip> },
      4: { label: <Tooltip title='Rank 5 or higher'>5</Tooltip> },
      5: { label: <Tooltip title='Rank 6 or higher'>6</Tooltip> },
      6: { label: <Tooltip title='Rank 7 or higher'>7</Tooltip> },
      7: { label: <Tooltip title='Rank 8 or higher'>8</Tooltip> },
      8: { label: <Tooltip title='Rank 9 or higher'>9</Tooltip> },
      9: { label: <Tooltip title='Any rank in guild'>10</Tooltip> },
      10: {
        label: (
          <Tooltip title='Not in guild'>
            <Icon type='info-circle' />
          </Tooltip>
        ),
      },
      11: {
        label: (
          <Tooltip title='Not logged in'>
            <Icon type='info-circle' />
          </Tooltip>
        ),
      },
    }

    // Urgh, reversing the slider also reverses the marks...
    const reversedMarks = {
      0: {
        label: (
          <Tooltip title='Not logged in'>
            <Icon type='info-circle' />
          </Tooltip>
        ),
      },
      1: {
        label: (
          <Tooltip title='Not in guild'>
            <Icon type='info-circle' />
          </Tooltip>
        ),
      },
      2: { label: <Tooltip title='Any rank in guild'>10</Tooltip> },
      3: { label: <Tooltip title='Rank 9 or higher'>9</Tooltip> },
      4: { label: <Tooltip title='Rank 8 or higher'>8</Tooltip> },
      5: { label: <Tooltip title='Rank 7 or higher'>7</Tooltip> },
      6: { label: <Tooltip title='Rank 6 or higher'>6</Tooltip> },
      7: { label: <Tooltip title='Rank 5 or higher'>5</Tooltip> },
      8: { label: <Tooltip title='Rank 4 or higher'>4</Tooltip> },
      9: { label: <Tooltip title='Rank 3 or higher'>3</Tooltip> },
      10: { label: <Tooltip title='Rank 2 or higher'>2</Tooltip> },
      11: { label: <Tooltip title='Guild leader only'>1</Tooltip> },
    }

    return (
      <div
        className={`threshold ${this.props.disabled ? 'disabled' : ''} ${
          !!this.props.reverse ? 'reverse' : ''
        }`}
      >
        <Tooltip className='type' title={this.props.description}>
          {this.props.type}
          <Icon type='info-circle' />
        </Tooltip>
        <Slider
          marks={!!this.props.reverse ? reversedMarks : marks}
          min={!!this.props.reverse ? 0 : this.props.user.highestRank}
          max={!!this.props.reverse ? 11 - this.props.user.highestRank : 11}
          tipFormatter={null}
          disabled={this.props.disabled}
          reverse={!!this.props.reverse}
          value={this.props.value}
          onChange={(value) => this.props.handleChange(this.props.type, value)}
        />
      </div>
    )
  }
}

export default AccessType
