import React from 'react'

import Unauthorized from '../containers/Unauthorized'

class ProtectedPage extends React.Component {
  render() {
    const Page = this.props.component

    if (this.props.commonProps.user.accessLevel <= this.props.threshold) {
      return <Page {...this.props.commonProps} />
    }
    return (
      <Unauthorized
        user={this.props.commonProps.user}
        guild={this.props.commonProps.guild}
        region={this.props.commonProps.guild.region}
        threshold={this.props.threshold}
      />
    )
  }
}

export default ProtectedPage
