/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Tooltip } from 'antd'

const CustomDifficultyTooltip = (props) => {
  const custom_difficulties = [
    { value: 'M', label: 'Mythic' },
    { value: 'H', label: 'Heroic' },
    { value: 'N', label: 'Normal' },
    { value: 'R', label: 'LFR' },
  ]

  return (
    <Tooltip
      title={
        <span>
          {custom_difficulties[props.encounter.difficulty].label}{' '}
          {props.encounter.instance_name !== props.raid.instance.name
            ? `(${props.encounter.instance_name})`
            : ''}
        </span>
      }
      className='custom-difficulty'
      placement='top'
    >
      {custom_difficulties[props.encounter.difficulty].value}
    </Tooltip>
  )
}

export default CustomDifficultyTooltip
