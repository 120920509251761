/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, Input, message, Modal, Select } from 'antd'
import { useEffect, useState } from 'react'

import RaidService from '../../services/RaidService'
import RealmService from '../../services/RealmService'

const AddManualModal = (props) => {
  const [loading, setLoading] = useState(false)
  const [realms, setRealms] = useState([])
  const [name, setName] = useState('')
  const [selectedRealm, setSelectedRealm] = useState(props.guild.realm_id)

  useEffect(() => {
    if (props.open && !realms.length) {
      RealmService.fetchByKind(props.guild.kind).then((realms) => {
        setRealms(realms)
      })
    }

    if (!props.open) {
      setName('')
    }
  }, [props.open])

  const addGuest = () => {
    setLoading(true)
    RaidService.addGuest(
      props.guild.id,
      props.guild.selectedTeam.id,
      props.raid.id,
      {
        realm_id: selectedRealm,
        name,
        add_character_only: true,
      },
    )
      .then((result) => {
        props.addManualCharacter(result.data.character)
        setLoading(false)
        setName('')
        props.setOpen(false)
      })
      .catch((result) => {
        if (result.response.status == 401) window.location.reload()
        message.error(
          result.response.data?.message ||
            'Something went wrong trying to add the character. Please try again.',
          3,
        )
        setLoading(false)
      })
  }

  return (
    <div>
      <Modal
        title='Add character manually'
        className='add-guest-modal'
        visible={props.open}
        onOk={addGuest}
        onCancel={() => props.setOpen(false)}
        footer={[
          <Button
            key='back'
            disabled={loading}
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={loading}
            loading={loading}
            onClick={addGuest}
          >
            Add
          </Button>,
        ]}
      >
        <section css={ModalContentStyles}>
          <div className='add-guest-block'>
            <div className='manual-add-info'>
              <Select
                placeholder='Realm'
                value={realms.length == 0 ? null : selectedRealm}
                loading={realms.length == 0}
                showSearch
                onChange={(value) => setSelectedRealm(value)}
                optionFilterProp='children'
              >
                {realms[props.guild.region] &&
                  realms[props.guild.region].map((realm) => (
                    <Select.Option key={realm.id} value={realm.id}>
                      {realm.name}
                    </Select.Option>
                  ))}
              </Select>
              <Input
                placeholder='Name'
                className={!selectedRealm ? 'disabled' : ''}
                value={name}
                disabled={!selectedRealm}
                onChange={(event) => setName(event.target.value)}
                loading={loading ? 'true' : undefined}
              />
            </div>
          </div>
        </section>
      </Modal>
    </div>
  )
}

export default AddManualModal

const ModalContentStyles = css`
  .add-guest-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 2rem;
    width: 100%;

    .ant-select {
      width: 100%;
    }

    .manual-add-info {
      display: flex;
      width: 100%;
      > * {
        flex-basis: 50%;
      }
    }
  }
`
