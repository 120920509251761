import React, { useMemo, useState } from 'react'
import { Button, Icon, Input, Table, Tooltip } from 'antd'
import { keyBy, uniqBy, sortBy } from 'lodash'
import moment from 'moment'

import Image from './Image'
import { specIcon } from '../helpers'
import TeamService from '../services/TeamService'

const LootHistoryTable = (props) => {
  const [sortingBy, setSortingBy] = useState({})
  const [selectedRows, setSelectedRows] = useState([])
  const [pageSize, setPageSize] = useState(25)
  const [loading, setLoading] = useState()
  const [showIgnoredItems, setShowIgnoredItems] = useState(
    !localStorage.getItem('hidingIgnoredItems'),
  )

  const toggleIgnoreItems = () => {
    if (showIgnoredItems) {
      localStorage.setItem('hidingIgnoredItems', 'true')
      setShowIgnoredItems(false)
    } else {
      localStorage.removeItem('hidingIgnoredItems')
      setShowIgnoredItems(true)
    }
  }

  const charactersById = useMemo(() => {
    return keyBy(props.characters, 'id')
  }, [props.characters])

  const handleTableChange = (pagination, _filters, sorter) => {
    setPageSize(pagination.pageSize)
    setSortingBy(sorter)
  }

  const changeRowStatus = (status) => {
    setLoading(status)
    TeamService.changeLootHistoryStatus(
      props.guild.id,
      props.guild.selectedTeam.id,
      selectedRows,
      status,
    )
      .then(async () => {
        setSelectedRows([])
        await props.refreshData()
      })
      .catch((error) => {
        if (error.response.status == 401) window.location.reload()
        message.error(
          `Couldn't change the status of the history items. ${error.response.data.error}`,
          3,
        )
      })
      .then(() => setLoading(undefined))
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: function filterDropdown({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) {
      return (
        <div className='antd-table-search-box'>
          <Input
            id={`${dataIndex}-search-input`}
            placeholder={`Search ${dataIndex}`}
            autoComplete='off'
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
          />
          <Button
            type='primary'
            onClick={() => confirm()}
            icon='search'
            size='small'
          >
            Search
          </Button>
          <Button onClick={() => clearFilters()} size='small'>
            Reset
          </Button>
        </div>
      )
    },
    filterIcon: <Icon type='search' />,
    onFilter: (value, record) => {
      if (dataIndex === 'character') {
        const character = charactersById[record.character_id]
        return (
          character &&
          character.name.toLowerCase().includes(value.toLowerCase())
        )
      } else {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() =>
          document.getElementById(`${dataIndex}-search-input`).focus(),
        )
      }
    },
  })

  const filteredItems = useMemo(() => {
    return props.items.filter((item) => {
      return showIgnoredItems || !item.discarded
    })
  }, [props.items, showIgnoredItems])

  const columns = useMemo(() => {
    return [
      {
        title: 'Character',
        dataIndex: 'character_id',
        key: 'character_id',
        sortOrder: sortingBy.columnKey === 'character_id' && sortingBy.order,
        sorter: (a, b) => {
          const characterA = charactersById[a.character_id]
          const characterB = charactersById[b.character_id]
          return characterA.name.localeCompare(characterB.name)
        },
        render: function renderName(_, record) {
          const character = charactersById[record.character_id]
          if (character) {
            return (
              <a
                className='roster-name'
                href={character.armoryLink}
                target='_blank'
                rel='noreferrer'
              >
                <Image name={character.role} />
                <Image
                  name={`${
                    character.class && character.class.replace(' ', '')
                  }-2`}
                />
                <span>{character.name}</span>
              </a>
            )
          } else {
            return null
          }
        },
        ...getColumnSearchProps('character'),
      },
      {
        title: 'New item',
        dataIndex: 'name',
        key: 'name',
        className: 'item',
        render: function renderItem(_, record) {
          return (
            <a
              href={`https://wowhead.com/item=${
                record.item_id
              }&bonus=${record.bonus_ids.join(':')}`}
              target='_blank'
              rel='noreferrer'
              className={record.quality}
            >
              <figure>
                <img
                  src={`https://render.worldofwarcraft.com/us/icons/36/${
                    record.icon
                  }${record.icon?.includes('.jpg') ? '' : '.jpg'}`}
                />
              </figure>
              {record.name}
            </a>
          )
        },
        ...getColumnSearchProps('name'),
      },
      {
        title: 'Slot',
        dataIndex: 'display_slot',
        key: 'display_slot',
        render: function renderSlot(_, record) {
          return record.display_slot.replace('_', ' ')
        },
        filters: sortBy(
          uniqBy(filteredItems, (item) => item.display_slot).map((item) => {
            return {
              text: item.display_slot.replace('_', ' '),
              value: item.display_slot,
            }
          }),
          'text',
        ),
        onFilter: (value, record) => record.display_slot.indexOf(value) === 0,
      },
      {
        title: 'Response',
        dataIndex: 'response',
        key: 'response',
        className: 'response',
        render: function renderResponse(_, record) {
          return (
            <span>
              {record.note && (
                <span className='comment'>
                  <Tooltip
                    title={record.note}
                    placement='left'
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                  >
                    <Icon type='message' />
                  </Tooltip>{' '}
                </span>
              )}
              {record.response}
            </span>
          )
        },
        filters: sortBy(
          uniqBy(filteredItems, (item) => item.response).map((item) => {
            return {
              text: item.response,
              value: item.response,
            }
          }),
          'text',
        ),
        onFilter: (value, record) => record.response.indexOf(value) === 0,
      },
      {
        title: 'Old',
        dataIndex: 'old_items',
        key: 'old_items',
        render: function renderOldItems(_, record) {
          return record.old_items.map((item, i) => (
            <React.Fragment key={i}>
              <a
                href={`https://wowhead.com/item=${
                  item.item_id
                }&bonus=${item.bonus_ids.join(':')}`}
                target='_blank'
                rel='noreferrer'
              >
                <Icon type='link' />
              </a>{' '}
            </React.Fragment>
          ))
        },
      },
      {
        title: (
          <Tooltip
            title={
              <span>
                <p>
                  Displays the amount of other characters that had the same
                  response.
                </p>
                <div style={{ opacity: 0.5 }}>
                  This is only available for items that are awarded when the
                  wowaudit addon is enabled.
                </div>
              </span>
            }
            placement='left'
            overlayStyle={{ whiteSpace: 'pre-line' }}
          >
            <Icon type='info-circle' />
          </Tooltip>
        ),
        dataIndex: 'same_response_amount',
        key: 'same_response_amount',
      },
      {
        title: (
          <span>
            Wishes{' '}
            <Tooltip
              title={
                <span>
                  <p>
                    Displays the wishes that were shown in-game during the loot
                    session.
                  </p>
                  <div style={{ opacity: 0.5 }}>
                    This is only available for items that are awarded when the
                    wowaudit addon is enabled.
                  </div>
                </span>
              }
              placement='left'
              overlayStyle={{ whiteSpace: 'pre-line' }}
            >
              <Icon type='info-circle' />
            </Tooltip>
          </span>
        ),
        dataIndex: 'wish_data',
        key: 'wish_data',
        className: 'wish-data',
        render: function renderWishData(_, record) {
          return record.wish_data.map((wish, i) => (
            <div key={i}>
              {specIcon(wish.spec_icon, wish.spec_name)} {wish.value}
            </div>
          ))
        },
      },
      {
        title: 'Awarded by',
        dataIndex: 'awarded_by_id',
        key: 'awarded_by_id',
        sortOrder: sortingBy.columnKey === 'awarded_by_id' && sortingBy.order,
        sorter: (a, b) => {
          const characterA = charactersById[a.awarded_by_id]
          const characterB = charactersById[b.awarded_by_id]
          return (characterA?.name || '').localeCompare(characterB?.name || '')
        },
        render: function renderAwardedBy(_, record) {
          const character = charactersById[record.awarded_by_id]
          if (character) {
            return (
              <a
                className='roster-name'
                href={character.armoryLink}
                target='_blank'
                rel='noreferrer'
              >
                <Image
                  name={`${
                    character.class && character.class.replace(' ', '')
                  }-2`}
                />
                <span>{character.name}</span>
              </a>
            )
          } else if (record.awarded_by_name) {
            const [name, realm] = record.awarded_by_name.split('-')
            return (
              <a
                className='roster-name'
                href={props.guild.armory_base_url
                  .replace('[realm]', realm)
                  .replace('[character]', name)}
                target='_blank'
                rel='noreferrer'
              >
                <Image name={`Unknown-2`} />
                <span>{name}</span>
              </a>
            )
          }
        },
      },
      {
        title: 'Date',
        dataIndex: 'awarded_at',
        key: 'awarded_at',
        defaultSortOrder: 'descend',
        sortOrder: sortingBy.columnKey === 'awarded_at' && sortingBy.order,
        sorter: (a, b) => a.awarded_at.localeCompare(b.awarded_at),
        render: function renderDate(_, record) {
          return moment(record.awarded_at).format('MMM D')
        },
      },
    ]
  }, [charactersById, filteredItems, sortingBy])

  const rowSelection = {
    selectedRows,
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys)
    },
    selectedRowKeys: selectedRows,
    hideDefaultSelections: true,
    columnWidth: '15px',
    selections: [],
  }

  return (
    <div>
      <div className='table-action-bar'>
        <div
          className={`table-action-block ${
            selectedRows.length === 0 ? 'inactive' : ''
          }`}
        >
          <span>
            {selectedRows.length} item{selectedRows.length !== 1 ? 's' : ''}{' '}
            selected,
          </span>
          <Button
            size='small'
            loading={loading === 'discard'}
            onClick={() => changeRowStatus('discard')}
          >
            ignore
          </Button>
          <span>or</span>
          <Button
            size='small'
            loading={loading === 'enable'}
            onClick={() => changeRowStatus('enable')}
          >
            enable
          </Button>
          for statistics
        </div>

        <Button type='ghost' size='small' onClick={toggleIgnoreItems}>
          {showIgnoredItems ? 'Hide' : 'Show'} ignored items
        </Button>
      </div>

      <Table
        id='loot-history-table'
        size='small'
        scroll={{ x: 'max-content' }}
        loading={props.loading}
        rowKey='id'
        onChange={handleTableChange}
        rowClassName={(record) => (record.discarded ? 'discarded' : '')}
        rowSelection={rowSelection}
        pagination={{
          showSizeChanger: true,
          pageSize: pageSize,
          pageSizeOptions: ['25', '50', '100', '500'],
        }}
        columns={columns}
        dataSource={filteredItems}
      />
    </div>
  )
}

export default LootHistoryTable
