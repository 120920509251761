import React from 'react'
import moment from 'moment'

import { Button, Icon, Tooltip, Popover, Select, Spin } from 'antd'

import AddGuestModal from './AddGuestModal'

import Image from '../Image'

import RaidService from '../../services/RaidService'

class CharacterSet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: '',
      visible: null,
      loadingDeleteGuest: false,
    }
  }

  hasWishes = (wishlist) => {
    return wishlist
      ? !!wishlist.percentage || !!wishlist.manual_upgrades.length
      : false
  }

  deleteGuest = (signup_id) => {
    this.setState({ loadingDeleteGuest: true })
    RaidService.removeGuest(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      this.props.raid.id,
      signup_id,
    )
      .then((result) => {
        this.props.refreshRaid(result)
        this.setState({ loadingDeleteGuest: false })
      })
      .catch((result) => {
        if (result.response.status == 401) window.location.reload()
        message.error(
          result.response.data?.message ||
            'Something went wrong trying to remove the guest. Please try again.',
          3,
        )
        this.setState({ loadingDeleteGuest: false })
      })
  }

  character = (signup, selected) => {
    let metadata = this.props.planningInfo
      ? this.props.planningInfo[signup.character.id]
      : null
    if (this.props.selectedEncounter && metadata) {
      metadata = metadata.encounters.find(
        (encounter) => encounter.id === this.props.selectedEncounter.id,
      )
    }

    const classes = [
      'Priest',
      'Mage',
      'Warlock',
      'Druid',
      'Rogue',
      'Monk',
      'Demon Hunter',
      'Hunter',
      'Shaman',
      'Evoker',
      'Death Knight',
      'Paladin',
      'Warrior',
    ]

    return (
      <div
        key={`${signup.id}-${signup.character.id}-${signup.encounter_id}`}
        onClick={() =>
          this.props.interactable &&
          signup.status != 'absent' &&
          signup.status != 'missing' &&
          signup.status != 'unknown'
            ? this.props.updateCharacter(signup)
            : null
        }
        className={`character ${signup.status} ${
          signup.selected ? 'selected' : ''
        } ${
          this.props.interactable && signup.status !== 'unknown'
            ? 'interactable'
            : ''
        }`}
      >
        <div className='basic-details'>
          <span
            className={`selection-details ${
              !this.props.selectedEncounter ? 'index' : 'boss'
            }`}
          >
            <Image
              className='primary'
              name={`${(signup.class || '').replace(' ', '')}-2`}
            />
          </span>
          <span className='name'>
            {signup.guest && (
              <Tooltip title='Guest'>
                <Icon type='user' />
              </Tooltip>
            )}
            {signup.character.name}
          </span>
          <span className='comment'>
            {signup.comment && (
              <Tooltip title={signup.comment}>
                <Icon type='message' />
              </Tooltip>
            )}
          </span>
          <span
            className={`status ${
              this.props.interactable ? 'interactable' : ''
            }`}
          >
            <span className='status-icon'>
              {this.props.signupStatus(signup.status)}
            </span>
            {this.props.interactable && (
              <span className='edit-icon'>
                <Popover
                  className='edit-signup'
                  visible={this.state.visible == signup.id}
                  trigger='click'
                  onClick={(event) => event.stopPropagation()}
                  onVisibleChange={() => this.setState({ visible: signup.id })}
                  overlayClassName='signup-popover'
                  content={
                    <div
                      className='edit-signup-tooltip'
                      onClick={(event) => event.stopPropagation()}
                    >
                      <div className='signup-field'>
                        <label>Role</label>
                        <Select
                          placeholder='Role'
                          defaultValue={signup.role}
                          optionFilterProp='children'
                          onChange={(value) =>
                            this.props.updateCharacter(signup, 'role', value)
                          }
                        >
                          {['Tank', 'Heal', 'Ranged', 'Melee'].map(
                            (role, index) => (
                              <Select.Option key={index} value={role}>
                                {role}
                              </Select.Option>
                            ),
                          )}
                        </Select>
                      </div>

                      <div className='signup-field'>
                        <label>Class</label>
                        <Select
                          placeholder='Class'
                          defaultValue={signup.class}
                          optionFilterProp='children'
                          onChange={(value) =>
                            this.props.updateCharacter(signup, 'class', value)
                          }
                        >
                          {classes.map((klass, index) => (
                            <Select.Option key={index + 1} value={klass}>
                              {klass}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>

                      <div className='signup-field'>
                        <label>Status</label>
                        <Select
                          placeholder='Status'
                          defaultValue={signup.status}
                          optionFilterProp='children'
                          onChange={(value) =>
                            this.props.updateCharacter(signup, 'status', value)
                          }
                        >
                          {[
                            'present',
                            'absent',
                            'tentative',
                            'late',
                            'standby',
                            'missing',
                          ].map((status, index) => (
                            <Select.Option key={index} value={status}>
                              {this.props.signupStatus(status)}{' '}
                              <span>{status}</span>
                            </Select.Option>
                          ))}
                        </Select>
                      </div>

                      {signup.guest && (
                        <div className='signup-field'>
                          <Button
                            type='danger'
                            loading={this.state.loadingDeleteGuest}
                            onClick={() => {
                              // signup can be a selection or a signup, so we have to do this ugly check
                              this.deleteGuest(signup.signup_id || signup.id)
                            }}
                          >
                            Remove guest
                          </Button>
                        </div>
                      )}
                    </div>
                  }
                >
                  <Icon type='edit' />
                </Popover>
              </span>
            )}
          </span>
        </div>
        <div
          className={`wishlist-info ${
            this.props.showPlanningDetails ? 'active' : ''
          }`}
        >
          {this.props.showPlanningDetails && (
            <React.Fragment>
              <span className='selected-count'>
                {!!this.props.selectedEncounter && (
                  <Tooltip
                    placement='left'
                    title={`Selected on ${
                      this.props.selectionCount[signup.character.id] || 0
                    } boss${
                      this.props.selectionCount[signup.character.id] === 1
                        ? ''
                        : 'es'
                    }.`}
                  >
                    {this.props.selectionCount[signup.character.id] || 0}
                  </Tooltip>
                )}
              </span>

              {this.props.planningInfo !== undefined &&
                !this.props.selectedEncounter?.extra && (
                  <div className='score wishlist-details'>
                    <span
                      className={
                        metadata?.wishlist?.edited
                          ? 'value edited'
                          : (metadata?.wishlist?.percentage || 0) === 0
                          ? 'value empty'
                          : 'value'
                      }
                    >
                      {this.hasWishes(metadata?.wishlist) ? (
                        <React.Fragment>
                          {metadata?.wishlist.percentage > 0 &&
                            `${metadata?.wishlist.percentage.toFixed(2)}%`}
                          {(metadata?.wishlist?.manual_upgrades || []).map(
                            (upgrade) => (
                              <span
                                key={upgrade}
                                className={`priority ${upgrade}`}
                              />
                            ),
                          )}
                        </React.Fragment>
                      ) : (
                        '-'
                      )}
                    </span>
                    <div
                      className={`wishlist-details${
                        metadata?.wishlist?.alternative ? ' suboptimal' : ''
                      }`}
                    >
                      <div className='wishlist-details-container'>
                        <div>
                          <div className='messages'>
                            <span>{`Prior to this raid, has been selected ${
                              metadata?.selected_amount || 0
                            } time${
                              metadata?.selected_amount === 1 ? '' : 's'
                            }${
                              !this.props.selectedEncounter
                                ? ''
                                : ' on this boss'
                            }, out of ${metadata?.total_amount || 0} total${
                              metadata?.last_selected_date
                                ? ` (last ${moment(
                                    metadata?.last_selected_date,
                                  ).format('MMM D')})`
                                : ''
                            }.`}</span>
                          </div>

                          {metadata?.wishlist?.items?.length ? (
                            <table>
                              <thead>
                                <tr>
                                  <th />
                                  <th>Upgrade</th>
                                  <th className='age'>
                                    <Tooltip
                                      placement='top'
                                      title='Age in days'
                                    >
                                      <Icon type='clock-circle' />
                                    </Tooltip>
                                  </th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {metadata?.wishlist.items.map((wish) => (
                                  <tr key={wish.blizzard_id}>
                                    <td>
                                      <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href={`https://wowhead.com/item=${wish.blizzard_id}?ilvl=${wish.item_level}`}
                                      >
                                        <figure>
                                          <img
                                            src={`https://render.worldofwarcraft.com/us/icons/36/${wish.icon}.jpg`}
                                          />
                                        </figure>
                                      </a>
                                    </td>
                                    <td>
                                      {wish.percentage ? (
                                        `${wish.percentage.toFixed(2)}%`
                                      ) : (
                                        <span
                                          className={`priority ${wish.manual_upgrade}`}
                                        />
                                      )}
                                    </td>
                                    <td
                                      className={`age ${
                                        wish.age > 21
                                          ? 'month'
                                          : wish.age > 7
                                          ? 'week'
                                          : 'day'
                                      }`}
                                    >
                                      {wish.age}
                                    </td>
                                    <td className='actions'>
                                      {wish.comment && (
                                        <Tooltip
                                          title={wish.comment}
                                          placement='top'
                                          overlayStyle={{
                                            whiteSpace: 'pre-line',
                                          }}
                                        >
                                          <Icon type='message' />
                                        </Tooltip>
                                      )}
                                      {wish.alternative && (
                                        <Tooltip
                                          title={
                                            <span>
                                              {' '}
                                              There is a{' '}
                                              {wish.alternative.percentage.toFixed(
                                                2,
                                              )}
                                              %{' '}
                                              <a
                                                target='_blank'
                                                rel='noreferrer'
                                                href={`https://wowhead.com/item=${wish.alternative.item}?ilvl=${wish.alternative.item_level}`}
                                              >
                                                upgrade
                                              </a>{' '}
                                              that is better in this slot than
                                              this item.
                                            </span>
                                          }
                                          placement='top'
                                        >
                                          <Icon type='warning' />
                                        </Tooltip>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              <div className='selected-info'>
                <span className='value'>
                  {this.props.planningInfo === undefined ? (
                    <Spin
                      id='planning-info-loading'
                      spinning={true}
                      size='small'
                    />
                  ) : metadata?.selected_percentage === null ||
                    metadata?.selected_percentage === undefined ? (
                    `-`
                  ) : (
                    `${(
                      (((metadata?.selected_amount || 0) + (selected ? 1 : 0)) /
                        (metadata?.total_amount + 1 || 1)) *
                      100.0
                    ).toFixed(0)}%`
                  )}
                </span>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className={`role-group${
          this.props.roles[0] === 'Unknown' ? ' wide' : ''
        }`}
        key={this.props.roles}
      >
        {this.props.roles.map((role, _) => {
          let selected = this.props.signups.filter(
            (s) => s.selected && s.role == role,
          )
          let queued = this.props.signups.filter(
            (s) => !s.selected && s.role == role,
          )
          return (
            <div className='role' key={role}>
              <h3>
                <Image name={role} />
                {role}
                <span className='counter'>
                  {role === 'Unknown'
                    ? this.props.signups.length
                    : selected.length}
                </span>
              </h3>
              {role !== 'Unknown' &&
              (selected.length > 0 || this.props.user.accessLevel == 0) ? (
                <React.Fragment>
                  <h5>
                    Selected{' '}
                    {this.props.user.accessLevel == 0 && (
                      <AddGuestModal
                        role={role}
                        raid={this.props.raid}
                        guild={this.props.guild}
                        refreshRaid={this.props.refreshRaid}
                      />
                    )}
                  </h5>
                  <div className='characters'>
                    {selected.map((signup, _) => this.character(signup, true))}
                  </div>
                </React.Fragment>
              ) : null}

              {queued.length > 0 && (
                <React.Fragment>
                  <h5 className='queued'>Queued</h5>
                  <div className='characters'>
                    {queued.map((signup, _) => this.character(signup, false))}
                  </div>
                </React.Fragment>
              )}

              {role === 'Unknown' && (
                <React.Fragment>
                  <h5 className='queued'>Unknown</h5>
                  <div className='characters wide'>
                    {this.props.signups.map((signup, _) =>
                      this.character(signup),
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

export default CharacterSet
