import React from 'react'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import { Button, Checkbox, Icon, message } from 'antd'

import RaidService from '../../services/RaidService'

class Strategy extends React.Component {
  state = {
    editing: false,
  }

  componentDidMount() {
    this.setEncounterState()
  }

  componentDidUpdate() {
    // TODO: Ugh
    if (this.state.encounter !== this.props.encounter) {
      this.setEncounterState()
    }
  }

  setEncounterState = () => {
    const content = htmlToDraft(this.props.notes?.content || '')

    this.setState({
      editing: false,
      encounter: this.props.encounter,
      content: EditorState.createWithContent(
        ContentState.createFromBlockArray(content.contentBlocks),
      ),
      remember: this.props.notes?.remember || true,
    })
  }

  currentContent = () => {
    if (this.state.content) {
      return draftToHtml(convertToRaw(this.state.content?.getCurrentContent()))
    }
  }

  saveChanges = () => {
    this.setState({ savingChanges: true })

    RaidService.updateRaidNotes(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      this.props.raid.id,
      this.props.encounter?.id,
      this.currentContent(),
      this.state.remember,
    )
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          'Something went wrong saving your changes. Please try again.',
          3,
        )
      })
      .then((result) => {
        this.setState({ savingChanges: false, editing: false }, () =>
          this.props.refreshRaid(result),
        )
      })
  }

  render() {
    return (
      <div className='strategy-container'>
        <h2>
          Strategy / Notes
          {this.props.user.accessLevel == 0 && !this.state.editing && (
            <Icon
              type='edit'
              onClick={() => this.setState({ editing: true })}
            />
          )}
        </h2>

        {this.state.editing && (
          <React.Fragment>
            <Editor
              editorState={this.state.content}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'list',
                  'colorPicker',
                  'link',
                  'embedded',
                  'emoji',
                  'image',
                  'history',
                ],
                inline: {
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
                blockType: {
                  inDropdown: false,
                  options: ['Normal', 'H1', 'H2', 'H3'],
                },
                colorPicker: {
                  colors: [
                    'rgba(255, 255, 255, 0.65)',
                    'rgba(0, 0, 0, 0.65)',
                    '#C41F3B',
                    '#A330C9',
                    '#FF7D0A',
                    '#A9D271',
                    '#40C7EB',
                    '#00FF96',
                    '#F58CBA',
                    '#FFFFFF',
                    '#FFF569',
                    '#0070DE',
                    '#8787ED',
                    '#C79C6E',
                    '#a335ee',
                    '#e6cc80',
                    '#1eff00',
                    '#399652',
                    '#ff7c7c',
                  ],
                },
                list: { options: ['unordered', 'ordered'] },
              }}
              toolbarClassName='toolbar'
              wrapperClassName='wysiwyg-editor'
              editorClassName='editor-content'
              onEditorStateChange={(content) => this.setState({ content })}
            />
            <div className='actions'>
              <Checkbox
                checked={this.state.remember}
                onChange={() =>
                  this.setState((prevState, _) => {
                    return { remember: !prevState.remember }
                  })
                }
              >
                Default for encounter
              </Checkbox>
              <div>
                <Button
                  disabled={this.state.savingChanges}
                  onClick={() => this.setState({ editing: false })}
                >
                  Cancel
                </Button>
                <Button
                  type='primary'
                  loading={this.state.savingChanges}
                  onClick={this.saveChanges}
                >
                  Save changes
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}

        {!this.state.editing && (
          <div
            className='strategy-content'
            dangerouslySetInnerHTML={{ __html: this.currentContent() }}
          />
        )}
      </div>
    )
  }
}

export default Strategy
