import React from 'react'
import { Link } from 'react-router-dom'

import { Table, Button, message, Input, Alert } from 'antd'
import Highlighter from 'react-highlight-words'

import CharacterService from '../../services/CharacterService'

import Image from '../Image'

class BlizzardTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFilters: {},
      activeSorting: {},
      activeSearch: null,
      pendingTracking: [],
      unfilteredCharacters: this.props.characters,
      filteredCharacters: this.props.characters,
    }
  }

  componentDidUpdate() {
    if (
      this.props.characters &&
      this.state.unfilteredCharacters != this.props.characters
    ) {
      this.setState({
        unfilteredCharacters: this.props.characters,
        filteredCharacters: this.props.characters,
      })
    }
  }

  setFallbackImage = (event) => {
    event.target.src = 'https://data.wowaudit.com/img/not-found.jpg'
  }

  handleTableChange = (_, filters, sorter) => {
    this.setState({ activeFilters: filters, activeSorting: sorter })
  }

  handleSearchChange = (event) => {
    this.setState({
      activeSearch: event.target.value,
      filteredCharacters: this.props.characters.filter((character) => {
        return character.name
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      }),
    })
  }

  trackCharacter = (record) => {
    this.setState((prevState, _) => {
      return { pendingTracking: prevState.pendingTracking.concat([record.key]) }
    })
    CharacterService.create(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      record,
    )
      .then((result) => {
        this.setState((prevState, _) => {
          prevState.pendingTracking.splice(
            prevState.pendingTracking.indexOf(record.key),
            1,
          )
          return {
            pendingTracking: prevState.pendingTracking,
          }
        })
        return result
      })
      .then((result) => {
        this.props.refreshGuild(result.data)
        window.scrollBy(0, 37)
      })
      .catch((result) => {
        if (result.response.status == 401) window.location.reload()
        message.error(
          `Couldn't track ${record.name}. ${
            result.response.data.message ? result.response.data.message : ''
          }`,
          10,
        )
        this.setState((prevState, _) => {
          prevState.pendingTracking.splice(
            prevState.pendingTracking.indexOf(record.key),
            1,
          )
          return { pendingTracking: prevState.pendingTracking }
        })
      })
  }

  isRecordTracking = (record) => {
    return this.props.guild.selectedTeam.characters
      .map((char, _) => `${char.realm_id}-${char.name}`)
      .includes(`${record.realm_id}-${record.name}`)
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sortOrder:
          this.state.activeSorting.columnKey === 'name' &&
          this.state.activeSorting.order,
        sorter: (a, b) => a.name.localeCompare(b.name),
        onFilter: (value, record) =>
          record.name.toLowerCase().includes(value.toLowerCase()),
        render: (_, record) => {
          if (this.props.guild.kind == 'live') {
            return (
              <a
                className='roster-name'
                href={record.armoryLink || '#'}
                target='_blank'
                rel='noreferrer'
              >
                {/* <img src={`//render-${this.props.guild.region}.worldofwarcraft.com/character/${record.thumbnail}`} onError={this.setFallbackImage} /> */}
                <span>
                  <Highlighter
                    highlightStyle={{ backgroundColor: '#c9d6cb', padding: 0 }}
                    searchWords={[this.state.activeSearch]}
                    autoEscape
                    textToHighlight={record.name}
                  />
                </span>
              </a>
            )
          } else {
            return (
              <span>
                <Highlighter
                  highlightStyle={{ backgroundColor: '#c9d6cb', padding: 0 }}
                  searchWords={[this.state.activeSearch]}
                  autoEscape
                  textToHighlight={record.name}
                />
              </span>
            )
          }
        },
      },
      {
        title: 'Class',
        dataIndex: 'class',
        key: 'class',
        sortOrder:
          this.state.activeSorting.columnKey === 'class' &&
          this.state.activeSorting.order,
        sorter: (a, b) => a.class.localeCompare(b.class),
        filters: [
          { text: 'Death Knight', value: 'Death Knight' },
          { text: 'Demon Hunter', value: 'Demon Hunter' },
          { text: 'Druid', value: 'Druid' },
          { text: 'Evoker', value: 'Evoker' },
          { text: 'Hunter', value: 'Hunter' },
          { text: 'Mage', value: 'Mage' },
          { text: 'Monk', value: 'Monk' },
          { text: 'Paladin', value: 'Paladin' },
          { text: 'Priest', value: 'Priest' },
          { text: 'Rogue', value: 'Rogue' },
          { text: 'Shaman', value: 'Shaman' },
          { text: 'Warlock', value: 'Warlock' },
          { text: 'Warrior', value: 'Warrior' },
        ],
        filteredValue: this.state.activeFilters.class || null,
        onFilter: (value, record) => record.class.includes(value),
        render: (_, record) => (
          <React.Fragment>
            <Image name={`${record.class.replace(' ', '')}-2`} />
            <span>{record.class}</span>
          </React.Fragment>
        ),
      },
      {
        title: 'Level',
        dataIndex: 'level',
        key: 'level',
        sortOrder:
          this.state.activeSorting.columnKey === 'level' &&
          this.state.activeSorting.order,
        sorter: (a, b) => a.level - b.level,
      },
      {
        title: 'Rank',
        dataIndex: 'rank',
        key: 'rank',
        sortOrder:
          this.state.activeSorting.columnKey === 'rank'
            ? this.state.activeSorting.order
            : 'ascend',
        sorter: (a, b) => a.rank - b.rank,
        render: (_, record) => <span>{record.rank + 1}</span>,
      },
      {
        title: '',
        dataIndex: 'track',
        render: (_, record) => (
          <Button
            type='success'
            className={this.isRecordTracking(record) ? 'tracking' : ''}
            icon={this.isRecordTracking(record) ? 'check' : ''}
            disabled={
              this.isRecordTracking(record) ||
              this.props.guild.selectedTeam.characters.length >=
                this.props.guild.characterLimit
            }
            loading={this.state.pendingTracking.includes(record.key)}
            onClick={() => this.trackCharacter(record)}
          >
            {this.isRecordTracking(record) ? 'tracking' : 'track'}
          </Button>
        ),
      },
    ]

    return (
      <React.Fragment>
        {this.props.guild.selectedTeam.characters.length >=
          this.props.guild.characterLimit && (
          <Alert
            message={
              <React.Fragment>
                Your team&apos;s character limit of{' '}
                {this.props.guild.characterLimit} has been reached. Go to the
                <Link to={`${this.props.guild.selectedTeam.path}/premium`}>
                  {' '}
                  Patreon page
                </Link>{' '}
                to increase it.
              </React.Fragment>
            }
            type='warning'
            showIcon
          />
        )}
        <Input.Search
          placeholder='Search'
          onChange={this.handleSearchChange}
          disabled={!this.props.characters}
        />
        <Table
          id='blizzard-table'
          size='small'
          columns={columns}
          scroll={{ x: 'max-content' }}
          pagination={{ defaultPageSize: 15 }}
          onChange={this.handleTableChange}
          loading={!this.props.characters}
          dataSource={this.state.filteredCharacters}
        />
      </React.Fragment>
    )
  }
}

export default BlizzardTable
