import axios from 'axios'

axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true

class GuildService {
  async fetch(id) {
    return await axios.get(`/api/guilds/${id}`)
  }

  async validate(realm, name) {
    return await axios.post(`/api/guilds`, { realm_id: realm, name: name })
  }

  async touch(id, team_id) {
    return await axios.put(`/api/guilds/${id}/touch`, { team_id })
  }

  async fetchLinkedAccounts(id) {
    return await axios.get(`/api/guilds/${id}/linked_accounts`)
  }

  async removeLinkedAccount(guildId, id) {
    return await axios.delete(`/api/guilds/${guildId}/linked_accounts/${id}`)
  }

  async updateAccess(id, team_id, guild) {
    return await axios.put(`/api/guilds/${id}`, { team_id, guild })
  }
}

export default new GuildService()
