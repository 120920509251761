import React from 'react'

import { Icon, Progress, Table, DatePicker, Select } from 'antd'
const { RangePicker } = DatePicker

import Image from '../../../components/Image'

import TeamService from '../../../services/TeamService'
import InstanceService from '../../../services/InstanceService'

class RaidStatisticsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTeam: this.props.guild.selectedTeam.id,
      loading: true,
      statistics: [],
      activeSorting: {},
    }
  }

  componentDidUpdate() {
    // TODO: Refactor this, improve state management
    if (this.state.currentTeam != this.props.guild.selectedTeam.id) {
      this.setState({
        currentTeam: this.props.guild.selectedTeam.id,
        loading: true,
      })
      this.loadData()
    }
  }

  componentDidMount() {
    InstanceService.fetchByKind(this.props.guild.subkind, true).then(
      (instances) => {
        this.setState({ instances: instances })
        this.loadData(
          this.props.guild.kind === 'live' &&
            instances.filter((i) => i.id === 0).length
            ? { instance: '0' }
            : {},
        )
      },
    )
  }

  loadData = (filters) => {
    TeamService.fetchRaidStatistics(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      filters,
    ).then((statistics) => {
      this.setState({
        statistics: statistics.data.characters,
        loading: false,
      })

      if (!filters || !filters.range) {
        this.setState({
          selectedInstance: this.state.instances.find(
            (i) => i.id == statistics.data.instance,
          ),
          instance: this.state.instances.find(
            (i) => i.id == statistics.data.instance,
          ).id,
        })
      }
    })
  }

  setFilter = (type, value) => {
    if (type == 'instance') {
      this.setState({
        loading: true,
        [type]: value,
      })
    } else {
      this.setState({ loading: true, [type]: value })
    }
    this.loadData({
      instance: this.state.instance || this.state.selectedInstance.id,
      range: this.state.range,
      encounter: this.state.encounter,
      [type]: value,
    })
  }

  setCustomRange = (value) => {
    this.setState({
      loading: true,
      range: value,
    })
    this.loadData({
      instance: this.state.instance || this.state.selectedInstance.id,
      encounter: this.state.encounter,
      range: value,
    })
  }

  handleTableChange = (_, _2, sorter) => {
    this.setState({ activeSorting: sorter })
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sortOrder:
          this.state.activeSorting.columnKey === 'name' &&
          this.state.activeSorting.order,
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, record) => (
          <a
            className='roster-name'
            href={record.armoryLink}
            target='_blank'
            rel='noreferrer'
          >
            <Image name={record.role} />
            <Image
              name={`${record.class && record.class.replace(' ', '')}-2`}
            />
            <span>{record.name}</span>
          </a>
        ),
      },
      {
        title: 'Attended',
        dataIndex: 'attendanceAmount',
        key: 'attendanceAmount',
        render: (_, record) => (
          <span>
            {record.totalAmount > 0
              ? `${record.attendedAmount} / ${record.totalAmount}`
              : '-'}
          </span>
        ),
      },
      {
        title: '%',
        dataIndex: 'attendance',
        key: 'attendance',
        sortOrder:
          this.state.activeSorting.columnKey === 'attendance' &&
          this.state.activeSorting.order,
        sorter: (a, b) => b.attendancePercentage - a.attendancePercentage,
        render: (_, record) => (
          <Progress percent={Math.round(record.attendancePercentage)} />
        ),
      },
      {
        title: 'Selected',
        dataIndex: 'selectedAmount',
        key: 'selectedAmount',
        render: (_, record) => (
          <span>
            {record.bossesTotalAmount > 0
              ? `${record.selectedAmount} / ${record.bossesTotalAmount}`
              : '-'}
          </span>
        ),
      },
      {
        title: '%',
        dataIndex: 'status',
        key: 'selected',
        sortOrder:
          this.state.activeSorting.columnKey === 'selected' &&
          this.state.activeSorting.order,
        sorter: (a, b) => b.selectedPercentage - a.selectedPercentage,
        render: (_, record) => (
          <Progress percent={Math.round(record.selectedPercentage)} />
        ),
      },
    ]

    return (
      <div className='raid-statistics-page'>
        <h1>
          <div className='title-caption'>
            Raids - Team {this.props.guild.selectedTeam.name}
          </div>
          <Icon type='bar-chart' />
          Statistics
        </h1>

        <div className='filter-bar'>
          <div className='field grow'>
            <label>Instance</label>
            <Select
              placeholder='Instance'
              value={this.state.instance}
              loading={!this.state.instances || !this.state.selectedInstance}
              allowClear={true}
              optionFilterProp='children'
              onChange={(value) => this.setFilter('instance', value)}
            >
              {this.state.instances &&
                this.state.instances.map((instance, _) => (
                  <Select.Option
                    key={`inst-${instance.id}`}
                    value={instance.id}
                  >
                    {instance.name}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className='field boss-encounter'>
            <label>Encounter</label>
            <Select
              placeholder='Encounter'
              value={this.state.encounter || ''}
              loading={!this.state.instances || !this.state.selectedInstance}
              allowClear={true}
              optionFilterProp='children'
              onChange={(value) => this.setFilter('encounter', value)}
            >
              <Select.Option key='' value=''>
                All encounters
              </Select.Option>
              {this.state.selectedInstance &&
                this.state.selectedInstance.encounters.map((encounter, _) => (
                  <Select.Option
                    key={`enc-${encounter.id}`}
                    value={encounter.id}
                  >
                    {encounter.name}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className='field'>
            <label>Custom range</label>
            <RangePicker
              value={this.state.range}
              allowClear={true}
              onChange={(value) => this.setCustomRange(value)}
            />
          </div>
        </div>

        <Table
          id='statistics-table'
          size='small'
          scroll={{ x: 'max-content' }}
          loading={this.state.loading}
          rowKey='name'
          onChange={this.handleTableChange}
          pagination={false}
          columns={columns}
          dataSource={this.state.statistics}
        />
      </div>
    )
  }
}

export default RaidStatisticsPage
