import React, { useState } from 'react'
import { Button, Icon, Modal, Tooltip, message } from 'antd'
import { Formik } from 'formik'

import LootHistoryResponseTypes from './LootHistoryResponseTypes'
import TeamService from '../services/TeamService'

const LootHistoryResponseTypeModal = (props) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState()

  const saveChanges = (values) => {
    setLoading(true)
    TeamService.update(props.guild.id, props.guild.selectedTeam.id, {
      loot_history_response_type_order: values.loot_history_response_type_order,
      loot_history_response_types_attributes: values.loot_history_response_types.map(
        (type) => {
          return {
            id: type.id,
            rgba: type.rgba,
            synonym_of_id: type.synonym_of_id || null,
          }
        },
      ),
    })
      .then(async () => {
        await props.refreshData()
        message.success(`Updated response types.`, 2)
      })
      .then(() => setOpen(false))
      .catch((_) => {
        if (_.response.status == 401) window.location.reload()
        message.error(
          `Something went wrong trying to update the response types. Please try again.`,
          3,
        )
      })
      .then(() => setLoading(false))
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)}>Adjust categories</Button>
      <Formik
        initialValues={{
          loot_history_response_types: props.responseTypes,
          loot_history_response_type_order: props.responseTypeOrder,
        }}
        enableReinitialize={true}
        validateOnChange={false} // https://github.com/jaredpalmer/formik/issues/2542
        onSubmit={async (values) => {
          saveChanges(values)
        }}
      >
        {({ submitForm }) => {
          return (
            <Modal
              visible={open}
              title='Response types'
              onOk={() => setOpen(false)}
              className='response-type-modal'
              onCancel={() => setOpen(false)}
              width='60rem'
              footer={[
                <Button key='back' onClick={() => setOpen(false)}>
                  Close
                </Button>,
                <Button
                  key='submit'
                  type='primary'
                  disabled={loading}
                  loading={loading}
                  onClick={submitForm}
                >
                  Save
                </Button>,
              ]}
            >
              <p>
                Response types in RCLootCouncil can vary wildly, based on the
                button configuration of each individual master looter. You can
                combine the different responses into a configuration that suits
                you.
              </p>

              <div className='list-header'>
                <div>Type</div>
                <div>
                  Merged with{' '}
                  <Tooltip
                    title={
                      <span>
                        Merged response types will not be visible in the
                        statistics table anymore. All awarded loot is treated as
                        the type it is merged with.
                      </span>
                    }
                    placement='left'
                  >
                    <Icon type='info-circle' />
                  </Tooltip>
                </div>
              </div>
              <LootHistoryResponseTypes />
            </Modal>
          )
        }}
      </Formik>
    </div>
  )
}

export default LootHistoryResponseTypeModal
