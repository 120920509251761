import React from 'react'

import { Icon, List, Avatar, Button } from 'antd'

class PatreonCard extends React.Component {
  render() {
    const selected = this.props.selected == this.props.level
    const active = this.props.guild.patreonLevel == this.props.level
    var benefits = [
      `Data refreshed ${this.props.refreshFrequency} times as often`,
      `Track up to ${this.props.teamLimit} teams in your guild`,
      `Track up to ${this.props.characterLimit} characters per team`,
      `Guild no longer expires`,
      `Track characters not from your guild`,
      `${this.props.level} Patreon role in Discord`,
    ]

    if (!(this.props.level === 'Bronze')) {
      benefits.push(`Grant benefits to a second guild`)
      if (!(this.props.level === 'Silver')) {
        benefits.push(`Manually refresh your data on demand`)
      }
    }

    return (
      <div
        onClick={() => {
          !selected ? this.props.selectTier(this.props.level) : null
        }}
        className={`level level-${this.props.level.toLowerCase()} ${
          selected ? 'selected' : ''
        } ${active ? 'active' : ''}`}
      >
        <div>
          <h2>
            <Icon type={active ? 'check-circle' : 'sketch'} />
            {this.props.level}
          </h2>

          <div className='benefits'>
            Benefits:
            <List
              dataSource={benefits}
              renderItem={(benefit) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon='check' />}
                    title={benefit}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
        <div className='details'>
          <div className='price'>
            {active && (
              <span className='active-badge'>
                <Icon type='check' />
                Active
              </span>
            )}
            <span className='amount'>${this.props.price}</span>
            <span className='label'> per month</span>
          </div>

          {active && this.props.user.loggedIn && (
            <Button block type='danger' className='revoke-benefits'>
              Revoke benefits
            </Button>
          )}
          {!active && (
            <Button block type='primary' className='select-tier'>
              Select tier
            </Button>
          )}
        </div>

        <div className='checkout'>
          {!active && (
            <React.Fragment>
              <div>
                <p>
                  Subscriptions are managed by Patreon. Simply sign up for an
                  account, follow the checkout process and then come back here
                  to claim your benefits.
                </p>

                <Button
                  icon='dollar'
                  block
                  type='primary'
                  href={`https://www.patreon.com/join/auditspreadsheet/checkout?rid=${this.props.patreonId}`}
                  target='_blank'
                >
                  Checkout on Patreon
                </Button>
              </div>

              <div>
                <p>
                  If you already have an active pledge you don&apos;t have to
                  checkout again, you can immediately claim your benefits.
                </p>

                <Button
                  icon='star'
                  block
                  type='primary'
                  href={`/api/guilds/${this.props.guild.id}/claim_benefits`}
                >
                  Claim benefits
                </Button>
              </div>
            </React.Fragment>
          )}
          {active && (
            <div>
              <p>
                You can revoke the benefits granted through your Patreon pledge
                here. Please note that this will only remove the Patreon status
                granted to this guild.
              </p>

              <p>
                If you want to cancel your pledge altogether then you should
                visit the Patreon website.
              </p>

              <Button
                block
                className='revoke-benefits'
                type='danger'
                disabled={!this.props.user.loggedIn}
                href={`/api/guilds/${this.props.guild.id}/revoke_benefits`}
              >
                Revoke
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default PatreonCard
