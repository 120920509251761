import axios from 'axios'

class WishlistService {
  async create(
    guildId,
    characterId,
    report_id,
    droptimizer_configuration_id,
    replace_manual_edits,
  ) {
    return await axios.post(
      `/api/guilds/${guildId}/characters/${characterId}/wishlist_items`,
      {
        report_id,
        droptimizer_configuration_id,
        replace_manual_edits,
      },
    )
  }

  async update(guildId, characterId, changes, instance_id) {
    return await axios.put(
      `/api/guilds/${guildId}/characters/${characterId}/wishlist_items/edit`,
      { changes: changes, instance_id },
    )
  }

  async updateSpecWeights(guildId, characterId, spec_weights) {
    return await axios.put(
      `/api/guilds/${guildId}/characters/${characterId}/wishlist_items/spec_weights`,
      { spec_weights },
    )
  }

  async retrieve(guildId, characterId, instanceId) {
    return await axios.get(
      `/api/guilds/${guildId}/characters/${characterId}/wishlist_items?instance_id=${instanceId}`,
    )
  }

  async delete(guildId, characterId, instanceId) {
    return await axios.delete(
      `/api/guilds/${guildId}/characters/${characterId}/wishlist_items/${instanceId}`,
    )
  }
}

export default new WishlistService()
