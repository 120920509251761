import React from 'react'
import { useFormikContext } from 'formik'
import { Checkbox, Input, Select } from 'formik-antd'

const VariableSettingsTable = ({ scope }) => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <div className='settings-table'>
      {values.settings.map((setting, i) => {
        if (!scope || setting.category === scope)
          return (
            <div
              key={setting.field}
              className={`single-setting ${
                typeof setting.value === 'boolean' ? 'checkbox' : ''
              }`}
              onClick={() => {
                if (typeof setting.value === 'boolean') {
                  setFieldValue(`settings.${i}.value`, !setting.value)
                }
              }}
            >
              <div className='left'>
                <label>{setting.label}</label>
                <span>{setting.description}</span>
              </div>

              <div className='right'>
                {setting.options ? (
                  <Select
                    placeholder='Difficulties'
                    mode={setting.multi ? 'multiple' : 'default'}
                    name={`settings.${i}.value`}
                    optionFilterProp='children'
                  >
                    {setting.options.map((option, index) => (
                      <Select.Option key={index} value={option}>
                        {option.replaceAll('_', ' ')}
                      </Select.Option>
                    ))}
                  </Select>
                ) : typeof setting.value === 'boolean' ? (
                  <div onClick={(e) => e.stopPropagation()}>
                    <Checkbox name={`settings.${i}.value`} />
                  </div>
                ) : (
                  <Input name={`settings.${i}.value`} />
                )}
              </div>
            </div>
          )
      })}
    </div>
  )
}

export default VariableSettingsTable
