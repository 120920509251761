import React from 'react'

import LandingPageContainer from '../components/LandingPageContainer'
import ProfileInfo from '../components/ProfileInfo'
import { Button, Icon } from 'antd'

import bnetLogo from '../../../../assets/images/bnet-logo-white.svg'
const Logo = () => <img src={bnetLogo} />

const ProfilePage = () => {
  const kind = document.getElementById('subdomain')?.getAttribute('data-kind')
  const session = JSON.parse(
    document.getElementById('session')?.getAttribute('data'),
  ).session

  return (
    <LandingPageContainer
      className={`homepage-profile ${
        session && session.loggedIn && session.bnet ? '' : 'inactive'
      }`}
    >
      {session && session.loggedIn && session.bnet ? (
        <React.Fragment>
          <h1>
            <span>{session.battletag}</span>
            <Button type='danger' size='small' href='/logout'>
              Log out
            </Button>
          </h1>
          <ProfileInfo
            user={session}
            kind={kind}
            refreshUrl='/profile/refresh_characters'
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h1>Log in to view your profile</h1>
          <p>
            <Button
              href={`/auth/bnet?origin=${encodeURIComponent('/profile')}`}
            >
              <Icon component={Logo} />
              Log in with Battle.net
            </Button>
          </p>
        </React.Fragment>
      )}
    </LandingPageContainer>
  )
}

export default ProfilePage
