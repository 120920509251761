/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { Button, Icon, Input, message, Modal, Select } from 'antd'
import { useEffect, useState } from 'react'

import Image from '../Image'

import RaidService from '../../services/RaidService'
import RealmService from '../../services/RealmService'

const AddGuestModal = (props) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [realms, setRealms] = useState([])
  const [name, setName] = useState('')
  const [selectedRealm, setSelectedRealm] = useState(props.guild.realm_id)
  const [selectedCharacter, setSelectedCharacter] = useState(null)

  const availableCharacters = props.guild.selectedTeam.characters.filter(
    (character) =>
      !character.raid_visibility &&
      !props.raid.signups.find(
        (signup) => signup.character.id === character.id,
      ),
  )

  useEffect(() => {
    if (open && !realms.length) {
      RealmService.fetchByKind(props.guild.kind).then((realms) => {
        setRealms(realms)
      })
    }

    if (!open) {
      setName('')
      setSelectedCharacter(null)
    }
  }, [open])

  const addGuest = () => {
    setLoading(true)
    RaidService.addGuest(
      props.guild.id,
      props.guild.selectedTeam.id,
      props.raid.id,
      {
        realm_id: selectedRealm,
        name,
        role: props.role,
        character_id: selectedCharacter?.id,
      },
    )
      .then((result) => {
        props.refreshRaid(result)
        setLoading(false)
        setName('')
        setSelectedCharacter(null)
        setOpen(false)
      })
      .catch((result) => {
        if (result.response.status == 401) window.location.reload()
        message.error(
          result.response.data?.message ||
            'Something went wrong trying to add the guest. Please try again.',
          3,
        )
        setLoading(false)
      })
  }

  return (
    <div>
      <Icon type='plus' onClick={() => setOpen(true)} />
      <Modal
        title={
          <span>
            <Image name={props.role} />
            Add {props.role.toLowerCase()} guest to raid
          </span>
        }
        className='add-guest-modal'
        visible={open}
        onOk={addGuest}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key='back' disabled={loading} onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={loading}
            loading={loading}
            onClick={addGuest}
          >
            Add
          </Button>,
        ]}
      >
        <section css={ModalContentStyles}>
          {availableCharacters.length ? (
            <React.Fragment>
              <div className='add-guest-block'>
                <h3>From roster</h3>

                <Select
                  placeholder='Character'
                  value={selectedCharacter?.id}
                  allowClear
                  filterOption={(input, option) =>
                    option.key.includes(input.toLowerCase())
                  }
                  showSearch
                  onChange={(value) =>
                    setSelectedCharacter(
                      availableCharacters.find((ch) => ch.id === value),
                    )
                  }
                >
                  {availableCharacters.map((character) => (
                    <Select.Option
                      key={`${character.name}-${character.id}`.toLowerCase()}
                      value={character.id}
                      className='lower'
                    >
                      <div className='add-guest-option'>
                        <Image
                          name={`${(character.class || '').replace(' ', '')}-2`}
                        />
                        <div>
                          <span>{character.name}</span>
                          <span className='realm'>{character.realm}</span>
                        </div>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className='divider'>
                <span>or</span>
              </div>
            </React.Fragment>
          ) : (
            ''
          )}

          <div className='add-guest-block'>
            <h3>Add manually</h3>

            <div className='manual-add-info'>
              <Select
                placeholder='Realm'
                value={realms.length == 0 ? null : selectedRealm}
                loading={realms.length == 0}
                disabled={!!selectedCharacter}
                showSearch
                onChange={(value) => setSelectedRealm(value)}
                optionFilterProp='children'
              >
                {realms[props.guild.region] &&
                  realms[props.guild.region].map((realm) => (
                    <Select.Option key={realm.id} value={realm.id}>
                      {realm.name}
                    </Select.Option>
                  ))}
              </Select>
              <Input
                placeholder='Name'
                className={
                  !selectedRealm || !!selectedCharacter ? 'disabled' : ''
                }
                value={name}
                disabled={!selectedRealm || !!selectedCharacter}
                onChange={(event) => setName(event.target.value)}
                loading={loading ? 'true' : undefined}
              />
            </div>
          </div>
        </section>
      </Modal>
    </div>
  )
}

export default AddGuestModal

const ModalContentStyles = css`
  .add-guest-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 2rem;
    width: 100%;

    .ant-select {
      width: 100%;
    }

    .manual-add-info {
      display: flex;
      width: 100%;
      > * {
        flex-basis: 50%;
      }
    }
  }

  .divider {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    > span {
      padding: 0.5rem 2rem;
      z-index: 2;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
    }
  }
`
