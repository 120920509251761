import React from 'react'

import { Select, Input, message, Alert } from 'antd'

import RealmService from '../../services/RealmService'
import CharacterService from '../../services/CharacterService'

class NewMemberForm extends React.Component {
  state = {
    realms: [],
    loading: false,
  }

  componentDidMount() {
    RealmService.fetchByKind(this.props.guild.kind).then((realms) => {
      this.setState({ realms })
    })
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value })
  }

  trackCharacter = () => {
    this.setState({ loading: true })
    CharacterService.create(
      this.props.guild.id,
      this.props.guild.selectedTeam.id,
      {
        name: this.state.name,
        realm_id: this.state.realm,
      },
    )
      .then((result) => this.props.refreshGuild(result.data))
      .then(() => {
        message.success(`Added ${this.state.name} to the roster.`, 1.5)
        this.setState({ realm: null, name: null })
      })
      .catch((error) => {
        if (error.response.status == 401) window.location.reload()
        message.error(
          `Couldn't track character. ${error.response.data.error}`,
          3,
        )
      })
      .then(() => this.setState({ loading: false }))
  }

  render() {
    return (
      <div className='new-member-form'>
        {this.props.guild.selectedTeam.characters.length >=
          this.props.guild.characterLimit && (
          <Alert
            message={`Your team's character limit of ${this.props.guild.characterLimit} has been reached.`}
            type='warning'
            showIcon
          />
        )}
        <Select
          placeholder='Realm'
          value={this.state.realm}
          loading={this.state.realms.length == 0}
          showSearch
          onChange={(value) => this.handleInputChange('realm', value)}
          optionFilterProp='children'
          disabled={
            this.props.guild.selectedTeam.characters.length >=
            this.props.guild.characterLimit
          }
        >
          {this.state.realms[this.props.guild.region] &&
            this.state.realms[this.props.guild.region].map((realm, index) => (
              <Select.Option key={realm.id} value={realm.id}>
                {realm.name}
              </Select.Option>
            ))}
        </Select>
        <Input.Search
          placeholder='Name'
          className={!this.state.realm && 'disabled'}
          value={this.state.name}
          disabled={
            !this.state.realm ||
            this.props.guild.selectedTeam.characters.length >=
              this.props.guild.characterLimit
          }
          onSearch={this.trackCharacter}
          onChange={(event) =>
            this.handleInputChange('name', event.target.value)
          }
          loading={this.state.loading ? 'true' : undefined}
          enterButton
        />
      </div>
    )
  }
}

export default NewMemberForm
