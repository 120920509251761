import React from 'react'
import { Link } from 'react-router-dom'

import Image from './Image'

const TroubleshootingContent = ({ team, inModal }) => {
  return (
    <div className='troubleshooting'>
      {inModal ? null : <h2>Troubleshooting</h2>}
      <div>
        {inModal ? (
          'You can try to refresh your list of characters on this page'
        ) : (
          <React.Fragment>
            You can try to refresh your list of characters in your{' '}
            <Link to={`${team ? team.path : ''}/profile`}>profile</Link>
          </React.Fragment>
        )}{' '}
        (ensure that your profile shows the correct Blizzard account). As a
        first step, confirm with your guild administrator that the access
        control allows your account to have access. Keep in mind that if you
        recently server transferred it can take up to a few days before your
        character is visible. If you&apos;ve verified that, please ensure that
        in your{' '}
        <a href='https://account.battle.net/privacy' target='blizzard_settings'>
          Blizzard settings
        </a>{' '}
        you have enabled this option:
        <Image name='game-data' />
        If that is the case, also ensure that on the{' '}
        <a
          href='https://account.battle.net/connections'
          target='blizzard_settings'
        >
          Connections page
        </a>{' '}
        your wowaudit authorization looks exactly like this:
        <Image name='profile' />
        If you&apos;ve made any changes, remember to explicitly refresh your
        list of characters in your profile (simply refreshing the page is not
        enough). If nothing works, please reach out on{' '}
        <a href='https://discord.gg/86eUAFz' target='_blank' rel='noreferrer'>
          {' '}
          Discord
        </a>{' '}
        and you will be assisted.
      </div>
    </div>
  )
}

export default TroubleshootingContent
