import React from 'react'
import { withRouter } from 'react-router-dom'

import Image from '../components/Image'
import TroubleshootingContent from '../components/TroubleshootingContent'

class Unauthorized extends React.Component {
  render() {
    return (
      <div className='unauthorized-page'>
        <h1>Unauthorized</h1>
        {this.props.user.method === 'google' ? (
          <div className='details'>
            <p className='reading-width'>
              Your Google account has not been granted admin access to this
              guild. You can try logging out as an admin in case your Battle.net
              account has access, or you can reach out to whoever is in charge
              of this team.
            </p>
          </div>
        ) : (
          <div className='details'>
            <p>
              {this.props.guild.trackingBased ? (
                <span>
                  This guild is granting access only to characters that are
                  tracked by it (and to all users with a guild rank of{' '}
                  {this.props.guild.adminThreshold + 1} or higher.)
                </span>
              ) : this.props.threshold === 2 &&
                this.props.guild.basicThreshold === 10 ? (
                <span>
                  This guild is restricting access to anyone who does not have a
                  character in the guild.
                </span>
              ) : this.props.threshold === 2 ? (
                <span>
                  This guild is restricting access to anyone who does not have a
                  character in the guild
                  {this.props.guild.basicThreshold < 10 ? (
                    <span>
                      {' '}
                      with rank {this.props.guild.basicThreshold + 1} or higher.
                    </span>
                  ) : (
                    <span>.</span>
                  )}
                </span>
              ) : (
                <span>
                  This page is restricted to anyone who does not have a
                  character in the guild
                  {this.props.guild.adminThreshold < 10 ? (
                    <span>
                      {' '}
                      with rank {this.props.guild.adminThreshold + 1} or higher.
                    </span>
                  ) : (
                    <span>.</span>
                  )}
                </span>
              )}
            </p>
            <p>
              {!this.props.user.loggedIn ? null : this.props.user
                  .highestRankDisplay === null ? (
                <span>
                  The account you logged in with doesn&apos;t appear to{' '}
                  {this.props.user.character_data.length
                    ? 'own any characters in this guild.'
                    : 'expose its characters, please look at the troubleshooting section.'}
                </span>
              ) : (
                <span>
                  According to our data, the highest guild rank of your
                  characters is {this.props.user.highestRankDisplay + 1}
                </span>
              )}
            </p>
            &nbsp;
            {this.props.user.loggedIn && (
              <TroubleshootingContent team={this.props.guild.selectedTeam} />
            )}
            {!this.props.user.loggedIn && (
              <React.Fragment>
                Have you tried{' '}
                <a
                  href={`/auth/bnet?origin=${encodeURIComponent(
                    this.props.match.url,
                  )}&region=${this.props.region}`}
                >
                  logging in
                </a>
                ?
              </React.Fragment>
            )}
            <Image name='guard' className='circle' />
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(Unauthorized)
